import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Editing,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DateBox from "devextreme-react/date-box";
import { DateFormat } from "../../helpers/fixcodes";
import Swal from "sweetalert2";

export class TransportComponent extends Component {
  static displayName = TransportComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      dataSourceSelectedRowKeys: [],
      ApproveDatasource: null,
      approveDataSourceSelectedRowKeys: [],
      crewStatusList: [],
      status: "Active",
      loadPanelVisible: false,
      fromDate: new Date(),
      toDate: new Date(),
      filterPickupDatetime: new Date(),
      filterToDateTime: new Date(),
      empNoList: [],
      empid: 0,
      empNo: "",
      billingStatusList: [],
      id: 0,
      billingStatusId: 0,
      billingStatusName: "",
      billingcrewid: 0,
      billingSchedulingid: 0,
      billingTransportId: 0,
      billingId: 0,
    };
  }

  async componentDidMount() {
    await this.getEmployeeNoList(); //EmployeeNoList
    await this.getBillingStatusList(); //BillingStatusList
    await this.getUnApprovedApprovedRides(); //Grid
  }

  //EmployeeNoList
  getEmployeeNoList = async () => {
    try {
      this.setState({
        loadPanelVisible: true,
      });
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Users/GetAirlineCrewUser",
        requestParams
      );
      const data = await response.json();
      this.setState({
        empNoList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (ex) {
      throw ex;
    }
  };

  // Filter Employee Number DropDown Handle Event
  handleOnfilterEmpNoChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        empid:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.id
            : 0,
      });
      this.setState({
        empNo:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.name
            : "",
      });
    } else {
      this.setState({ empid: 0, empNo: "" });
    }
  };

  //BillingStatusList
  getBillingStatusList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "rides/GetBillingStatus",
        requestParams
      );
      const data = await response.json();
      this.setState({
        billingStatusList: data.payload != null ? data.payload : [],
        billingcrewid: data.payload[0].id,
        billingSchedulingid: data.payload[1].id,
        billingTransportId: data.payload[2].id,
        loadPanelVisible: false,
      });
    } catch (ex) {
      console.log("Error in " + this.displayName + ".getAirports function", ex);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  // Filter Billing Status DropDown Handle Event
  handleOnfilterbillingStatusChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        billingStatusId:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.id
            : 0,
      });
      this.setState({
        billingStatusName:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.billingStatusName
            : "",
      });
    } else {
      this.setState({ billingStatusId: 0, billingStatusName: "" });
    }
  };

  // Filter Pickup Date DropDown Handle Event
  handleOnFilterPickupDateChanged = (date) => {
    this.setState({ filterPickupDatetime: date.value });
  };
  handleOnFilterToDateChanged = (date) => {
    this.setState({ filterToDateTime: date.value });
  };

  //Grid Data
  getUnApprovedApprovedRides = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let userId = sessionStorage.getItem("session_userId");
      var request = {};
      request.AirlineCode = airlineCode;
      request.EmployeeNumber = this.state.empNo;
      request.StartTime =
        formatDate(this.state.filterPickupDatetime, "MM/dd/yyyy") + " 00:00:00";
      request.EndTime =
        formatDate(this.state.filterToDateTime, "MM/dd/yyyy") + " 23:59:59";
      //request.StartTime = formatDate('10/1/2022', 'MM/dd/yyyy') + " 00:00:00";
      //request.EndTime = formatDate('10/30/2022', 'MM/dd/yyyy') + " 23:59:59";
      request.BillingStatusId = this.state.billingId;
      request.LoginUserId = parseInt(userId);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Rides/GetScheduleApprovedRides",
        requestParams
      );
      //const response = await fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams);
      let data = await response.json();
      if (data.payload != null) {
        data = JSON.parse(JSON.stringify(data.payload));
        let billingst = data.filter(
          (item) => item.billingStatusId === this.state.billingSchedulingid
        );
        let Transbillingst = data.filter(
          (item) => item.billingStatusId === this.state.billingTransportId
        );
        this.setState({
          datasource: billingst,
          ApproveDatasource: Transbillingst,
          loadPanelVisible: false,
        });
      } else {
        this.setState({
          datasource: [],
          ApproveDatasource: [],
          loadPanelVisible: false,
        });
      }
    } catch (ex) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        ex
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  //First Grid CheckBox handle Event
  handleMultipleDataSourceSelectionChanged = (e) => {
    this.setState({
      dataSourceSelectedRowKeys: e.selectedRowKeys,
    });
  };

  //Transport Approve Button Function
  handleApprove = async (e) => {
    e.preventDefault();
    try {
      if (
        this.state.dataSourceSelectedRowKeys != null &&
        this.state.dataSourceSelectedRowKeys.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Please select at least one row.",
        }).then((result) => {
          this.setState({ isSwapTrip: false });
        });
        return;
      }
      Swal.fire({
        html: "Are you sure, you want to approve selected ride[s]?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          let selectedRowKeys = this.state.dataSourceSelectedRowKeys;
          var newObject = {};
          newObject.BillingStatusId = this.state.billingTransportId;
          newObject.UserId = sessionStorage.getItem("session_userId");
          newObject.RideId = selectedRowKeys;
          newObject.IsRideApproved = true;
          const requestParams = getRequestParams("POST", newObject);
          //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
          fetch(
            process.env.REACT_APP_API_BASE_URL + "Rides/ApproveRide",
            requestParams
          )
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: data.isSuccess === true ? "success" : "error",
                  text: "Selected ride[s] approved successfully.",
                  // text: data.message,
                }).then((result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getUnApprovedApprovedRides();
                      this.clearData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  //Second Grid CheckBox handle Event
  handleMultipleApproveDataSourceSelectionChanged = (e) => {
    this.setState({
      approveDataSourceSelectedRowKeys: e.selectedRowKeys,
    });
  };

  //Disapproved Transport Button Function
  handleUnapprovebySchedule = async (e) => {
    e.preventDefault();
    try {
      if (
        this.state.approveDataSourceSelectedRowKeys != null &&
        this.state.approveDataSourceSelectedRowKeys.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Please select at least one row.",
        }).then((result) => {
          this.setState({ isSwapTrip: false });
        });
        return;
      }
      Swal.fire({
        html: "Are you sure, you want to disapprove selected ride[s]?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          let selectedRowKeys = this.state.approveDataSourceSelectedRowKeys;
          var newObject = {};
          newObject.BillingStatusId = this.state.billingSchedulingid;
          newObject.UserId = sessionStorage.getItem("session_userId");
          newObject.RideId = selectedRowKeys;
          newObject.IsRideApproved = false;
          const requestParams = getRequestParams("POST", newObject);
          fetch(
            process.env.REACT_APP_API_BASE_URL + "Rides/ApproveRide",
            requestParams
          )
            //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: data.isSuccess === true ? "success" : "error",
                  text: "Selected ride[s] disapproved successfully.",
                }).then((result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getUnApprovedApprovedRides();
                      this.clearData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  //Clear Button
  clearData = async (e) => {
    this.setState({
      loadPanelVisible: false,
      fromDate: new Date(),
      toDate: new Date(),
      filterPickupDatetime: new Date(),
      filterToDateTime: new Date(),
      empid: 0,
      empNo: "",
      id: 0,
      billingStatusId: 0,
      billingStatusName: "",
      billingcrewid: 0,
      billingSchedulingid: 0,
      billingTransportId: 0,
      dataSourceSelectedRowKeys: [],
      approveDataSourceSelectedRowKeys: [],
    });
    window.location.reload(false); //Refresh
  };

  //handle event of approve button from transporter
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getUnApprovedApprovedRides.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };

  //handle event of Unapprove button from transporter
  onToolbarPreparingUnapprove = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getUnApprovedApprovedRides.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };

  toolbarItemRender = () => {
    return (
      <Fragment>
        <div className="filter-header">
          <div className="row">
            <div className="col-12 pl-0">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleApprove}
              >
                <span title="Approve by transport">
                  <i className="fas fa-check"></i> Approve by transport
                </span>
              </button>
              &nbsp;&nbsp;
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  toolbarItemRenderUnapproved = () => {
    return (
      <Fragment>
        <div className="filter-header">
          <div className="row">
            <div className="col-12 pl-0">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleUnapprovebySchedule}
              >
                <span title="Unapprove">
                  <i className="fas fa-times"></i> Unapprove
                </span>
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  render() {
    return (
      <Fragment>
        <div className="filter-box">
          <div className="card p-2 m-0">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Employee Number</label>
                  <SelectBox
                    dataSource={this.state.empNoList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="id"
                    defaultValue={0}
                    value={this.state.empid}
                    displayExpr="name"
                    valueExpr="id"
                    onSelectionChanged={this.handleOnfilterEmpNoChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-2">
                                <div className="form-group">
                                    <label>Billing Status</label>
                                    <SelectBox
                                        dataSource={this.state.billingStatusList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Billing Status"
                                        name="id"
                                        defaultValue={0}
                                        value={this.state.billingStatusId}
                                        displayExpr='billingStatusName'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleOnfilterbillingStatusChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                    />
                                </div>
                            </div> */}
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterPickupDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterPickupDatetime}
                    value={this.state.filterPickupDatetime}
                    onValueChanged={this.handleOnFilterPickupDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDateTime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterToDateTime}
                    value={this.state.filterToDateTime}
                    onValueChanged={this.handleOnFilterToDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.getUnApprovedApprovedRides}
                  >
                    <span title="Filter">
                      <i className="fas fa-filter"></i> Filter
                    </span>
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="submit"
                    className="btn btn-custom-gray "
                    onClick={this.clearData}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 border-bottom-0">
          <ul
            className="nav nav-tabs mt-1 ml-2"
            id="custom-tabs-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="custom-tabs-home-tab"
                data-toggle="pill"
                href="#custom-tabs-home"
                role="tab"
                aria-controls="custom-tabs-home"
                aria-selected="true"
                onClick={(e) => this.setState({ tabChangeEvent: true })}
              >
                Unapproved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-profile"
                role="tab"
                aria-controls="custom-tabs-profile"
                aria-selected="false"
                onClick={(e) => this.setState({ tabChangeEvent: false })}
              >
                Approved
              </a>
            </li>
          </ul>
        </div>
        <div className="card">
          <div className="card-body">
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-home-tab"
              >
                <DataGrid
                  dataSource={this.state.datasource}
                  keyExpr="tripId"
                  // getRowId={row => row.yourUniqueField}
                  //selection={{ mode: 'single' }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  selectedRowKeys={this.state.dataSourceSelectedRowKeys}
                  onSelectionChanged={
                    this.handleMultipleDataSourceSelectionChanged
                  }
                  onToolbarPreparing={this.onToolbarPreparing}
                >
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                  />
                  <Editing mode="cell" allowUpdating={false} />
                  <Column
                    dataField="airlineCode"
                    caption="Airline"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={55}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="Employee No."
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="crewName"
                    caption="Crew Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="tripId"
                    caption="Trip Id"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="reservationDate"
                    caption="Reservation DateTime"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    format={DateFormat.DateTime24NorthFormat}
                    dataType="datetime"
                  />
                  <Column
                    dataField="pickupLocation"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="dropoffLocation"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="billingStatus"
                    caption="Billing Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="onLocationTime"
                    caption="Location Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                  />
                  <Column
                    dataField="pickupTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                  />
                  <Column
                    dataField="startOdometer"
                    caption="Start Odometer"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={120}
                  />
                  <Column
                    dataField="endOdometer"
                    caption="End Odometer"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={120}
                  />
                  <Column
                    dataField="totalKms"
                    caption="Total Kms"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />
                  <Column
                    dataField="totalFare"
                    caption="Total Fare"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <Template
                    name="toolbarTemplate"
                    render={this.toolbarItemRender}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="UnApproved Rides"
                  />
                </DataGrid>
              </div>
              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-profile"
                role="tabpanel"
                aria-labelledby="custom-tabs-profile-tab"
              >
                <DataGrid
                  dataSource={this.state.ApproveDatasource}
                  keyExpr="tripId"
                  // getRowId={row => row.yourUniqueField}
                  //selection={{ mode: 'single' }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  selectedRowKeys={this.state.approveDataSourceSelectedRowKeys}
                  onSelectionChanged={
                    this.handleMultipleApproveDataSourceSelectionChanged
                  }
                  onToolbarPreparing={this.onToolbarPreparingUnapprove}
                >
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                  />
                  <Editing mode="cell" allowUpdating={false} />
                  <Column
                    dataField="airlineCode"
                    caption="Airline"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={55}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="Employee No."
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="crewName"
                    caption="Crew Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="tripId"
                    caption="Trip Id"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="reservationDate"
                    caption="Reservation DateTime"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    format={DateFormat.DateTime24NorthFormat}
                    dataType="datetime"
                  />
                  <Column
                    dataField="pickupLocation"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="dropoffLocation"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="billingStatus"
                    caption="Billing Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="onLocationTime"
                    caption="Location Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                  />
                  <Column
                    dataField="pickupTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                  />
                  <Column
                    dataField="startOdometer"
                    caption="Start Odometer"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={120}
                  />
                  <Column
                    dataField="endOdometer"
                    caption="End Odometer"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={120}
                  />
                  <Column
                    dataField="totalKms"
                    caption="Total Kms"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />
                  <Column
                    dataField="totalFare"
                    caption="Total Fare"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <Template
                    name="toolbarTemplate"
                    render={this.toolbarItemRenderUnapproved}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="UnApproved Rides"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
