import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import { Template } from "devextreme-react/core/template";
import Modal from "react-modal";
import Swal from "sweetalert2";
import "devextreme/dist/css/dx.light.css";
import { toast } from "react-toastify";

const CustomStyles = {
  content: {
    width: "30%",
    // /height: '30%'
  },
};
export class AirportComponent extends Component {
  static displayName = AirportComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      airportModelIsOpen: false,
      dataSource: null,
      loadPanelVisible: false,
      airportId: 0,
      addAirportCode: "",
      isActive: true,
      errors: {
        addAirportCode: 0,
      },
    };
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getAirports.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };
  async componentDidMount() {
    await this.getAirports();
  }
  getAirports = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        datasource: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  openTracking = (e, data) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/tracking",
      state: {
        airportId: data.airportId,
        sourceUrl: "/fleet-status",
        airportList: data,
      },
    });
  };
  getAirportTracking = (e, currentPageName, data) => {
    e.preventDefault();
    sessionStorage.setItem("session_PageName", currentPageName);
    this.props.history.push({
      pathname: "/tracking",
      state: { airportCode: data.airportCode, sourceUrl: "/airport" },
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  openAirportModel = (e) => {
    e.preventDefault();
    this.setState({
      airportModelIsOpen: true,
      addAirportCode: "",
    });
  };
  handleAirportSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.state.addAirportCode.trim() === "") {
        let formIsValid = true;
        let errors = this.state.errors;
        errors.addAirportCode = "";
        if (this.state.addAirportCode.trim() === "") {
          formIsValid = false;
          errors.addAirportCode = "Please enter airport.";
        }
        this.setState({ errors: errors });
        return formIsValid;
      }
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      this.setState({ isSubmited: true });
      var newObject = {};
      newObject.UserId = UserId;
      newObject.AirLineCode = airlineCode;
      newObject.AirportCode = this.state.addAirportCode;
      newObject.Enable = this.state.isActive;
      const requestParams = getRequestParams("POST", newObject);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirportMapping",
        requestParams
      );
      const data = await response.json();
      if (data.isSuccess) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            this.getAirports();
            this.closeUserModal();
          }
          this.setState({ isSubmited: false });
        });
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          style: { width: "310px" },
          hideProgressBar: false,
          newestOnTop: false,
          closeButton: false,
        });
        this.setState({ isSubmited: false });
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };
  closeUserModal = () => {
    this.setState({
      isSubmited: false,
      airportModelIsOpen: false,
      addAirportCode: "",
      isActive: true,
      errors: {
        addAirportCode: 0,
      },
    });
  };
  toolbarItemRender() {
    return (
      <Fragment>
        <div className="row">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.openAirportModel}
          >
            <span title="Submit">
              <i className="fas fa-plus"></i>Add Aiport
            </span>
          </button>
        </div>
      </Fragment>
    );
  }
  render() {
    const renderTrackAirport = (data) => {
      return (
        <div>
          <a
            href="!#"
            onClick={(e) => {
              this.getAirportTracking(e, "Tracking", data.data);
            }}
            style={{ textDecoration: "underline" }}
            title={data.data.airportName}
          >
            {data.data.airportName}
          </a>
          &nbsp;&nbsp;
        </div>
      );
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.datasource}
              keyExpr="airportId"
              selection={{ mode: "single" }}
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Column
                dataField="airportCode"
                caption="Airport Code"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
              />
              <Column
                dataField="airportName"
                caption="Airport Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={400}
                cellRender={renderTrackAirport}
              />
              <Column
                dataField="hotelShuttle"
                caption="Hotel Stuttle"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={300}
              />
              <Column
                dataField="groundTransporter"
                caption="Transport Available"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={400}
              />

              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Hotels"
              />
            </DataGrid>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.airportModelIsOpen}
          style={CustomStyles}
          className={"react-modal"}
          onAfterOpen={this.afterOpenviewEditModal}
          onRequestClose={this.editBaseLocationResetStateData}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleAirportSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">Add Airport</h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeUserModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Airport<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="addAirportCode"
                      title="AirportCode"
                      placeholder="Airport Code"
                      maxLength="3"
                      value={this.state.addAirportCode}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["addAirportCode"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["addAirportCode"]}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                  style={{ marginTop: "19px" }}
                >
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        checked={this.state.isActive}
                        onChange={this.handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isActive"
                        style={{ fontSize: "inherit" }}
                      >
                        {" "}
                        Is Active &nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleAirportSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && (
                  <span title="Save">
                    <i className="fas fa-check"></i> Save
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray "
                onClick={this.closeUserModal}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}
