import React, { Component, Fragment } from 'react';
import { TopUserSetupComponent } from '../components/Shared/TopUserSetupComponent';
import Modal from 'react-modal'; //npm install --save react-modal
import { w3cwebsocket as W3CWebSocket } from "websocket"; //npm i --save websocket

const customStyles = {
    content: {
        width: '40%',
    }
};

export class TopBarComponent extends Component {
    static displayName = TopBarComponent.name;    
    constructor(props) {
        super(props);        
        this.state = {
            displayName: TopBarComponent.name,
            pageName: sessionStorage.getItem("PageName") === null ? "Dashboard" : sessionStorage.getItem("PageName"),
            wsurl: sessionStorage.getItem("session_socketApiUrl"),
            notificationModalIsOpen: false,
            notificationMessage: [],
        };

        this.webSocketInterval = null;
        this.setupWebSocket = this.setupWebSocket.bind(this);
        this.closeNotificationModal = this.closeNotificationModal.bind(this);
    }

    componentDidMount() {
        this.setupWebSocket();
       //console.log(sessionStorage.getItem("PageName"));
    }

    setupWebSocket() {
        try {
            var socket = new W3CWebSocket(this.state.wsurl + "Connect");
            socket.onopen = (event) => {
                function establishValidConnection() {
                    console.log("Websocket client connected");
                    //debugger;
                    if (socket.readyState === socket.OPEN) {
                        let sessRequest = JSON.stringify({
                            user:
                            {
                                UserName: sessionStorage.getItem("session_userName"),
                                AccountId: parseInt(0),
                                LocationId: parseInt(0),
                                AirlineCode: sessionStorage.getItem("session_airlineCode"),
                            }
                        });
                        console.log("WebSocket Client " + sessRequest);
                        socket.send(sessRequest);
                    } else {
                        setTimeout(establishValidConnection, 500);
                    }
                }
                establishValidConnection();
            };

            socket.onclose = (event) => {
                //debugger;
                console.log("Websocket client disconnected");
                this.webSocketInterval = setTimeout(() => { this.setupWebSocket(); }, 5000);
            };

            socket.onmessage = (message) => {
                console.log("Websocket client message received");
                console.log(message.data);
                if (message.data != null) {
                    const notification = JSON.parse(message.data);
                    var allnotifications = [];
                    allnotifications = this.state.notificationMessage;
                    //if (notification.UserName === sessionStorage.getItem("session_userName") || notification.AccountId === sessionStorage.getItem("session_accountId")) {
                    if (notification.AirlineCode === sessionStorage.getItem("session_airlineCode")) {
                        if (notification.Message !== "") {
                            allnotifications.push(notification.Message);
                            this.setState({
                                notificationModalIsOpen: true,
                                notificationMessage: allnotifications,
                            });
                        }
                    }
                }
            };
        } catch (err) {
            console.log("Error in " + this.displayName + " setupWebSocket function", err);
        }
    }

    closeNotificationModal() {
        this.setState({
            notificationModalIsOpen: false,
            notificationMessage: [],
        });
    }
  
    handleLogout = async (e) => {
        e.preventDefault();        
        //const requestParams = getRequestParams('GET');
        //let userId = parseInt(sessionStorage.getItem("session_userId"));
        //let loginHistoryId = parseInt(sessionStorage.getItem("session_LoginHistoryId"));        
        //const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        sessionStorage.clear();
        window.location.href = "/";
    }

    toggleSidebar=(e)=>{
        e.preventDefault();
        if(document.getElementsByTagName("body")[0].className === "sidebar-mini"){
            document.getElementsByTagName("body")[0].classList.remove("sidebar-mini")
        }else{
            document.getElementsByTagName("body")[0].classList.add("sidebar-mini")
        }      
    }

    render() {
        return (
            <Fragment>
                <div className="navbar-bg"></div>
                <nav className="navbar navbar-custom navbar-expand-lg main-navbar" name="topGeneralBar">
                    <form className="form-inline mr-auto">
                        <ul className="navbar-nav mr-3">
                            {/* <NavItem>
                                <NavLink className="nav-link-lg" tag={Link} to="#" data-toggle="sidebar"><i className="fas fa-bars"></i></NavLink>
                            </NavItem> */}
                            <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                            <li className="nav-item">
                                <h6 className='mb-0'><label id='lblPageTitle'></label></h6>
                            </li>
                        </ul>
                    </form>
                    {/* <ul className="navbar-nav navbar-right">                        
                        
                    </ul> */}
                    <TopUserSetupComponent />
                </nav>
                {/* <Modal ariaHideApp={false}
                    isOpen={this.state.notificationModalIsOpen}
                    style={customStyles}
                    className={"react-modal-notification"}
                    onRequestClose={this.closeNotificationModal}
                    shouldCloseOnOverlayClick={false}>
                    <div className="alert alert-success mb-0" role="alert">
                        <h4 className="alert-heading">New Notification Arrived!
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeNotificationModal}>
                                <span aria-hidden="true" className="text-dark">&times;</span>
                            </button>
                        </h4><hr />
                        {this.state.notificationMessage.map((data, i) => (
                            <div key={i} className="alert alert-warning" role="alert">{data}</div>
                        ))}
                    </div>
                </Modal> */}

                <Modal ariaHideApp={false}
                    isOpen={this.state.notificationModalIsOpen}
                    style={customStyles}
                    className={"react-modal react-modal-notification"}
                    onRequestClose={this.closeNotificationModal}
                    shouldCloseOnOverlayClick={false}>
                    <div className="modal-header">
                        <h6 className="modal-title">New Notification(s)</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeNotificationModal}>
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-overflow">
                            <ul class="list-group border-0">
                                {this.state.notificationMessage.map((data, i) => (
                                    <li key={i} class="list-group-item"><i class="far fa-bell mr-2"></i>{data}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}