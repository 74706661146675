import React, { Component, Fragment } from "react";
import { Route } from "react-router";
import { Wrapper } from "@googlemaps/react-wrapper";

import { Layout } from "./components/Layout";
import { AuthorisedLayout } from "./components/AuthorisedLayout";
import { ForgotPasswordComponent } from "./components/ForgotPasswordComponent";
import { RegisterComponent } from "./components/RegisterComponent";
import { LoginComponent } from "./components/LoginComponent";
import { DashboardComponent } from "./components/dashboard/DashboardComponent";
import { AirportComponent } from "./components/airport/AirportComponent";
import { ResetPasswordComponent } from "./components/ResetPasswordComponent";
import { HotelsComponent } from "./components/hotels/HotelsComponent";
import { ServiceProviderComponent } from "./components/serviceProvider/ServiceProviderComponent";
import { ReservationsComponent } from "./components/reservations/ReservationsComponent";
import { DispatchComponent } from "./components/reservations/DispatchComponent";
import { NotificationComponent } from "./components/notification/NotificationComponent";

import { TrackingComponent } from "./components/tracking/TrackingComponent";
import { CrewMembersComponent } from "./components/crewmembers/CrewMembersComponent";
import { UserComponent } from "./components/user/UserComponent";
import { RatingComponent } from "./components/rating/RatingComponent";
import { FeedbackComponent } from "./components/feedback/FeedbackComponent";
import { ReimburseTransport } from "./components/cancellation/ReimburseTransport";
import { UnApprovedApprovedRidesComponent } from "./components/billing/UnApprovedApprovedRidesComponent";
import { TransportComponent } from "./components/transportDepartment/TransportComponent";
import { InvoiceComponent } from "./components/invoice/InvoiceComponent";
import { TransportHotelConfigurationComponent } from "./components/transporthotelconfiguration/TransportHotelConfigurationComponent";
import { ScheduleDashBoardComponent } from "./components/scheduleDashBoard/ScheduleDashBoardComponent";
import { EmailConfigurationComponent } from "./components/emailconfiguration/EmailConfigurationComponent";
import { CancelHotelComponent } from "./components/report/CancelHotelComponent";
import { StayHotelComponent } from "./components/report/StayHotelComponent";
import { HotelBookingComponent } from "./components/hotelbooking/HotelBookingComponent";
import { ChildBookingDataComponent } from "./components/hotelbooking/ChildBookingDataComponent";
import { CrewTypeTransportConfigurationComponent } from "./components/CrewTypeTransportConfiguration/CrewTypeTransportConfigurationComponent";
import { HotelBookingPriority } from "./components/hotelbookingpriority/HotelBookingPriority";
import { AirportGeofenceReportComponent } from "./components/report/AirportGeofenceReportComponent";
import { CrewAddressComponent } from "./components/crewmembers/CrewAddressComponent";

export default class App extends Component {
  static displayName = App.name;

  render() {
    const isAuthorized =
      sessionStorage.getItem("session_userId") === "" ||
      sessionStorage.getItem("session_userId") === null
        ? false
        : true;

    return (
      <Fragment>
        {isAuthorized === true ? (
          <Wrapper apiKey={"AIzaSyB2__GLNvcvr70qg-p6lvN0O9H9XLhAU00"}>
            <AuthorisedLayout>
              {/* <Route path="*" component={DashboardComponent} /> */}
              <Route path="/dashboard" component={DashboardComponent} />
              <Route path="/airport" component={AirportComponent} />
              <Route path="/tracking" component={TrackingComponent} />
              <Route path="/hotels" component={HotelsComponent} />
              <Route
                path="/serviceProviders"
                component={ServiceProviderComponent}
              />
              <Route path="/reservations" component={ReservationsComponent} />
              <Route path="/dispatch" component={DispatchComponent} />
              <Route path="/crewmember" component={CrewMembersComponent} />
              <Route path="/user" component={UserComponent} />
              <Route path="/rating" component={RatingComponent} />
              <Route path="/feedback" component={FeedbackComponent} />
              <Route path="/notification" component={NotificationComponent} />
              <Route
                path="/reimburseTransport"
                component={ReimburseTransport}
              />
              <Route
                path="/billing"
                component={UnApprovedApprovedRidesComponent}
              />
              <Route
                path="/transportDepartment"
                component={TransportComponent}
              />
              <Route path="/invoice" component={InvoiceComponent} />
              <Route
                path="/scheduleDashboard"
                component={ScheduleDashBoardComponent}
              />
              <Route
                path="/config/transporthotel"
                component={TransportHotelConfigurationComponent}
              />
              <Route
                path="/config/email"
                component={EmailConfigurationComponent}
              />
              <Route path="/report/cancel" component={CancelHotelComponent} />
              <Route path="/report/stay" component={StayHotelComponent} />
              <Route path="/hotelbooking" component={HotelBookingComponent} />
              <Route
                path="/config/transportcrewtype"
                component={CrewTypeTransportConfigurationComponent}
              />
              <Route
                path="/config/booking/hotelpriority"
                component={HotelBookingPriority}
              />
              <Route
                path="/report/geofence"
                component={AirportGeofenceReportComponent}
              />
              <Route path="/crew-address" component={CrewAddressComponent} />
            </AuthorisedLayout>
          </Wrapper>
        ) : (
          <Layout>
            <Route exact path="/" component={LoginComponent} />
            <Route path="/login" component={LoginComponent} />
            <Route path="/forgotpassword" component={ForgotPasswordComponent} />
            <Route path="/register" component={RegisterComponent} />
            <Route path="/resetpassword" component={ResetPasswordComponent} />
          </Layout>
        )}
      </Fragment>
    );
  }
}
