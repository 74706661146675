import React, { Component, Fragment } from "react";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { BasicMapStyle } from "../../helpers/mapstylejson";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { SelectBox } from "devextreme-react/select-box";
import { LoadPanel } from "devextreme-react/load-panel";
const position = { of: "#historydiv" };

//var MarkerWithLabel = require('markerwithlabel')(window.google.maps);
export class TrackingComponent extends Component {
  static displayName = TrackingComponent.name;

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      infoWindow: true,
      infoVehicle: "none",
      isSubmited: false,
      datasource: [],
      singledatasource: [],
      accountdatasource: [],
      airportList:
        this.props.location.state === undefined
          ? []
          : this.props.location.state.airportList,
      vehicleLastStatus: [],
      loadPanelVisible: false,
      airportId:
        this.props.location.state === undefined
          ? 0
          : this.props.location.state.airportId,
      airportCode:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.airportCode,
      accountId:
        this.props.location.state === undefined
          ? 0
          : parseInt(this.props.location.state.serviceProviderId),
      sourceUrl:
        this.props.location.state === undefined
          ? ""
          : sessionStorage.setItem("session_PageName", "Tracking"),
      vehicleId: 0,
      selectedvehicleId: 0,
      vehicleNo: "",
      rideId: 0,
      vgTypeId: "",
      isAutoRefresh: false,
      center: {
        lat: 19.0936,
        lng: 72.856629,
      },
      vehicleLatitude: 0,
      vehicleLongitude: 0,
      zoom: 8,
      mapStyle: BasicMapStyle,
      markerLabelVisibility: false,
      selectedAmbulance: [],
      lastGpsTime: "",
      pickupTIme: "",
      vehicleData: [],
      seconds: 30,
    };
    this.infoWindow = null;
    this.vehicleMarkers = [];
    this.closeInfo = this.closeInfo.bind(this);
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getTracking.bind(this),
      },
    });
  };
  async componentDidMount() {
    clearInterval(this.vehicleIntervalHandle);
    clearInterval(this.singleVehicleIntervalHandle);
    if (this.state.sourceUrl != "") {
      sessionStorage.setItem("session_PageName", "Tracking");
    }
    this.getAirports();
    this.getServiceProvider();
    this.bindMap();
    let myVar = setTimeout(() => this.getTracking(), 500);
    this.vehicleIntervalHandle = setInterval(() => {
      this.getTracking();
    }, this.state.seconds * 1000);
    this.setState({ loadPanelVisible: true });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  getAirports = async () => {
    this.setState({ loadPanelVisible: true });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getServiceProvider = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Account",
        requestParams
      );
      const data = await response.json();
      this.setState({
        accountdatasource: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getTracking = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let UserId = sessionStorage.getItem("session_userId");
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.UserId = parseInt(UserId);
      request.VehicleId = parseInt(this.state.vehicleId);
      request.RideId = this.state.rideId;
      request.VgTypeId = this.state.vgTypeId;
      request.AirlineCode = airlineCode;
      request.ServiceProviderId =
        isNaN(this.state.accountId) === true
          ? 0
          : parseInt(this.state.accountId);
      request.AirportCode = this.state.airportCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Tracking",
        requestParams
      );
      const data = await response.json();
      //debugger;
      this.setState({
        datasource: data.payload != null ? data.payload : [],
        singledatasource:
          this.state.selectedvehicleId > 0 && data.payload != null
            ? data.payload.filter(
                (e) => e.vehicleId === this.state.selectedvehicleId
              )
            : data.payload,
        loadPanelVisible: false,
        //vehicleId: data.payload != null ? data.payload[0].vehicleId : 0,
        vehicleNo: data.payload != null ? data.payload[0].vehicleNo : "",
        vehicleLatitude: data.payload != null ? data.payload[0].latitude : 0,
        vehicleLongitude: data.payload != null ? data.payload[0].longitude : 0,
      });

      this.bindVehicleLastStatusData(data.payload);

      this.setState({
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getTracking function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  displayExpr = (item) => {
    if (item != null) {
      return item.airportCode;
    } else {
      return "Select Airport";
    }
  };
  bindMap() {
    let mapStyle = BasicMapStyle;
    this.map = new window.google.maps.Map(this.mapRef.current, {
      center:
        this.state.vehicleData != null && this.state.vehicleData.length > 0
          ? new window.google.maps.LatLng(
              this.state.vehicleData[0].latitude,
              this.state.vehicleData[0].longitude
            )
          : this.state.center,
      zoom:
        this.state.vehicleData != null && this.state.vehicleData.length > 0
          ? this.state.zoom
          : 8,
      styles: mapStyle,
    });
  }
  bindVehicleLastStatusData(data) {
    try {
      this.setState(
        {
          vehicleLastStatus: data != null ? data : [],
        },
        () => {
          this.drawVehicleMarkers();
          this.onVehicleFilterChanged();
        }
      );
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".bindVehicleLastStatusData function",
        err
      );
    }
  }
  removeMarkers = (map) => {
    for (let i = 0; i < this.vehicleMarkers.length; i++) {
      const marker = this.vehicleMarkers[i];
      marker.setMap(null);
    }
  };
  getMarkerIconUrl = (hostStatus, course) => {
    let _course = course - (course % 5);
    let folderAndiconName = "Car";
    return (
      "../assets/img/device-icons/" +
      folderAndiconName +
      "/" +
      folderAndiconName +
      "_" +
      this.getColorByHostStatus(hostStatus) +
      ("_" + _course) +
      ".png?v=1.6"
    );
  };
  getColorByHostStatus = (hostStatus) => {
    let color = "";
    if (hostStatus === 0) {
      color = "orange";
    } else if (hostStatus === 1) {
      color = "green";
    } else if (hostStatus === 2) {
      color = "red";
    } else if (hostStatus === 3) {
      color = "orange";
    } else if (hostStatus === 4) {
      color = "blue";
    }
    return color;
  };
  drawVehicleMarkers = () => {
    try {
      this.removeMarkers();
      this.vehicleMarkers = [];
      let bounds = new window.google.maps.LatLngBounds();
      //var vehicles = this.state.datasource;
      var vehicles =
        this.state.singledatasource.length > 0
          ? this.state.singledatasource
          : this.state.datasource;
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];
        let latLng = new window.google.maps.LatLng(
          vehicle.latitude,
          vehicle.longitude
        );
        let iconImage = {
          url: this.getMarkerIconUrl(vehicle.hostStatus, vehicle.course), //"../assets/img/device-icons/Car/Car_blue_25.png",
          size: new window.google.maps.Size(30, 30),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(15, 15),
        };

        let marker = new window.google.maps.Marker({
          vehicleId: vehicle.vehicleId,
          position: latLng,
          map: this.map,
          icon: iconImage,
          //showInfoWindow: this.showInfo
        });

        const contentString =
          "<div>" +
          '<table border="0" width="100%">' +
          '<td colspan="2"><b className="text-muted"><i className="fas fa-chalkboard"></i>Crew Name</b><br /><label id="lblPaxName">' +
          vehicle.paxName +
          "</label></td>" +
          "<tr>" +
          '<td><b><i className="fas fa-car"></i> Vehicle</b><br /><span>' +
          vehicle.vehicleNo +
          "</span></td>" +
          '<td width = "50%"><strong className="text-muted"><i className="fas fa-user"></i> Driver</strong><br /><label>' +
          vehicle.driverName +
          '</label> <label className="badge badge-success" id="lblStatus">' +
          vehicle.statusDescripTion +
          "</label></td>" +
          "<tr>" +
          "<tr>" +
          '<td><strong className="text-muted"><i className="fas fa-clock"></i> Last GPS Time</strong><br /><label>' +
          formatDate(vehicle.gpsDateTime, "mm/dd/yyyy hh:mm:ss") +
          "</label></td>" +
          '<td><strong className="text-muted"><i className="fas fa-clock"></i> Pickup Time</strong><br /><label>' +
          formatDate(vehicle.pickUpDateTime, "mm/dd/yyyy hh:mm:ss") +
          "</label></td>" +
          "</tr>" +
          "<tr>" +
          '<td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Pick-up</strong><br /><label>' +
          vehicle.puStreet +
          "</label></td>" +
          '<td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Dropoff</strong><br /><label>' +
          vehicle.doStreet +
          "</label></td>" +
          "</tr>" +
          "<tr>" +
          '<td><strong className="text-muted"><i className="fas fa-hourglass-start"></i> <span id="lblEtaEtdName">Driver Mobile</span></strong><br /><span id="lblEtaEtd">' +
          vehicle.moblieNo +
          "</span></td>" +
          '<td><strong className="text-muted"><i className="fas fa-tachometer-alt"></i> Speed</strong><br /><label id="lblSpeed">' +
          vehicle.speed.toFixed(3) +
          "</label></td>" +
          "</tr>" +
          "</table>" +
          // + 'Vehicle: ' + vehicle.vehicleNo + '<br />'
          // + 'Status: ' + vehicle.statusDescripTion + '<br />'
          // + 'Last GpsTime: ' + formatDate(vehicle.gpsDateTime, 'mm/dd/yyyy hh:mm:ss') + '<br />'
          // + 'RideId: ' + vehicle.rideId + '<br />'
          // + 'Crew: ' + vehicle.paxName + '<br />'
          // + 'PickUp time: ' + formatDate(vehicle.pickUpDateTime, 'mm/dd/yyyy hh:mm:ss') + '<br />'
          // + 'Speed: ' + vehicle.speed + '<br />'
          // + 'PickUp Address: ' + vehicle.puStreet + '<br />'
          // + 'DropOff Address: ' + vehicle.doStreet + '<br />'
          // + 'Driver Name: ' + vehicle.driverName + '<br />'
          // + 'Driver Mobile: ' + vehicle.moblieNo + '<br />'
          "</div>";

        var infowindow = new window.google.maps.InfoWindow();

        window.google.maps.event.addListener(marker, "click", function () {
          var marker = this;
          infowindow.setContent(contentString);
          infowindow.open(this.map, marker);
        });

        // this.map = new window.google.maps.Map(this.mapRef.current, {
        //     center: new window.google.maps.LatLng(vehicle.latitude, vehicle.longitude),
        //     zoom: 8,
        //     styles: this.state.mapStyle,
        // });
        this.vehicleMarkers.push(marker);
        this.map.setZoom(5);
        bounds.extend(latLng);
      }

      if (!this.state.isAutoRefresh) {
        if (!bounds.isEmpty()) {
          this.map.fitBounds(bounds);
        }
      }
      this.setState({ isAutoRefresh: false, vehicleData: null });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".drawVehicleMarkers function",
        err
      );
    }
  };
  onVehicleFilterChanged = () => {
    let bounds = new window.google.maps.LatLngBounds();
    if (this.state.selectedvehicleId > 0) {
      let marker = this.vehicleMarkers.filter(
        (m) => m.vehicleId == this.state.selectedvehicleId
      )[0];
      let currentZoom = this.map.getZoom();
      if (currentZoom != 16) {
        this.map.setZoom(16);
      }

      if (marker != undefined) {
        this.map.setCenter(marker.getPosition());
      }
    } else {
      this.setState({ selectedvehicleId: 0 });
      // if (this.filterVehiclePolylineArry.length > 0) {
      //     for (let index = 0; index < this.filterVehiclePolylineArry.length; index++) {
      //         this.filterVehiclePolylineArry[index].setMap(null);
      //     }
      //     this.filterVehiclePolylineArry = [];
      // }

      // if (!this.state.isAutoRefresh && !bounds.isEmpty()) {
      //     this.map.fitBounds(bounds);
      // }
    }
  };
  setCurrentVehicle = (e, item) => {
    e.preventDefault();
    this.setState({ selectedvehicleId: item.vehicleId });
    //clearInterval(this.vehicleIntervalHandle);
    let myVar = setTimeout(() => this.getTracking(), 500);
    this.vehicleIntervalHandle = setInterval(() => {
      console.log("singleVehicleIntervalHandle");
      //this.getTracking();
      this.onVehicleFilterChanged();
    }, this.state.seconds * 1000);
  };
  handleAirportChange = async (e) => {
    if (e.selectedItem != null) {
      this.setState({
        airportId: e.selectedItem.airportId,
        airportCode: e.selectedItem.airportCode,
        vehicleId: 0,
        center: {
          lat: e.selectedItem.latitude,
          lng: e.selectedItem.longitude,
        },
        zoom: 8,
      });
    } else {
      this.setState({
        airportId: 0,
        airportCode: "",
        vehicleId: 0,
        center: {
          lat: 20.5937,
          lng: 78.9629,
        },
        zoom: 8,
      });
    }
    //setTimeout(() => { this.bindMap(); }, 300);
  };
  handleServiceProviderChange = async (e) => {
    this.setState({
      accountId: e.selectedItem != null ? e.selectedItem.accountId : 0,
    });
  };
  closeInfo = async (e) => {
    this.setState({
      airportId: 0,
      airportCode: "",
      accountId: 0,
      datasource: [],
      singledatasource: [],
      infoWindow: true,
      infoVehicle: "none",
      vehicleId: 0,
      selectedvehicleId: 0,
      accountName: "",
      driverName: "",
      driverMob: "",
      status: "",
      lastGpsTime: "",
      rideId: 0,
      pickUpTime: "",
      speed: 0,
      provider: "",
      puAddress: "",
      doAddress: "",
      eta: "",
    });
    setTimeout(() => {
      this.removeMarkers();
    }, 500);
    setTimeout(() => {
      this.getTracking();
    }, 1000);
  };
  render() {
    return (
      <Fragment>
        <div className="card mb-2">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h6 className="text-primary">
                  Note: Tracking will display only when vehicle(s) are on JOB.
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <div className="form-group">
                  <label>Airport(s)</label>
                  <SelectBox
                    dataSource={this.state.airportList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="airportCode"
                    defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    //displayExpr={this.displayExpr}
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <div className="form-group">
                  <label>Service Provider(s)</label>
                  <SelectBox
                    dataSource={this.state.accountdatasource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Service Providers"
                    name="accountId"
                    defaultValue={0}
                    value={this.state.accountId}
                    displayExpr="accountName"
                    valueExpr="accountId"
                    onSelectionChanged={this.handleServiceProviderChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={this.getTracking}
                  >
                    {this.state.isSubmitted && (
                      <span title="In-process">
                        <i className="fas fa-spinner fa-spin"></i> In-process
                      </span>
                    )}
                    {!this.state.isSubmitted && (
                      <span title="Search">
                        <i className="fas fa-search"></i> Submit{" "}
                      </span>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={this.closeInfo}
                  >
                    {this.state.isSubmitted && (
                      <span title="In-process">
                        <i className="fas fa-spinner fa-spin"></i> In-process
                      </span>
                    )}
                    {!this.state.isSubmitted && (
                      <span title="Search">
                        <i className="fas fa-search"></i> Clear{" "}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body-map">
            <div className="row">
              {this.state.datasource != null &&
              this.state.datasource.length > 0 ? (
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-0">
                  <div className="tracking-list">
                    {this.state.datasource &&
                      this.state.datasource.map((item, i) => (
                        <div
                          className={
                            this.state.selectedvehicleId === item.vehicleId
                              ? "trip-data-row trip-data-row-selected"
                              : "trip-data-row"
                          }
                          key={i}
                        >
                          <div className="divider text-hr">
                            <span className="flight">
                              {" "}
                              <i className="fas fa-plane fa-rotate-270"></i>
                              &nbsp;{item.flightNumber}
                            </span>
                          </div>
                          <div className="tracking-info">
                            <div className="vehicle">
                              <span>
                                <i className="fas fa-car circle-icon"></i>
                              </span>
                            </div>
                            <div className="driver">
                              <a
                                href="#"
                                title="Track current location"
                                onClick={(e) => {
                                  this.setCurrentVehicle(e, item);
                                }}
                              >
                                <div>
                                  <strong>{item.vehicleNo}</strong>
                                </div>
                              </a>
                              <div className="text-muted">
                                {item.driverName}
                              </div>
                              <div className="pickup">
                                <div>
                                  <i className="far fa-clock"></i>{" "}
                                  {formatDate(item.gpsDateTime, "HH:mm")}
                                </div>
                                <div>
                                  <b>{item.statusDescripTion}</b>
                                </div>
                              </div>
                              <div>{item.employeeName}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  this.state.singledatasource != null &&
                  this.state.singledatasource.length > 0
                    ? "col-lg-10 col-md-10 col-sm-10 col-xs-10 pl-0"
                    : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
                }
              >
                <div
                  ref={this.mapRef}
                  id="map"
                  style={{ height: `75.5vh`, width: `99%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
