import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import {
  getRequestParams,
  formatDate,
  formatDate01,
} from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LoadPanel } from "devextreme-react/load-panel";
import Highcharts from "highcharts";
import { RadioGroup } from "devextreme-react/radio-group";

const position = { of: "#historydiv" };
const RideTypeLst = [
  { id: 0, name: "Both" },
  { id: 1, name: "Arrival" },
  { id: 2, name: "Departure" },
];

export class DashboardComponent extends Component {
  static displayName = DashboardComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      isCompleteDashboardApiLoaded: false,
      isAirlineRideApiLoaded: false,
      datasource: null,
      reservationdatasource: null,
      loadPanelVisible: false,
      totalAirport: 0,
      totalRides: 0,
      totalServiceProviders: 0,
      totalCompletedRides: 0,
      totalHotelBookings: 0,
      totalDepartureCount: 0,
      totalArrivalCount: 0,
      totalNoshowCount: 0,
      totalDelayCount: 0,
      totalCancelledCount: 0,
      totalCrewRides: 0,
      scheduleVsActualData: [],
      hourlyData: [],
      reservationTitle: "Crew Transfers for Today",
      rideTypeDataSource: RideTypeLst,
      rideTypeId: 0,
    };
  }

  componentDidMount() {
    try {
      this.callDashboardApi();
    } catch (error) {
      console.log(error);
    }
  }
  callDashboardApi = () => {
    try {
      setTimeout(async () => {
        await this.getCompleteDashboard(0);
      }, 0);
      setTimeout(async () => {
        await this.getReservations();
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };
  getCompleteDashboard = async (rideTypeId) => {
    try {
      this.setState((prev) => ({
        ...prev,
        isCompleteDashboardApiLoaded: true,
      }));
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let userId = parseInt(sessionStorage.getItem("session_userId"));
      let userName = sessionStorage.getItem("session_userName");
      let accountId = 0;
      const dashBoardRequest = {};
      dashBoardRequest.AccountId = accountId;
      dashBoardRequest.AirlineCode = airlineCode;
      dashBoardRequest.UserId = userId;
      dashBoardRequest.UserName = userName;
      dashBoardRequest.NoOfDays = 10;
      dashBoardRequest.RideTypeId = rideTypeId;
      debugger;
      const requestParams = getRequestParams("POST", dashBoardRequest);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Dashboard/CompleteDashboard",
        requestParams
      );
      debugger;
      const data = await response.json();
      this.setState((prev) => ({
        ...prev,
        isCompleteDashboardApiLoaded: false,
        totalAirport: data?.payload[0]?.serviceAirportCount ?? 0,
        totalServiceProviders: data?.payload[0]?.serviceAccountCount ?? 0,
        totalCompletedRides: data?.payload[0]?.totalRidesCompleteCount ?? 0,
        totalRides: data?.payload[0]?.totalRidesCount ?? 0,
        totalHotelBookings: data?.payload[0]?.totalHotelBookingCount ?? 0,
        scheduleVsActualData:
          data?.payload[0]?.airlineScheduleVsActualData ?? [],
        hourlyData: data?.payload[0]?.hourlyRidesModel ?? [],
        totalDepartureCount: data?.payload[0]?.totalDepartureCount ?? 0,
        totalArrivalCount: data?.payload[0]?.totalArrivalCount ?? 0,
        totalNoshowCount: data?.payload[0]?.totalNoshowCount ?? 0,
        totalDelayCount: data?.payload[0]?.totalDelayCount ?? 0,
        totalCancelledCount: data?.payload[0]?.totalCancelledCount ?? 0,
        totalCrewRides: data?.payload[0]?.totalCrewRides ?? 0,
      }));
      this.shceduleVsActualChartRender();
      this.hourlyChartRender();
    } catch (e) {
      this.setState((prev) => ({
        ...prev,
        isCompleteDashboardApiLoaded: false,
      }));
    }
  };
  getReservations = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.UserId = sessionStorage.getItem("session_userId");
      request.AirlineCode = airlineCode;
      request.StatusId = 0;
      request.FromDate =
        formatDate(this.state.filterFromDatetime, "yyyy-MM-dd") + " 00:00:00";
      request.ToDate =
        formatDate(this.state.filterFromDatetime, "yyyy-MM-dd") + " 23:59:59";
      request.AirportCode = this.state.airportCode;
      const requestParams = getRequestParams("POST", request);
      this.setState((prev) => ({
        ...prev,
        isAirlineRideApiLoaded: true,
      }));
      let url = process.env.REACT_APP_API_BASE_URL + "Rides/AirlineRides";
      const response = await fetch(url, requestParams);
      const data = await response.json();
      this.setState((prev) => ({
        ...prev,
        isAirlineRideApiLoaded: false,
        reservationdatasource:
          data.payload !== null
            ? data.payload.filter((f) => f.tripStatusId !== 118)
            : [],
      }));
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getReservations function",
        error
      );
      this.setState((prev) => ({
        ...prev,
        isAirlineRideApiLoaded: false,
      }));
    }
  };
  changeSelectionRideType = (e) => {
    try {
      this.setState({
        rideTypeId: e.value ?? 0,
      });
      this.getCompleteDashboard(e.value);
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".changeSelectionRideType function",
        error
      );
    }
  };
  handleRowPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.rideStatus === "Pending") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "CANCELLED") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "Driver En Route") {
        e.rowElement.style.backgroundColor = "#FFFB77";
      } else if (e.data.rideStatus === "Vehicle unassigned") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "Driver Rejected Job") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "On Location") {
        e.rowElement.style.backgroundColor = "#FFFF01";
      } else if (e.data.rideStatus === "Boarded") {
        e.rowElement.style.backgroundColor = "#D4FFD4";
      } else if (e.data.rideStatus === "NO SHOW") {
        e.rowElement.style.backgroundColor = "#eabdf5";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "NO SHOW - NON BILLABLE") {
        e.rowElement.style.backgroundColor = "#eabdf5";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "Ride Complete") {
        e.rowElement.style.backgroundColor = "#01B050";
        e.rowElement.style.foregroundColor = "#ffffff";
      }
    }
  };

  shceduleVsActualChartRender() {
    var daycategories = [];
    var totalrides = [];
    var totalcompletedrides = [];

    for (let i of this.state.scheduleVsActualData) {
      daycategories.push(i.dayName);
      totalrides.push(i.dayTotalRides);
      totalcompletedrides.push(i.totalCompleteRides);
    }

    let count1 = totalcompletedrides.filter((e) => e > 0);
    let count2 = totalrides.filter((e) => e > 0);

    Highcharts.chart("ScheduleVsActualChartContainer", {
      chart: {
        type: "column",
      },
      title: {
        text: count1.length > 0 || count2.length > 0 ? "" : "No data available",
      },
      xAxis: {
        categories: daycategories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Rides",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} Rides</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Total Rides",
          data: totalrides,
          color: "#cacaca",
        },
        {
          name: "Total Completed Rides",
          data: totalcompletedrides,
          color: "#7e7ecc",
        },
      ],
    });
  }
  hourlyChartRender() {
    var daycategories = [];
    var gtperformancedata = [];

    for (let i of this.state.scheduleVsActualData) {
      daycategories.push(i.dayName);
      gtperformancedata.push(i.gtPerformance);
    }

    let count = gtperformancedata.filter((e) => e > 0);

    Highcharts.chart("HourlyChartContainer", {
      chart: {
        type: "line",
      },
      title: {
        text: count.length > 0 ? "" : "No data available",
      },
      xAxis: {
        categories: daycategories,
      },
      yAxis: {
        title: {
          text: "GT Performance (%)",
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          name: "Day Name",
          data: gtperformancedata,
          color: "#FF6300",
        },
      ],
      lang: {
        noData: "no data!",
      },
      noData: {
        position: {
          x: 0,
          y: 0,
          align: "center",
          verticalAlign: "middle",
        },
      },
    });
  }

  AirportGridShow = () => {
    sessionStorage.setItem("session_PageName", "Airport");
    window.location.href = "/airport";
  };
  handleGridChanges = async (e, ids) => {
    e.preventDefault();
    let airlineCode = sessionStorage.getItem("session_airlineCode");
    var request = {};
    request.UserId = sessionStorage.getItem("session_userId");
    request.AirlineCode = airlineCode;
    request.StatusId = 0;
    request.FromDate =
      formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 00:00:00";
    request.ToDate =
      formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 23:59:59";
    request.AirportCode = this.state.airportCode;
    const requestParams = getRequestParams("POST", request);
    this.setState((prev) => ({
      ...prev,
      loadPanelVisible: true,
    }));
    let url = process.env.REACT_APP_API_BASE_URL + "Rides/AirlineRides";
    const response = await fetch(url, requestParams);
    const reservationdata = await response.json();
    const data =
      reservationdata.payload !== null
        ? reservationdata.payload.filter((f) => f.tripStatusId !== 118)
        : [];
    if (ids === 1) {
      var CrewTransfer = [];
      CrewTransfer =
        data != null ? data.filter((e) => e.tripStatusId !== 118) : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: CrewTransfer,
        loadPanelVisible: false,
        reservationTitle: "",
      }));
    } else if (ids === 2) {
      var DepartureRides = [];
      DepartureRides =
        data != null ? data.filter((e) => e.rideTypeId === 2) : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: DepartureRides,
        loadPanelVisible: false,
        reservationTitle: "Departure Rides",
      }));
    } else if (ids === 3) {
      var ArrivalRides = [];
      ArrivalRides = data != null ? data.filter((e) => e.rideTypeId === 1) : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: ArrivalRides,
        loadPanelVisible: false,
        reservationTitle: "Arrival",
      }));
    } else if (ids === 4) {
      var NoShowRides = [];
      NoShowRides =
        data != null
          ? data.filter((e) => e.tripStatusId === 109 || e.tripStatusId === 110)
          : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: NoShowRides,
        loadPanelVisible: false,
        reservationTitle: "No Show Rides",
      }));
    } else if (ids === 5) {
      var CancelledRides = [];
      CancelledRides =
        data != null ? data.filter((e) => e.tripStatusId === 111) : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: CancelledRides,
        loadPanelVisible: false,
        reservationTitle: "Cancelled Rides",
      }));
    } else if (ids === 6) {
      var DelayPickupRide = [];
      DelayPickupRide =
        data != null
          ? data.filter((e) => e.onLocationTime > e.pickUpDateTime)
          : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: DelayPickupRide,
        loadPanelVisible: false,
        reservationTitle: "Delay Pickup",
      }));
    } else {
      var CompletedRides = [];
      CompletedRides =
        data != null
          ? data.filter((e) => e.tripStatusId === 116 && e.tripStatusId !== 118)
          : [];
      this.setState((prev) => ({
        ...prev,
        reservationdatasource: CompletedRides,
        loadPanelVisible: false,
        reservationTitle: "Executed Crew Transfers",
      }));
    }
    this.setState((prev) => ({
      ...prev,
      loadPanelVisible: false,
    }));
  };

  render() {
    const renderPickupDropoffGridCell = (data) => {
      return (
        <div className="mergeData ellipsis">
          <span className="text-success">
            <i className="fas fa-map-marker-alt"></i>
          </span>
          <span className="text-muted"></span> {data.data.puStreet}
          <br />
          <span className="text-danger">
            <i className="fas fa-map-marker-alt"></i>
          </span>
          <span className="text-muted"></span> {data.data.doStreet}
        </div>
      );
    };
    const renderVehicleDriverGridCell = (data) => {
      return (
        <div className="mergeData ellipsis">
          <span className="text-muted">
            <i className="fa fa-car"></i>
          </span>{" "}
          <span className="text-muted"></span>{" "}
          {data.data.vehicleNo !== null ? data.data.vehicleNo : "N/A"}
          <br />
          <span className="text-muted">
            <i className="fa fa-user"></i>
          </span>{" "}
          <span className="text-muted"></span>{" "}
          {data.data.driverName !== " " ? data.data.driverName : "N/A"}
        </div>
      );
    };

    const renderPaxEmployeeGridCell = (data) => {
      if (data.data.paxName.includes("[")) {
        var position =
          data.data.paxName.split(/[\s`,]+/)[
            data.data.paxName.split(/[\s`,]+/).length - 1
          ];
        var _d = position.substring(0, position.lastIndexOf("-") + 1);
        var _p = _d.replace("[", "");
        var _paxName = _p.replace("-", "");
        var _pax =
          data.data.paxName.split(/[\s`,]+/)[
            data.data.paxName.split(/[\s`,]+/).length - 2
          ] +
          " " +
          data.data.paxName.split(/[\s`,]+/)[
            data.data.paxName.split(/[\s`,]+/).length - 3
          ] +
          "- " +
          _paxName;

        return (
          <div className="mergeData ellipsis">
            <span className="text-muted">
              <i className="fa fa-user"></i>
            </span>{" "}
            <span className="text-muted"></span>
            {_pax}
            <br />
            <span className="text-muted">
              <i className="fas fa-id-badge"></i>
            </span>{" "}
            <span className="text-muted"></span>
            {data.data.employeeNo}
          </div>
        );
      } else {
        return (
          <div className="mergeData ellipsis">
            <span className="text-muted">
              <i className="fa fa-user"></i>
            </span>{" "}
            <span className="text-muted"></span>
            {data.data.paxName}
            <br />
            <span className="text-muted">
              <i className="fas fa-id-badge"></i>
            </span>{" "}
            <span className="text-muted"></span>
            {data.data.employeeNo}
          </div>
        );
      }
    };

    const renderReportingDebreifingGridCell = (data) => {
      if (data.data.dutyReportingTime !== null) {
        return (
          <div className="mergeData ellipsis">
            <span className="text-muted"></span>{" "}
            <span className="text-muted">R -</span>
            {data.data.dutyReportingTime != null
              ? formatDate01(data.data.dutyReportingTime, "dd/MM/yy hh:mm")
              : ""}
            {/* <br />
                <span className='text-muted'></span> <span className='text-muted'>D - </span>{data.data.deBriefingTime != null ? formatDate01(data.data.deBriefingTime, 'dd/MM/yy hh:mm') :''} */}
          </div>
        );
      } else {
        return (
          <div className="mergeData ellipsis">
            <span className="text-muted"></span>{" "}
            <span className="text-muted">D - </span>
            {data.data.deBriefingTime != null
              ? formatDate01(data.data.deBriefingTime, "dd/MM/yy hh:mm")
              : ""}
          </div>
        );
      }
    };

    const renderPickUpDateTimeGridCell = (data) => {
      return (
        <div className="mergeData ellipsis">
          <span className="text-muted">
            <i className="fa fa-clock"></i>
          </span>{" "}
          <span className="text-muted">Pickup -</span>
          {data.data.pickUpDateTime != null
            ? formatDate01(data.data.pickUpDateTime, "dd/MM/yy hh:mm")
            : ""}
          <br />
          <span className="text-muted">
            <i className="fas fa-map-marker-alt"></i>
          </span>{" "}
          <span className="text-muted">On Loc.-</span>
          {data.data.onLocationTime != null
            ? formatDate01(data.data.onLocationTime, "dd/MM/yy hh:mm")
            : "N/A"}
        </div>
      );
    };
    const renderFilghtTime = (data) => {
      if (data.data.rideTypeId === 1) {
        return (
          <div className="mergeData ellipsis">
            <span className="text-muted">
              <i className="fas fa-plane-arrival"></i>
            </span>{" "}
            <span className="text-muted">{data.data.filghtDetails}</span>
          </div>
        );
      }
      return (
        <div className="mergeData ellipsis">
          <span className="text-muted">
            <i className="fas fa-plane-departure"></i>
          </span>{" "}
          <span className="text-muted">{data.data.filghtDetails}</span>
        </div>
      );
    };
    return (
      <Fragment>
        <div>
          <div className="row mb-3">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-info">
                <span className="info-box-icon">
                  <i className="fas fa-plane fa-rotate-270"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Airport Serviced</span>
                  <span
                    className="info-box-number"
                    onClick={this.AirportGridShow}
                  >
                    {this.state.totalAirport}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-warning">
                <span className="info-box-icon">
                  <i className="fas fa-car-alt"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">
                    Crew Transfers for Today
                  </span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 1);
                    }}
                  >
                    {this.state.totalCrewRides}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-brand">
                <span className="info-box-icon">
                  <i className="fas fa-plane-departure"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Departure</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 2);
                    }}
                  >
                    {this.state.totalDepartureCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-brand">
                <span className="info-box-icon">
                  <i className="fas fa-plane-arrival"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Arrival</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 3);
                    }}
                  >
                    {this.state.totalArrivalCount}
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box bg-warning">
                                <span className="info-box-icon"><i className="fas fa-building"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Hotel Bookings for Today</span>
                                    <span className="info-box-number">
                                        {this.state.totalHotelBookings}
                                    </span>
                                </div>
                            </div>
                        </div> */}
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-disable">
                <span className="info-box-icon">
                  <i className="fas fa-eye-slash"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">No Show</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 4);
                    }}
                  >
                    {this.state.totalNoshowCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-danger">
                <span className="info-box-icon">
                  <i className="fas fa-times"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Cancelled Rides</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 5);
                    }}
                  >
                    {this.state.totalCancelledCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-danger">
                <span className="info-box-icon">
                  <i className="fas fa-user-clock"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Delay Pickup</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 6);
                    }}
                  >
                    {this.state.totalDelayCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box bg-success">
                <span className="info-box-icon">
                  <i className="fas fa-exchange-alt"></i>
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">Executed Crew Transfers</span>
                  <span
                    className="info-box-number"
                    onClick={(e) => {
                      this.handleGridChanges(e, 7);
                    }}
                  >
                    {this.state.totalCompletedRides}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12 col-sm-12 col-md-12 pl-0">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 pl-0">
                  <div className="card shadow-sm rounded-vlg">
                    <div className="card-header">
                      <h4>Scheduled Vs Actual Pick-up Time</h4>
                      <div>
                        <RadioGroup
                          id="radio-group-with-selection"
                          dataSource={this.state.rideTypeDataSource}
                          layout="horizontal"
                          value={this.state.rideTypeId}
                          defaultValue={this.state.rideTypeId}
                          valueExpr="id"
                          displayExpr="name"
                          onValueChanged={this.changeSelectionRideType}
                        />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="tab-content p-0">
                        <div
                          id="ScheduleVsActualChartContainer"
                          style={{ height: "225px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 pl-0">
                  <div className="card shadow-sm rounded-vlg">
                    <div className="card-header">
                      <h4 className="card-title">On Time Performance</h4>
                    </div>
                    <div className="card-body">
                      <div className="tab-content p-0">
                        <div
                          id="HourlyChartContainer"
                          style={{ height: "225px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 pr-0">
              <div className="card">
                <div
                  className="card-header pt-2 pb-2 pl-3"
                  style={{ minHeight: "0px" }}
                >
                  <h4>{this.state.reservationTitle}</h4>
                </div>
                <div className="card-body">
                  <DataGrid
                    id="dashboardGrid"
                    dataSource={this.state.reservationdatasource}
                    keyExpr="rideId"
                    selection={{ mode: "single" }}
                    columnsAutoWidth={false}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnResizing={true}
                    onRowPrepared={this.handleRowPrepared}
                    onRowInserting={this.insertRow}
                    columnResizingMode="widget"
                  >
                    <Column
                      dataField="filghtDetails"
                      caption="Flight No"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={100}
                      cellRender={renderFilghtTime}
                    />
                    <Column
                      dataField="flightDateTime"
                      caption="Flight Date Time"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      dataType="datetime"
                      format="dd/MM/yy HH:mm"
                      width={160}
                    />
                    <Column
                      dataField="paxname"
                      caption="Crew / Emp #"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={200}
                      cellRender={renderPaxEmployeeGridCell}
                    />
                    <Column
                      dataField=""
                      caption="Reporting / Debreifing Time"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={230}
                      cellRender={renderReportingDebreifingGridCell}
                    />
                    <Column
                      dataField="pickUpDateTime"
                      caption="Pickup Date Time"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      dataType="datetime"
                      cellRender={renderPickUpDateTimeGridCell}
                      width={200}
                    />
                    <Column
                      dataField="puStreet"
                      caption="Pickup / Drop Off"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={280}
                      cellRender={renderPickupDropoffGridCell}
                    />
                    {/* <Column dataField="doStreet" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={280} /> */}
                    <Column
                      dataField="vehicleNo"
                      caption="Vehicle / Driver"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={150}
                      cellRender={renderVehicleDriverGridCell}
                    />
                    <Column
                      dataField="rideStatus"
                      caption="Status"
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      width={130}
                    />
                    {/* <Column dataField="driverName" caption="" allowSearch={true} allowFiltering={false} allowSorting={true} width={125} /> */}
                    {/* <Column dataField="pickUpDateTime" caption="ATA" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={90} /> */}
                    <SortByGroupSummaryInfo summaryItem="count" />
                    <GroupPanel visible={false} />
                    <HeaderFilter visible={true} />
                    <SearchPanel
                      visible={false}
                      highlightCaseSensitive={true}
                      width={240}
                      placeholder="Search..."
                    />
                    <Grouping autoExpandAll={true} />
                    <Paging defaultPageSize={15} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[15, 30, 50]}
                      showInfo={true}
                      visible={true}
                    />
                    <Export
                      enabled={false}
                      allowExportSelectedData={false}
                      fileName="Reservation"
                    />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={
            this.state.loadPanelVisible ||
            this.state.isCompleteDashboardApiLoaded ||
            this.state.isAirlineRideApiLoaded
          }
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
