import React, { Component, Fragment } from 'react';
import { SideBarComponent } from '../components/SideBarComponent';
import { ToastContainer } from 'react-toastify';
import IdleTimer from 'react-idle-timer' // npm i react-idle-timer
import 'react-toastify/dist/ReactToastify.css';
import { TopBarComponent } from './TopBarComponent';

export class AuthorisedLayout extends Component {
    static displayName = AuthorisedLayout.name;

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.timeout = 3600000;
        this.toastId = React.createRef();
        this.state = {
            pageName: sessionStorage.getItem("PageName") === null ? "Dashboard" : sessionStorage.getItem("PageName"),
            alertsSettings: [],
            alertData: {},
            deviceObj: {},
            isReadable: false,
            vehicleList: []
        }
        this.client = null;
        this.authWebSocketInterval = null;
    }

    componentDidMount() {
        window.authComponentUnloading = false;
        document.addEventListener("mousemove", this.handleMouseMove, false);
        document.addEventListener("keypress", this.handleKeyPress, false);        
    }

    componentWillUnmount() {
        window.authComponentUnloading = true;
        if (this.authWebSocketInterval !== null) {
            clearTimeout(this.authWebSocketInterval);
        }
        if (this.client != null) {
            try {
                this.client.close();
            } catch (err) { console.log(err); }
        }
    }
    toggleNavbar = async () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    handleMouseMove = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    };
    handleKeyPress = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    }
    handleOnActive = async () => {
        this.setState({ lastEvent: 'active' })
    }
    handleOnIdle = async (e) => {
        this.setState({ lastEvent: 'idle' })
        sessionStorage.clear();
        window.location.href = "/";
    }
    handleReset = async () => {
        this.idleTimer.reset()
    }
    handlePause = async () => {
        this.idleTimer.pause()
    }
    handleResume = async () => {
        this.idleTimer.resume()
    }

    render() {
        return (
            <Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    timeout={this.timeout}
                    crossTab={{
                        emitOnAllTabs: true
                    }}
                />
                <div className="main-wrapper">
                    <SideBarComponent />
                    <TopBarComponent pageName={sessionStorage.getItem('session_PageName')} />
                    <div name="mainContent" className="main-content">
                        <section className="section">
                                {this.props.children}
                        </section>
                    </div>
                </div>
                <ToastContainer
                    id={this.toastId.current}
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    style={{ width: "250px" }}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Fragment>
        );
    }
}