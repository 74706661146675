import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Modal from "react-modal"; //npm install --save react-modal;
import { getRequestParams } from "../../helpers/globalfunctions.js";
import { precisionFormats, symbolFormats } from "../../helpers/fixcodes.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
//import { parse } from "terser";

const customStyles = {
  content: {
    width: "30%",
  },
};

const approveStyles = {
  content: {
    width: "50%",
  },
};

export class ReimburseTransport extends Component {
  static displayName = ReimburseTransport.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      dataSource: null,
      loadPanelVisible: false,
      invoiceModelIsOpen: false,
      singleData: null,
      approvalIsOpen: false,
      documentUrl: "",
      approveAmount: parseFloat(0).toFixed(2),
      smalldocumentUrl: "",
      approvalComments: "",
      isApproved: false,
      errors: {
        approveAmount: "",
        approvalComments: "",
      },
    };
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getAirports.bind(this),
      },
    });
  };
  async componentDidMount() {
    await this.getReimburseTransport();
  }
  getReimburseTransport = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.EmployeeNumber = "";
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Reimbursement/CancelledReservation/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        datasource: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getReimburseTransport function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleReimbursementImage = (e, data) => {
    e.preventDefault();
    this.setState({
      invoiceModelIsOpen: true,
      documentUrl:
        data.documentUrl === null ? "" : data.imageBaseUrl + data.documentUrl,
    });
  };

  handleApprovalReimbursement = (e, data) => {
    e.preventDefault();
    this.setState({
      approvalIsOpen: true,
      approveAmount: parseFloat(data.voucherAmount).toFixed(2),
      smalldocumentUrl:
        data.documentUrl === null ? "" : data.imageBaseUrl + data.documentUrl,
      singleData: data,
      isApproved: data.isApproved,
    });
  };

  closeUserModal = () => {
    this.setState({
      isSubmited: false,
      dataSource: null,
      loadPanelVisible: false,
      invoiceModelIsOpen: false,
    });
  };

  closeApprovalModal = () => {
    this.setState({
      isSubmited: false,
      dataSource: null,
      loadPanelVisible: false,
      invoiceModelIsOpen: false,
      approvalIsOpen: false,
      approveAmount: parseFloat(0, 2),
      smalldocumentUrl: "",
      approvalComments: "",
      errors: {
        approveAmount: "",
        approvalComments: "",
      },
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  handleAssignDataToState = async () => {
    try {
      if (this.state.singleData.isApproved === true) {
        this.setState({
          approveAmount: parseFloat(
            this.state.singleData.approvedAmount
          ).toFixed(2),
          approvalComments: this.state.singleData.approvedComments,
        });
      } else {
        this.setState({
          approveAmount: parseFloat(
            this.state.singleData.voucherAmount
          ).toFixed(2),
          approvalComments: "",
        });
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleAssignDataToState function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.userName = "";
      errors.userTypeId = "";

      if (this.state.approveAmount === 0) {
        formIsValid = false;
        errors.approveAmount = "Please enter approval amount.";
      }
      if (this.state.approveAmount > this.state.singleData.voucherAmount) {
        formIsValid = false;
        errors.approveAmount =
          "Approval amount should not be more than voucher amount.";
      }
      //if (this.state.approveAmount )
      if (this.state.approvalComments === "") {
        formIsValid = false;
        errors.approvalComments = "Please approval comments.";
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleValidation function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };

  handleApprovalSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        let UserId = sessionStorage.getItem("session_userId");
        var newObject = {};
        newObject.TripId = this.state.singleData.tripid;
        newObject.TransactionId = this.state.singleData.transactionId;
        newObject.ApprovedAmount = this.state.approveAmount;
        newObject.ApprovedBy = UserId;
        newObject.ApprovedComments = this.state.approvalComments.trim();
        const requestParams = getRequestParams("POST", newObject);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "Reimbursement/ApproveReimbursement",
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getReimburseTransport();
              this.closeApprovalModal();
            }
            this.setState({ isSubmited: false });
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
          this.setState({ isSubmited: false });
        }
      }
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleUsehandleApprovalSubmitrSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };
  render() {
    const renderGridCell = (data) => {
      return (
        <div>
          {data.data.documentUrl === null ? (
            ""
          ) : (
            <a
              href="#"
              className="fa fa-solid fa-file-invoice"
              title="View Invoice"
              onClick={(e) => {
                this.handleReimbursementImage(e, data.data);
              }}
            ></a>
          )}
          &nbsp;&nbsp;
        </div>
      );
    };

    const renderApproveCell = (data) => {
      return (
        <div>
          {data.data.isApproved === true ? (
            <a
              href="#"
              title="View Invoice"
              onClick={(e) => {
                this.handleApprovalReimbursement(e, data.data);
              }}
            >
              <span className="textApproved">Approved</span>
            </a>
          ) : (
            <a
              href="#"
              title="View Invoice"
              onClick={(e) => {
                this.handleApprovalReimbursement(e, data.data);
              }}
            >
              <span className="textPending">Pending</span>
            </a>
          )}{" "}
          &nbsp;&nbsp;
        </div>
      );
    };

    const renderVoucherAmount = (data) => {
      return (
        <div>
          {/* Rs. {parseFloat(data.data.voucherAmount).toFixed(2)} */}
          <small>
            <i className={symbolFormats.ruppesFormat}></i>
          </small>
          &nbsp;&nbsp;
          {parseFloat(
            data.data.voucherAmount !== undefined
              ? data.data.voucherAmount
              : 0.0
          ).toFixed(precisionFormats[2])}
        </div>
      );
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.datasource}
              keyExpr="transactionId"
              selection={{ mode: "single" }}
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
            >
              <Column
                dataField="transactionId"
                caption=""
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={22}
                cellRender={renderGridCell}
              />
              <Column
                dataField="transactionId"
                caption=""
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={65}
                cellRender={renderApproveCell}
              />
              <Column
                dataField="transactionId"
                caption="Transaction Id"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={210}
              />
              <Column
                dataField="employeeNumber"
                caption="Employee Number"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={130}
              />
              <Column
                dataField="tripid"
                caption="Trip Id"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
                width={100}
              />
              <Column
                dataField="flightNumber"
                caption="Flight Number"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={110}
              />
              <Column
                dataField="pickupTime"
                caption="Pickup Date Time"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="transportationCompany"
                caption="Transportation Company"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="voucherAmount"
                caption="Voucher Amount"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={120}
                cellRender={renderVoucherAmount}
                format={"###.##"}
              />
              <Column
                dataField="cancellationDate"
                caption="Cancellation Date"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="voucherDate"
                caption="Voucher Date"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="cancelreason"
                caption="Cancel Reason"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
              />
              <Column
                dataField="approvedComments"
                caption="Approval Comments"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
              />
              <Column
                dataField="approvedDate"
                caption="Approval Date"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
              />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Hotels"
              />
            </DataGrid>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.invoiceModelIsOpen}
          style={customStyles}
          className={"react-modal"}
          onRequestClose={this.closeUserModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleUserSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">{"View Attachment"}</h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeUserModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="imagecenter">
                <img
                  src={this.state.documentUrl}
                  style={{ height: "50vh", width: "80%" }}
                  className="imagecenter"
                ></img>
              </div>
            </div>
            <div className="modal-footer"></div>
          </form>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.approvalIsOpen}
          style={approveStyles}
          className={"react-modal"}
          onAfterOpen={this.handleAssignDataToState}
          onRequestClose={this.closeApprovalModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleApprovalSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">{"Reimbursement Approval"}</h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeApprovalModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <div className="form-group">
                    <label>
                      Approve Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="approveAmount"
                      placeholder="Enter approve amount"
                      value={this.state.approveAmount}
                      disabled={this.state.id > 0}
                      title="Approval Amount"
                      maxLength="7"
                      format={"###.##"}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["approveAmount"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["approveAmount"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div className="form-group">
                    <label>
                      Approval comments <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="approvalComments"
                      placeholder="Enter approal comments"
                      maxLength="50"
                      title="Enter approval comments"
                      value={this.state.approvalComments}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors["approvalComments"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["approvalComments"]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                  <div className="form-group">
                    <label>Receipt</label>
                    <img
                      src={this.state.smalldocumentUrl}
                      style={{ height: "10vh", width: "70%" }}
                      className="imagecenter"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {this.state.isApproved === false ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleApprovalSubmit}
                >
                  {this.state.isSubmited && (
                    <span title="In-process">
                      <i className="fas fa-spinner fa-spin"></i> In-process
                    </span>
                  )}
                  {!this.state.isSubmited && (
                    <span title="Submit">
                      <i className="fas fa-check"></i> Submit
                    </span>
                  )}
                </button>
              ) : (
                ""
              )}
              <button
                type="button"
                className="btn btn-custom-gray "
                onClick={this.closeApprovalModal}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}
