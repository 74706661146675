import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Margin } from 'devextreme-react/bullet';
import { DisplayFormat } from 'devextreme-react/date-box';

const customStyles = {
    content: {
        width: '85%',
        height: '80%',
    }
};
const customMaxStyles = {
    content: {
        width: '100%',
        height: '100%',
    }
};
const position = { of: '#locateVehiclediv' };

export class ReplayVehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            rideId: 0,
            vehicleId: 0,
            locationdate: '',
            completeDate: '',
            trackingSourceId: 0,
            mapUrl: ''
            //customeStyle: status == "Max" ? customStyles : customMaxStyles
        }

        this.showModal = this.showModal.bind(this);
     }

    handleCloseModal = () => {
        this.setState({
            isOpenModal: false,
            rideId: 0,
            mapUrl: ''
        })
    }

    showModal = async (rideId, fmVehicleId, onLocationTime, onCompletedTime) => {
        this.setState({
            isOpenModal: true,
            rideId: rideId,
            vehicleId: fmVehicleId,
            locationdate: onLocationTime,
            completeDate: onCompletedTime,
        });
        this.setState({
            mapUrl: process.env.REACT_APP_MapUrl,
        });
    }

    render() {
        let url = this.state.mapUrl + "replay?vehicleId=" + this.state.vehicleId + "&fromDatetime=" + this.state.locationdate + "&toDatetime=" + this.state.completeDate + "&rideId=" + this.state.rideId;
        

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={customStyles}
                    className={"react-modal"}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form>
                        <div id="locateVehiclediv">
                            <div className="modal-header">
                                <h6 className="modal-title">Ride Replay</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    {/*<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">*/}
                                    {/*    <small>Ride Id: <i><strong className="text-danger"> {this.state.rideId} </strong></i></small>&nbsp;*/}
                                    {/*    <small>On Location Time: <i><strong className="text-danger"> {this.state.locationdate} </strong></i></small>&nbsp;*/}
                                    {/*    <small>Drop off Time: <i><strong className="text-danger"> {this.state.completeDate} </strong></i></small>*/}
                                    {/*    <small>Total Distance: 0Km</small>*/}
                                    {/*</div>*/}
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-map-container">
                                            <iframe width="100%" height="550" frameBorder="0" scrolling="no" allowtransparency="true" src={url}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        position={position}
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                        closeOnOutsideClick={false}
                    />
                </Modal>

            </div>
        );
    }
}

