import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Modal from "react-modal"; //npm install --save react-modal;
import DateBox from "devextreme-react/date-box";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { DateFormat } from "../../helpers/fixcodes.js";
import { LoadPanel } from "devextreme-react/load-panel";
const position = { of: "#historydiv" };

const customStyles = {
  content: {
    width: "30%",
  },
};

export class FeedbackComponent extends Component {
  static displayName = FeedbackComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      resolvedModelIsOpen: false,
      transportDatasource: [],
      hotelList: [],
      transportList: [],
      transportFeedbackId: 2,
      hotelFeedbackId: 1,
      searchFromDate: "08/01/2022 00:00:00", //new Date(),
      searchToDate: new Date(),
      loadPanelVisible: false,
    };
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.GetRating.bind(this),
      },
    });
  };
  async componentDidMount() {
    await this.GetHotelFeedback();
    await this.GetTransportFeedback();
  }
  GetHotelFeedback = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.TypeofFeedback = parseInt(this.state.hotelFeedbackId);
      request.FromDate = formatDate(this.state.searchFromDate, "MM/dd/yyyy");
      request.ToDate = formatDate(this.state.searchToDate, "MM/dd/yyyy");
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "HotelFeedback/Feedback",
        requestParams
      );
      const data = await response.json();

      this.setState({
        datasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getHotelList function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  GetTransportFeedback = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.TypeofFeedback = parseInt(this.state.transportFeedbackId);
      request.FromDate = formatDate(this.state.searchFromDate, "MM/dd/yyyy");
      request.ToDate = formatDate(this.state.searchToDate, "MM/dd/yyyy");
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "HotelFeedback/Feedback",
        requestParams
      );
      const data = await response.json();

      this.setState({
        transportDatasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".GetTransportFeedback function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleSearchFeedback = (e) => {
    e.preventDefault();
    this.GetHotelFeedback();
    this.GetTransportFeedback();
  };
  handleFromValueChanged = (date) => {
    this.setState({ searchFromDate: date.value });
  };
  handleToValueChanged = (date) => {
    this.setState({ searchToDate: date.value });
  };
  handleFeedbackResolve = (e, data) => {
    e.preventDefault();
    this.setState({
      resolvedModelIsOpen: true,
    });
  };
  closeUserModal = () => {
    this.setState({
      isSubmited: false,
      dataSource: null,
      loadPanelVisible: false,
      resolvedModelIsOpen: false,
    });
  };
  render() {
    const renderEmployeeName = (data) => {
      return `${data.data.firstName} ${data.data.lastName}`;
    };

    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filtersearchFromDatetime"
                    placeholder="MM/dd/yyyy"
                    displayFormat="MM/dd/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.searchFromDate}
                    value={this.state.searchFromDate}
                    onValueChanged={this.handleFromValueChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filtersearchFromDatetime"
                    placeholder="MM/dd/yyyy"
                    displayFormat="MM/dd/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.searchToDate}
                    value={this.state.searchToDate}
                    onValueChanged={this.handleToValueChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <br></br>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleSearchFeedback}
                >
                  <span title="Submit">
                    <i className="fas fa-search"></i> Search
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-2"
                id="custom-tabs-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-home"
                    role="tab"
                    aria-controls="custom-tabs-home"
                    aria-selected="true"
                    value={this.state.hotelFeedbackId}
                    onClick={(e) => this.GetHotelFeedback()}
                  >
                    Hotel
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-profile-tab"
                    data-toggle="pill"
                    href="#custom-tabs-profile"
                    role="tab"
                    aria-controls="custom-tabs-profile"
                    aria-selected="false"
                    value={this.state.transportFeedbackId}
                    onClick={(e) => this.GetTransportFeedback()}
                  >
                    Transport{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-home-tab"
              >
                <DataGrid
                  dataSource={this.state.datasource}
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.handleToolbarPreparing}
                >
                  <Column
                    dataField="airlineCode"
                    width={70}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Airline"
                  />
                  <Column
                    dataField="employeeNumber"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee#"
                  />
                  <Column
                    dataField=""
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Name"
                    cellRender={renderEmployeeName}
                  />
                  <Column
                    dataField="airlineCrewType"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Crew Position"
                  />
                  <Column
                    dataField="recievedFrom"
                    width={225}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Email"
                  />
                  <Column
                    dataField="airportCode"
                    width={120}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Airport Code"
                  />
                  <Column
                    dataField="hotel"
                    width={180}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Hotel Name "
                  />
                  <Column
                    dataField="PairingNumber"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Pairing Number"
                  />
                  <Column
                    dataField="status"
                    width={120}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Status"
                  />
                  <Column
                    dataField="issueType"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Type"
                  />
                  <Column
                    dataField="priority"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Priority"
                  />
                  <Column
                    dataField="checkInDate"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Check In Datetime"
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="checkOutDate"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="CheckOut Datetime"
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="roomNumber"
                    width={120}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Room Number"
                  />
                  <Column
                    dataField="flightNumber"
                    width={120}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Flight Number"
                  />
                  <Column
                    dataField="dateofOccurrence"
                    width={180}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Submission Datetime"
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="resolvedBy"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Resolved By"
                  />
                  <Column
                    dataField="resolutionDate"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Resolution Datetime"
                    format={DateFormat.DateTime24WesternFormat}
                  />
                  <Column
                    dataField="replyMsg"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Reply Message"
                  />
                  <Column
                    dataField="imageFileName"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Image"
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={false} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="HotelFeedback"
                  />
                </DataGrid>
              </div>
              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-profile"
                role="tabpanel"
                aria-labelledby="custom-tabs-profile-tab"
              >
                <DataGrid
                  dataSource={this.state.transportDatasource}
                  keyExpr="hcFeedbackId"
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.handleToolbarPreparing}
                >
                  <Column
                    dataField="airlineCode"
                    width={60}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Airline"
                  />
                  <Column
                    dataField=""
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Name"
                    cellRender={renderEmployeeName}
                  />
                  <Column
                    dataField="employeeNumber"
                    width={125}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Number"
                  />
                  <Column
                    dataField="airlineCrewType"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Crew Position"
                  />
                  <Column
                    dataField="recievedFrom"
                    width={250}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Email"
                  />
                  <Column
                    dataField="airportCode"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Airport Code"
                  />
                  <Column
                    dataField="transporterName"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Transport Name"
                  />
                  <Column
                    dataField="pairingNumber"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Pairing Number"
                  />
                  <Column
                    dataField="status"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Status"
                  />
                  <Column
                    dataField="issueType"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Type"
                  />
                  <Column
                    dataField="priority"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Issue Priority"
                  />
                  <Column
                    dataField="flightNumber"
                    width={120}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Flight Number"
                  />
                  <Column
                    dataField="description"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Description"
                  />
                  <Column
                    dataField="transportDateTime"
                    width={180}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Transport DateTime"
                  />
                  <Column
                    dataField="dateofOccurrence"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Submission Datetime"
                  />
                  <Column
                    dataField="resolvedBy"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Resolved By"
                  />
                  <Column
                    dataField="resolutionDate"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Resolution Datetime"
                  />
                  <Column
                    dataField="replyMsg"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Reply Message"
                  />
                  <Column
                    dataField="imageFileName"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Image"
                  />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    displayMode="full"
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="TransportFeedback"
                  />
                </DataGrid>
              </div>
            </div>
            <Modal
              ariaHideApp={false}
              isOpen={this.state.resolvedModelIsOpen}
              style={customStyles}
              className={"react-modal"}
              onRequestClose={this.closeUserModal}
              shouldCloseOnOverlayClick={false}
              contentLabel="Example Modal"
            >
              <form
                method="post"
                onSubmit={this.handleUserSubmit}
                style={
                  this.state.isSubmited
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
              >
                <div className="modal-header">
                  <h6 className="modal-title">{"Resolved Feedback"}</h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeUserModal}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-footer"></div>
              </form>
            </Modal>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
