import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";

import DateBox from "devextreme-react/date-box";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LoadPanel } from "devextreme-react/load-panel";
const position = { of: "#historydiv" };

export class RatingComponent extends Component {
  static displayName = RatingComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      transportDatasource: [],
      hotelList: [],
      transportList: [],
      transportRatingId: 2,
      hotelRatingId: 1,
      searchFromDate: new Date(),
      searchToDate: new Date(),
      loadPanelVisible: false,
    };
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.GetRating.bind(this),
      },
    });
  };
  async componentDidMount() {
    await this.GetHotelsRating();
    await this.GetTransportRating();
  }

  GetHotelsRating = async (e) => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.RatingType = parseInt(this.state.hotelRatingId);
      request.FromDate = formatDate(this.state.searchFromDate, "MM/dd/yyyy");
      request.ToDate = formatDate(this.state.searchToDate, "MM/dd/yyyy");
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Rating/GetRating",
        requestParams
      );
      const data = await response.json();
      this.setState({
        datasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".GetHotelsRating function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  GetTransportRating = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.RatingType = parseInt(this.state.transportRatingId);
      request.FromDate = formatDate(this.state.searchFromDate, "MM/dd/yyyy");
      request.ToDate = formatDate(this.state.searchToDate, "MM/dd/yyyy");
      request.UserMasterId = parseInt(1);
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Rating/GetRating",
        requestParams
      );
      const data = await response.json();
      this.setState({
        transportDatasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".GetTransportRating function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleSearchRating = (e) => {
    e.preventDefault();
    this.GetHotelsRating();
  };
  handleFromValueChanged = (date) => {
    this.setState({ searchFromDate: date.value });
  };
  handleToValueChanged = (date) => {
    this.setState({ searchToDate: date.value });
  };

  render() {
    const renderServiceRating = (data) => {
      return drawRating(data.data.service);
    };

    const renderCleanlinessRating = (data) => {
      return drawRating(data.data.cleanliness);
    };

    const renderPunctualityRating = (data) => {
      return drawRating(data.data.punctuality);
    };

    const renderQualityRating = (data) => {
      return drawRating(data.data.quality);
    };

    const drawRating = (count) => {
      if (count === 1) {
        return (
          <div>
            <i className="fa fa-light fa-star"></i>
          </div>
        );
      } else if (count === 2) {
        return (
          <div>
            <i className="fa fa-light fa-star"></i>
            <i class="fa fa-light fa-star"></i>
          </div>
        );
      } else if (count === 3) {
        return (
          <div>
            <i className="fa fa-light fa-star"></i>
            <i class="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
          </div>
        );
      } else if (count === 4) {
        return (
          <div>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
          </div>
        );
      } else if (count === 5) {
        return (
          <div>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
            <i className="fa fa-light fa-star"></i>
          </div>
        );
      }
    };

    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filtersearchFromDatetime"
                    placeholder="MM/dd/yyyy"
                    displayFormat="MM/dd/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.searchFromDate}
                    value={this.state.searchFromDate}
                    onValueChanged={this.handleFromValueChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filtersearchFromDatetime"
                    placeholder="MM/dd/yyyy"
                    displayFormat="MM/dd/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.searchToDate}
                    value={this.state.searchToDate}
                    onValueChanged={this.handleToValueChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <br></br>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleSearchRating}
                >
                  <span title="Submit">
                    <i className="fas fa-search"></i> Search
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-2"
                id="custom-tabs-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-home"
                    role="tab"
                    aria-controls="custom-tabs-home"
                    aria-selected="true"
                    value={this.state.hotelRatingId}
                  >
                    Hotel
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-profile-tab"
                    data-toggle="pill"
                    href="#custom-tabs-profile"
                    role="tab"
                    aria-controls="custom-tabs-profile"
                    aria-selected="false"
                    value={this.state.transportRatingId}
                    onClick={(e) => this.GetTransportRating()}
                  >
                    Transport{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-home-tab"
                onClick={(e) => this.GetHotelsRating(e)}
              >
                <DataGrid
                  dataSource={this.state.datasource}
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.handleToolbarPreparing}
                >
                  <Column
                    dataField=""
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee"
                  />
                  <Column
                    dataField="airlineCode"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="AirlineCode "
                  />
                  <Column
                    dataField="airportCode"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="AirportCode"
                  />
                  <Column
                    dataField="employeeNumber"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee Name"
                  />
                  {/* <Column dataField="" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="From " /> */}
                  <Column
                    dataField="hotelName"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Hotel Name"
                  />
                  <Column
                    dataField="service"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Facility"
                  />
                  <Column
                    dataField=""
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Amenities"
                  />
                  <Column
                    dataField=""
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Check In "
                  />
                  {/* <Column dataField="food" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Food" />
                                    <Column dataField="cleanliness" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Cleanliness" />
                                    <Column dataField="quality" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Room Quality" />
                                    <Column dataField="Bed" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Sleep Quality" />
                                    <Column dataField="comments" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Comments" />
                                    <Column dataField="reservationDate" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Reservation Date" />
                                    <Column dataField="ratingType" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Rating Type" />
                                    <Column dataField="replyMsg" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Reply Msg" />
                                    <Column dataField="replyDatetime" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Reply Datetime" /> */}
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={false} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="HotelRating"
                  />
                </DataGrid>
              </div>

              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-profile"
                role="tabpanel"
                aria-labelledby="custom-tabs-profile-tab"
                onClick={(e) => this.GetTransportRating()}
              >
                <DataGrid
                  dataSource={this.state.transportDatasource}
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.handleToolbarPreparing}
                >
                  <Column
                    dataField="airlineCode"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="AirlineCode "
                  />
                  <Column
                    dataField="airportCode"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="AirportCode"
                  />
                  <Column
                    dataField="employeeNumber"
                    width={100}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Employee"
                  />
                  {/* <Column dataField="" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="From " /> */}
                  <Column
                    dataField="transporterName"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Transporter Name"
                  />
                  <Column
                    dataField="service"
                    width={210}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Service"
                    cellRender={renderServiceRating}
                  />
                  <Column
                    dataField="cleanliness"
                    width={210}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Cleanliness"
                    cellRender={renderCleanlinessRating}
                  />
                  <Column
                    dataField="punctuality"
                    width={210}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Punctuality"
                    cellRender={renderPunctualityRating}
                  />
                  <Column
                    dataField="quality"
                    width={210}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Quality"
                    cellRender={renderQualityRating}
                  />
                  {/* <Column dataField="comments" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Comments" />
                                    <Column dataField="reservationDate" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Reservation Date" />
                                    <Column dataField="dateOfRating" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Rating Date Time" />
                                    <Column dataField="replyMsg" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Reply Msg" />
                                    <Column dataField="replyDatetime" width={150} allowSearch={true} allowFiltering={false} allowSorting={true} caption=" Reply Datetime" /> */}
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    displayMode="full"
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="TransportRating"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
