import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  ColumnFixing,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { LoadPanel } from "devextreme-react/load-panel";
const position = { of: "#historydiv" };
const google = window.google;
const crewStatus = [
  { id: 1, name: "Active" },
  { id: 2, name: "In Active" },
];
const customStyles = {
  content: {
    width: "65%",
  },
};
const editCustomStyles = {
  content: {
    width: "45%",
  },
};
export class CrewMembersComponent extends Component {
  static displayName = CrewMembersComponent.name;
  constructor(props) {
    super(props);
    this.autocompletePickup = null;
    this.state = {
      isSubmited: false,
      datasource: null,
      crewStatusList: [],
      status: "Active",
      loadPanelVisible: false,
      viewCrewMemberisOpen: false,
      viewEmployeeNumber: 0,
      viewGetLocationGridDataSource: null,
      editBaseLocationisOpen: false,
      editRowIndex: 0,
      editAirlineCode: "",
      editEmpNo: "",
      editAddressType: "",
      editAddressTypeId: "",
      editAddress: "",
      editAddress1: "",
      editAddress2: "",
      editCity: "",
      editState: "",
      editCountry: "",
      editPostalCode: "",
      editLandmark: "",
      editLatitude: 0,
      editLongitude: 0,
      editBaseLocation: "",
      isDelete: true,
      isDefault: true,
      editAddressTitle: "",
      pickupGoogle: "",
      customer: 0,
      dropdownZone: [],
      pickupLocation: "",
      pickupAddress: "",
      pickupAddressType: "",
      pickupCity: "",
      pickupState: "",
      pickupZipCode: "",
      pickupLatitude: 0,
      pickupLongitude: 0,
      rowIndexid: 0,
      test: [],
      isPickupGoogleBooking: false,
    };
    this.toolbarItemRender = this.toolbarItemRender.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.getZones = this.getZones.bind(this);
    this.handlePlaceSearch = this.handlePlaceSearch.bind(this);
    this.handlePickupPlaceSelect = this.handlePickupPlaceSelect.bind(this);
  }

  async componentDidMount() {
    await this.getCrewMember();
    await this.getCrewMemberStatus();
    // await this.updateBaseLocation();
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.key = "Tab";
    }
  };

  getCrewMember = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.Status = this.state.status;
      request.AirportCode = "";
      request.FlightNo = "";
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      );
      const data = await response.json();
      this.setState({
        datasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  getCrewMemberStatus = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      this.setState({
        crewStatusList: crewStatus,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMemberStatus function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  handleCrewStatusChange = (e) => {
    this.setState({
      status: e.selectedItem !== null ? e.selectedItem.name : "",
    });
    this.getCrewMember();
  };

  // this is for View Grid data
  viewCrewMember = (e, data) => {
    e.preventDefault();
    this.setState({
      viewCrewMemberisOpen: true,
      editBaseLocationisOpen: false,
      singelData: data,
      viewEmployeeNumber: data.employeeNumber,
    });
  };

  afterOpenViewModal = async () => {
    const dataV = this.state.singelData;
    this.setState({
      viewEmployeeNumber: dataV.employeeNumber,
    });
    this.getBaseLocation(this.state.customer);
  };

  getBaseLocation = async () => {
    let viewGetLocationGridDataSource = [];
    let rowIndexid = 0;
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Crew/GetBaseLocationAddresses/${airlineCode}/${this.state.viewEmployeeNumber}`,
        requestParams
      );
      debugger;
      const data = await response.json();
      viewGetLocationGridDataSource = data ?? [];
      rowIndexid = data.length > 0 ? data[0].rowIndex : 0;
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      viewGetLocationGridDataSource: viewGetLocationGridDataSource,
      rowIndexid: rowIndexid,
      loadPanelVisible: false,
    });
  };

  handleResetStateData = async () => {
    this.setState({
      isSubmited: false,
      crewStatusList: [],
      status: "Active",
      loadPanelVisible: false,
      viewCrewMemberisOpen: false,
      viewEmployeeNumber: 0,
      rowIndexid: 0,
      viewGetLocationGridDataSource: null,
    });
  };

  //handle input change.
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handlePickupToggleChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" || target.type === "radio"
        ? target.checked
        : target.value;
    this.setState({ isPickupGoogleBooking: value });
  };

  updateBaseLocation = async (e, data) => {
    e.preventDefault();
    this.setState({
      singelData: data,
      editBaseLocationisOpen: true,
      editAirlineCode: data.airlineCode,
      editRowIndex: data.rowIndex,
      editEmpNo: data.employeeNo,
      editAddressType: data.addressType,
      editAddressTypeId: data.addressTypeId,
      editAddress: data.address,
      editAddress1: data.address1,
      editAddress2: data.address2,
      editCity: data.city,
      editState: data.state,
      editCountry: data.country,
      editPostalCode: data.postalCode,
      editLandmark: data.landmark,
      editLatitude: data.latitude,
      editLongitude: data.longitude,
      isDefault: data.isDefault,
      isDelete: data.isDelete,
      editAddressTitle: data.addressTitle,
      editBaseLocation: data.baseStation,
      pickupGoogle: "",
      customer: 0,
      pickupLocation: "",
      pickupAddress: "",
      pickupAddressType: "",
      pickupCity: "",
      pickupState: "",
      pickupZipCode: "",
      pickupLatitude: 0,
      pickupLongitude: 0,
      errors: {
        pickupLocation: "",
      },
    });
    this.loadPage(this.state.customer);
  };

  //1 Steps for location
  loadPage = async (data) => {
    this.setState({ customer: data });
    await this.getZones();
    var options = {
      types: ["establishment"],
      componentRestrictions: { country: "IND" },
    };
    this.autocompletePickup = new google.maps.places.Autocomplete(
      document.getElementById("pickupGoogle"),
      options
    );
    this.autocompletePickup.addListener(
      "place_changed",
      this.handlePickupPlaceSelect
    );
    this.setState({
      isPickupGoogleBooking: false,
    });
  };
  //2 Steps for location
  getZones = async () => {
    try {
      const requestParams = getRequestParams("GET", "");
      const response = await fetch(
        "serviceAreaZone-api/zone-dropdown/" + 0 + "/" + 0,
        requestParams
      );
      const data = await response.json();
      this.setState({
        dropdownZone: data.locationServiceAreaZoneList,
      });
    } catch (e) {
      console.log(e);
    }
  };
  //3 Steps for location
  handlePickupPlaceSelect() {
    var placeObj = this.handlePlaceSearch(this.autocompletePickup);
    if (placeObj !== null && placeObj !== undefined) {
      this.state.errors["pickupLocation"] = "";
      this.setState({
        pickupLocation: placeObj.location,
        pickupAddress: placeObj.address1,
        pickupAddressType: placeObj.addressType,
        pickupCity: placeObj.cityName,
        pickupState: placeObj.stateName,
        pickupZipCode: placeObj.zipCode,
        pickupLatitude: placeObj.latitude,
        pickupLongitude: placeObj.longitude,
      });
      var error = document.getElementById("lbllocation");
      if (
        document.getElementById("pickupGoogle").value !== "" &&
        Number(this.state.pickupLongitude) !== 0 &&
        Number(this.state.pickupLatitude) !== 0
      ) {
        document.getElementById("lbllocation").style.display = "none";
      }
    } else {
      this.setState({
        pickupLocation: "",
        pickupAddress: "",
        pickupAddressType: "",
        pickupCity: "",
        pickupState: "",
        pickupZipCode: "",
        pickupLatitude: 0,
        pickupLongitude: 0,
      });
      this.state.errors["pickupLocation"] = "";
      var error = document.getElementById("lbllocation");
      if (
        document.getElementById("pickupGoogle").value !== "" &&
        Number(this.state.pickupLongitude) !== 0 &&
        Number(this.state.pickupLatitude) !== 0
      ) {
        document.getElementById("lbllocation").style.display = "none";
        error.textContent = "This address is not verified.";
      }
    }
  }
  //4 Steps for location
  handlePlaceSearch(autocomplete_place) {
    try {
      let place = autocomplete_place.getPlace();
      var placeObject = {};
      if (place.name !== null && place.name !== "") {
        if (
          place !== null &&
          place.formatted_address !== null &&
          place.formatted_address.trim() !== ""
        ) {
          placeObject.location = place.name;
          placeObject.latitude = place.geometry.location.lat();
          placeObject.longitude = place.geometry.location.lng();
          var splitAddress = [];
          place.formatted_address = place.formatted_address.replace(/-/g, ",");
          splitAddress = place.formatted_address.split(",");
          if (splitAddress.length > 0) {
            if (splitAddress.length === 1) {
              placeObject.address1 = splitAddress[0];
              placeObject.cityName = "";
              placeObject.zipCode = "";
              placeObject.stateName = "";
              placeObject.countryName = "";
            } else if (splitAddress.length === 2) {
              placeObject.address1 = splitAddress[0];
              placeObject.cityName = splitAddress[0];
              placeObject.stateName =
                splitAddress[1].trim().search(" ") > 0
                  ? splitAddress[1].trim().split(" ")[0]
                  : splitAddress[1].trim();
              placeObject.zipCode =
                splitAddress[1].trim().search(" ") > 0
                  ? splitAddress[1].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = "";
            } else if (splitAddress.length === 3) {
              placeObject.address1 = +splitAddress[1].trim();
              placeObject.cityName = splitAddress[1].trim();
              placeObject.stateName =
                splitAddress[2].trim().search(" ") > 0
                  ? splitAddress[2].trim().split(" ")[0]
                  : splitAddress[2].trim();
              placeObject.zipCode =
                splitAddress[2].trim().search(" ") > 0
                  ? splitAddress[2].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = "";
            } else if (splitAddress.length === 4) {
              placeObject.address1 = splitAddress[1].trim();
              placeObject.cityName = splitAddress[1].trim();
              placeObject.stateName =
                splitAddress[2].trim().search(" ") > 0
                  ? splitAddress[2].trim().split(" ")[0]
                  : splitAddress[2].trim();
              placeObject.zipCode =
                splitAddress[2].trim().search(" ") > 0
                  ? splitAddress[2].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[3].trim();
            } else if (splitAddress.length === 5) {
              placeObject.address1 =
                splitAddress[1].trim() + ", " + splitAddress[2].trim();
              placeObject.cityName = splitAddress[2].trim();
              placeObject.stateName =
                splitAddress[3].trim().search(" ") > 0
                  ? splitAddress[3].trim().split(" ")[0]
                  : splitAddress[3].trim();
              placeObject.zipCode =
                splitAddress[3].trim().search(" ") > 0
                  ? splitAddress[3].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[4].trim();
            } else if (splitAddress.length === 6) {
              placeObject.address1 =
                splitAddress[1].trim() +
                ", " +
                splitAddress[2].trim() +
                ", " +
                splitAddress[3].trim();
              placeObject.cityName = splitAddress[3].trim();
              placeObject.stateName =
                splitAddress[4].trim().search(" ") > 0
                  ? splitAddress[4].trim().split(" ")[0]
                  : splitAddress[4].trim();
              placeObject.zipCode =
                splitAddress[4].trim().search(" ") > 0
                  ? splitAddress[4].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[5].trim();
            } else if (splitAddress.length === 7) {
              //placeObject.address1 = splitAddress[0] + ' ' + splitAddress[1].trim() + ' ' + splitAddress[2].trim() + ' ' + splitAddress[3].trim() + ' ' + splitAddress[4].trim();
              placeObject.address1 =
                splitAddress[1].trim() +
                ", " +
                splitAddress[2].trim() +
                ", " +
                splitAddress[3].trim() +
                "";
              placeObject.cityName = splitAddress[4].trim();
              placeObject.stateName =
                splitAddress[5].trim().search(" ") > 0
                  ? splitAddress[5].trim().split(" ")[0]
                  : splitAddress[5].trim();
              placeObject.zipCode =
                splitAddress[5].trim().search(" ") > 0
                  ? splitAddress[5].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[6].trim();
            } else if (splitAddress.length === 8) {
              placeObject.address1 =
                splitAddress[1].trim() +
                ", " +
                splitAddress[2].trim() +
                ", " +
                splitAddress[3].trim() +
                ", " +
                splitAddress[4].trim();
              placeObject.cityName = splitAddress[5].trim();
              placeObject.stateName =
                splitAddress[6].trim().search(" ") > 0
                  ? splitAddress[6].trim().split(" ")[0]
                  : splitAddress[6].trim();
              placeObject.zipCode =
                splitAddress[6].trim().search(" ") > 0
                  ? splitAddress[6].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[7].trim();
            } else if (splitAddress.length === 9) {
              placeObject.address1 =
                splitAddress[1].trim() +
                ", " +
                splitAddress[2].trim() +
                ", " +
                splitAddress[3].trim() +
                ", " +
                splitAddress[4].trim() +
                ", " +
                splitAddress[5].trim();
              placeObject.cityName = splitAddress[6].trim();
              placeObject.stateName =
                splitAddress[7].trim().search(" ") > 0
                  ? splitAddress[7].trim().split(" ")[0]
                  : splitAddress[7].trim();
              placeObject.zipCode =
                splitAddress[7].trim().search(" ") > 0
                  ? splitAddress[7].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[8].trim();
            } else {
              placeObject.address1 =
                splitAddress[1].trim() +
                ", " +
                splitAddress[2].trim() +
                ", " +
                splitAddress[3].trim() +
                ", " +
                splitAddress[4].trim() +
                ", " +
                splitAddress[5].trim() +
                ", " +
                splitAddress[6].trim();
              placeObject.cityName = splitAddress[7].trim();
              placeObject.stateName =
                splitAddress[8].trim().search(" ") > 0
                  ? splitAddress[8].trim().split(" ")[0]
                  : splitAddress[8].trim();
              placeObject.zipCode =
                splitAddress[8].trim().search(" ") > 0
                  ? splitAddress[8].trim().split(" ")[1]
                  : " ";
              placeObject.countryName = splitAddress[9].trim();
            }
          }
        } else {
          this.isFormValid();
        }
        return placeObject;
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  modifyBaseLocation = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        loadPanelVisible: true,
      });
      var addresspicker =
        this.state.pickupLocation + ", " + this.state.pickupAddress;
      if (addresspicker === ", ") {
        addresspicker = "";
      }
      // var addresspicker = this.state.pickupLocation + " " + this.state.pickupAddress + " " + this.state.pickupCity + " " + this.state.pickupState + " " + this.state.pickupZipCode;
      var request = {};
      request.AirlineCode = this.state.editAirlineCode;
      request.EmployeeNo = this.state.editEmpNo;
      request.RowIndex = this.state.editRowIndex;
      request.AddressType = this.state.editAddressType;
      request.AddressTypeId = this.state.editAddressTypeId;
      request.Address =
        addresspicker === "" ? this.state.editAddress : addresspicker;
      request.Address1 = this.state.editAddress1;
      request.Address2 = this.state.editAddress2;
      request.City = this.state.editCity;
      request.State = this.state.editState;
      request.Country = this.state.editCountry;
      request.PostalCode = this.state.editPostalCode;
      request.Landmark = this.state.editLandmark;
      request.Latitude =
        this.state.pickupLatitude === ""
          ? this.state.editLatitude
          : this.state.pickupLatitude;
      request.Longitude =
        this.state.pickupLongitude === ""
          ? this.state.editLongitude
          : this.state.pickupLongitude;
      request.IsDefault = this.state.isDefault;
      request.IsDelete = this.state.isDelete;
      request.AddressTitle = this.state.editAddressTitle;
      request.BaseStation = this.state.editBaseLocation;
      const requestParams = getRequestParams("POST", request);
      //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
      fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/UpdateTripAddress",
        requestParams
      )
        .then((response) => {
          if (response.ok) return response.json();
        })
        .then((data) => {
          // this.setState({ viewGetLocationGridDataSource: data.payload, loadPanelVisible: false });
          if (data[0].isSuccess === true) {
            Swal.fire({
              icon: data[0].isSuccess === true ? "success" : "error",
              title: data[0].message,
              // text: data.message,
            }).then((result) => {
              if (data[0].isSuccess === true) {
                if (result.isConfirmed) {
                  this.getBaseLocation();
                  this.handleResetStateDataEdit();
                }
              }
            });
          }
        });
    } catch (ex) {
      throw ex;
    }
  };
  //Clear all data
  handleResetStateDataEdit = async () => {
    this.setState({
      viewCrewMemberisOpen: true,
      editBaseLocationisOpen: false,
      editAirlineCode: "",
      editEmpNo: 0,
      editBaseLocation: "",
      editAddressType: "",
      pickupGoogle: "",
      editCity: "",
      editState: "",
      isSubmited: false,
      loadPanelVisible: false,
    });
  };

  //Delete Button Function
  deleteBaseLocation = async (e, data) => {
    e.preventDefault();
    try {
      Swal.fire({
        html: "Are you sure, you want to delete this record?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          let selectedRowKeys = this.state.dataSourceSelectedRowKeys;
          let employeeNoDel = this.state.viewEmployeeNumber;
          let rowIndexNo = this.state.rowIndexid;
          const requestParams = getRequestParams("GET");
          fetch(
            process.env.REACT_APP_API_BASE_URL +
              "Crew/DeleteCrewAddress/" +
              rowIndexNo +
              "/" +
              employeeNoDel,
            requestParams
          )
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data[0].isSuccess === true) {
                Swal.fire({
                  icon: data[0].isSuccess === true ? "success" : "error",
                  title: "Address deleted successfully.",
                  // text: data.message,
                }).then((result) => {
                  if (data[0].isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getBaseLocation();
                      this.handleResetStateData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  openTracking = (e, data) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/demo",
      state: { sourceUrl: "/demo", airportList: data },
    });
  };

  // isFormValid = () => {
  //     let formIsValid = true;
  //     try {
  //         let errors = this.state.errors;
  //         errors.editBaseLocation = "";
  //         errors.pickupGoogle = "";
  //         errors.pickupLatitude = "";
  //         errors.pickupLongitude = "";

  //         if (this.state.editCity === '') {
  //             formIsValid = false;
  //             errors.editCity = "Please enter city.";
  //         }
  //         if (this.state.editState === '') {
  //             formIsValid = false;
  //             errors.editState = "Please enter state.";
  //         }
  //         this.setState({ errors: errors });
  //     } catch (ex) {
  //         console.log("Error in " + this.displayName + ".isFormValid function", ex);
  //         formIsValid = false;
  //     }
  //     return formIsValid;
  // }

  toolbarItemRender() {
    return (
      <Fragment>
        <div className="filter-header">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div>
                {/* <label>Status</label> */}
                <SelectBox
                  dataSource={this.state.crewStatusList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Status"
                  defaultValue={this.state.status}
                  value={this.state.status}
                  valueExpr="name"
                  displayExpr="name"
                  onSelectionChanged={this.handleCrewStatusChange}
                  showClearButton={true}
                  disabled={false}
                  searchEnabled={true}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const renderGridAddressCell = (data) => {
      let splitaddress = data.data.crewAddress;
      return (
        <div>
          <span
            className="p-0 ellipsis"
            style={{ color: "green" }}
            title={data.data.crewAddress}
          >
            {splitaddress}&nbsp;&nbsp;
          </span>
        </div>
      );
    };

    const renderEmployeeAddressCell = (data) => {
      if (data.data.employeeNumber !== null) {
        return (
          <a
            href="#"
            title="View address(s)"
            onClick={(e) => {
              this.viewCrewMember(e, data.data);
            }}
          >
            {data.data.employeeNumber}
          </a>
        );
      }
    };

    const renderEmployeeAddressCountCell = (data) => {
      if (data.data.employeeNumber !== null) {
        return (
          <a
            href="#"
            title="View address(s)"
            onClick={(e) => {
              this.viewCrewMember(e, data.data);
            }}
          >
            {data.data.addressCount}
          </a>
        );
      }
    };

    const renderGridAddressEditCell = (data) => {
      return (
        <div className="text-center">
          <a
            href="#"
            title="Edit"
            onClick={(e) => {
              this.updateBaseLocation(e, data.data);
            }}
          >
            <i className="far fa-edit"></i>
          </a>
          <a
            href="#"
            title="Delete"
            onClick={(e) => {
              this.deleteBaseLocation(e, data.data);
            }}
          >
            <i className="far fa-trash-alt"></i>
          </a>
        </div>
      );
    };
    return (
      <Fragment>
        {/* <div className="card">
                    <div className="card-body">
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <div className="form-group">
                                <label>Status</label>
                                <SelectBox
                                    dataSource={this.state.crewStatusList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Status"
                                    defaultValue={this.state.status}
                                    value={this.state.status}
                                    valueExpr='name'
                                    displayExpr='name'
                                    onSelectionChanged={this.handleCrewStatusChange}
                                    showClearButton={true}
                                    disabled={false}
                                    searchEnabled={true}
                                    width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.datasource}
              keyExpr="userId"
              id="gridCrewMemberList"
              selection={{ mode: "single" }}
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Column
                dataField="airlineCode"
                caption="Airline"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={80}
              />
              <Column
                dataField="employeeNumber"
                caption="Employee #"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
              />
              <Column
                dataField="addressCount"
                caption="Address#"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={80}
                alignment={"center"}
                cellRender={renderEmployeeAddressCountCell}
              />
              <Column
                dataField="baseStation"
                caption="BaseStation"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
              />
              <Column
                dataField="firstName"
                caption="First Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="lastName"
                caption="Last Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="email"
                caption="Email"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
              />
              <Column
                dataField="contactNumber"
                caption="Contact Number"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={120}
              />
              {/* <Column dataField="baseStation" caption="BaseStation" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} /> */}
              <Column
                dataField="designation"
                caption="Position"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
              />
              {/* <Column dataField="crewAddress" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={350} cellRender={renderGridAddressCell}  /> */}
              <Column
                dataField="registrationDatetime"
                caption="Registration DateTime"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={180}
              />
              <Column
                dataField="createdDatetime"
                caption="Created DateTime"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="lastAppAccessTime"
                caption="Last Access"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              {/* <Column dataField="baseStationCount" caption="BaseStation#" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} /> */}
              {/* <Column dataField="addressGroup" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} /> */}
              <Column
                dataField="appVersion"
                caption="App Version"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
              />
              <Column
                dataField="activeStatus"
                caption="Status"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
              />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Crew Member"
              />
            </DataGrid>
          </div>
        </div>

        {/* View Crew Member Model  */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.viewCrewMemberisOpen}
          style={customStyles}
          className={"react-modal"}
          onAfterOpen={this.afterOpenViewModal}
          onRequestClose={this.ViewCrewMemberResetStateData}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleViewJobSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                Employee Number - {this.state.viewEmployeeNumber}{" "}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleResetStateData}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <DataGrid
                    dataSource={this.state.viewGetLocationGridDataSource}
                    //keyExpr="id"
                    //selection={{ mode: 'single' }}
                    columnsAutoWidth={false}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnResizing={true}
                  >
                    {/* <Column dataField="id" width={80} allowFiltering={false} allowSorting={true} caption="" cellRender={renderGridAddressEditCell} /> */}
                    <Column
                      dataField="airlineCode"
                      width={80}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Airline"
                    />
                    {/* <Column dataField="employeeNo" width={80} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Emp No." /> */}
                    <Column
                      dataField="baseStation"
                      width={80}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Airport"
                    />
                    {/* <Column dataField="addressType" width={120} allowSearch={true} allowFiltering={false} allowSorting={true} caption="Address Type" /> */}
                    <Column
                      dataField="address1"
                      width={170}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="House / Apartment No"
                    />
                    <Column
                      dataField="address2"
                      width={150}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Road / Area"
                    />
                    <Column
                      dataField="landmark"
                      width={150}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Landmark"
                    />
                    <Column
                      dataField="address"
                      width={350}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Address"
                    />
                    <Column
                      dataField="isDefault"
                      width={120}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Default Address"
                    />
                    <Column
                      dataField="city"
                      width={100}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="City"
                    />
                    <Column
                      dataField="state"
                      width={100}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="State"
                    />
                    <Column
                      dataField="createdDate"
                      width={120}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Created Date"
                      dataType="datetime"
                      format="dd/MM/yyyy HH:mm"
                    />
                    <Column
                      dataField="updatedDate"
                      width={120}
                      allowSearch={true}
                      allowFiltering={false}
                      allowSorting={true}
                      caption="Updated Date"
                      dataType="datetime"
                      format="dd/MM/yyyy HH:mm"
                    />
                    <ColumnFixing enabled={true} />
                    <SearchPanel
                      visible={true}
                      highlightCaseSensitive={true}
                      width={240}
                      placeholder="Search..."
                    />
                    <Export enabled={true} fileName="CrewAddress" />
                  </DataGrid>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        {/* Edit in View Crew Member Model  */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.editBaseLocationisOpen}
          style={editCustomStyles}
          className={"react-modal"}
          // onAfterOpen={this.afterOpenviewEditModal}
          onRequestClose={this.editBaseLocationResetStateData}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.modifyBaseLocation}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                Employee Number - {this.state.viewEmployeeNumber}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleResetStateDataEdit}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>Address Type<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="editAddressType" title='Address Type' placeholder="Address Type" maxLength="50" value={this.state.editAddressType} onChange={this.handleInputChange} />
                                        {this.state.errors["editAddressType"].length > 0 && <span className="error invalid-feedback">{this.state.errors["editAddressType"]}</span>}

                                    </div>
                                </div> */}
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>
                      Base Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="editBaseLocation"
                      title="Base Location"
                      placeholder="Base Location"
                      maxLength="150"
                      value={this.state.editBaseLocation}
                      onChange={this.handleInputChange}
                      readOnly={true}
                    />
                    {/* {this.state.errors["editBaseLocation"].length > 0 && <span className="error invalid-feedback">{this.state.errors["editBaseLocation"]}</span>} */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div>
                    <br />
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isDefault"
                      name="isDefault"
                      checked={this.state.isDefault}
                      onChange={this.handleInputChange}
                    />
                    <label className="form-group mt-1" htmlFor="isDefault">
                      &nbsp;&nbsp;Set as default address
                    </label>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>City<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="editCity" placeholder="City" maxLength="10" value={this.state.editCity} onChange={this.handleInputChange} />
                                        {this.state.errors["editCity"].length > 0 && <span className="error invalid-feedback">{this.state.errors["editCity"]}</span>}

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label>State<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-sm" name="editState"  title='State' placeholder="State" maxLength="10" value={this.state.editState} onChange={this.handleInputChange} />
                                        {this.state.errors["editState"].length > 0 && <span className="error invalid-feedback">{this.state.errors["editState"]}</span>}
                                    </div>
                                </div> */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="address-switcher">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={this.handlePickupToggleChange}
                        id="customSwitchPickup"
                      />
                      <span
                        className="slider round"
                        htmlFor="customSwitchPickup"
                      ></span>
                    </label>
                    <label>
                      &nbsp;&nbsp;<b>Do you want to change address?</b>
                    </label>
                  </span>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group">
                    <label>Address</label>
                    <div
                      style={{
                        display: this.state.isPickupGoogleBooking
                          ? "none"
                          : "flex",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder=""
                        name="editAddress"
                        maxLength="150"
                        value={this.state.editAddress}
                        onChange={this.handleInputChange}
                        readOnly={true}
                      />
                    </div>
                    <div
                      style={{
                        display: this.state.isPickupGoogleBooking
                          ? "flex"
                          : "none",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="pickupGoogle"
                        name="pickupGoogle"
                        placeholder="'Type' slowly to search location"
                        maxLength="150"
                        title="Address"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                      />
                      <label
                        id="lbllocation"
                        style={{ display: "none", color: "#ff0000 !important" }}
                      ></label>
                    </div>
                  </div>
                </div>
                {/* <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                                    <div className="form-group custom-control custom-switch swicth-group-checkbox">
                                        <input type="checkbox" className="custom-control-input" onChange={this.handlePickupToggleChange} id="customSwitchPickup" />
                                        <label className="custom-control-label" htmlFor="customSwitchPickup"><b style={{ color: 'red !important' }} >Do you want to change address</b></label>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.modifyBaseLocation}
              >
                {this.state.isAdd && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isAdd && (
                  <span title="Save">
                    <i className="fas fa-check"></i> Save
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray"
                onClick={this.handleResetStateDataEdit}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
