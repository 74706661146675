import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Editing,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  precisionFormats,
  DateFormat,
  BillingStatus,
  symbolFormats,
} from "../../helpers/fixcodes.js";
import DateBox from "devextreme-react/date-box";
import Swal from "sweetalert2";
import { LoadPanel } from "devextreme-react/load-panel";
const position = { of: "#historydiv" };

export class UnApprovedApprovedRidesComponent extends Component {
  static displayName = UnApprovedApprovedRidesComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      dataSourceSelectedRowKeys: [],
      approvedDatasource: null,
      pendingDatasource: null,
      approveDataSourceSelectedRowKeys: [],
      crewStatusList: [],
      status: "Active",
      loadPanelVisible: false,
      fromDate: new Date(),
      toDate: new Date(),
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
      empNoList: [],
      empid: 0,
      empNo: "",
      billingStatusList: [],
      id: 0,
      billingStatusId: 0,
      billingStatusName: "",
      billingcrewid: 0,
      billingSchedulingid: 0,
      billingSchedulingbyycrewid: 0,
      billingId: 0,
      trips: [],
      scheduleid: 0,
      accountdatasource: [],
      airportList: [],
      accountId: 0,
      airportId: 0,
      airportCode: "",
    };
  }
  async componentDidMount() {
    await this.getEmployeeNoList(); //EmployeeNoList
    await this.getBillingStatusList(); //BillingStatusList
    await this.getAirports();
    await this.getServiceProvider();
    await this.getUnApprovedApprovedRides(); //Grid
  }

  //EmployeeNoList
  getEmployeeNoList = async () => {
    try {
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Users/GetAirlineCrewUser",
        requestParams
      );
      const data = await response.json();
      this.setState({
        empNoList: data.payload !== null ? data.payload : [],
      });
    } catch (ex) {
      throw ex;
    }
  };

  //BillingStatusList
  getBillingStatusList = async () => {
    try {
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "rides/GetBillingStatus",
        requestParams
      );
      let data = await response.json();
      if (data.payload !== null) {
        data = JSON.parse(JSON.stringify(data.payload));
        this.setState({
          billingcrewid: data[0].id,
          billingSchedulingid: data[1].id,
          billingSchedulingbyycrewid: data[3].id,
          scheduleid: data[2].id,
        });
        let filterBillingList = data.filter(
          (item) =>
            item.id === this.state.billingcrewid ||
            item.id === this.state.billingSchedulingbyycrewid
        );
        this.setState({
          billingStatusList:
            filterBillingList !== null ? filterBillingList : [],
        });
      } else {
        this.setState({
          billingStatusList: [],
        });
      }
    } catch (ex) {
      console.log("Error in " + this.displayName + ".getAirports function", ex);
    }
  };

  getAirports = async () => {
    this.setState({ loadPanelVisible: true });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportList: data.payload !== null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getServiceProvider = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Account",
        requestParams
      );
      const data = await response.json();
      this.setState({
        accountdatasource: data.payload !== null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  // Filter Employee Number DropDown Handle Event
  handleOnfilterEmpNoChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        empid:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.id
            : 0,
      });
      this.setState({
        empNo:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.name
            : "",
      });
    } else {
      this.setState({ empid: 0, empNo: "" });
    }
  };

  // Filter Billing Status DropDown Handle Event
  handleOnfilterbillingStatusChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        billingStatusId:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.id
            : 0,
      });
      this.setState({
        billingStatusName:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.name
            : "",
      });
    } else {
      this.setState({ billingStatusId: 0, billingStatusName: "" });
    }
  };

  // Filter Pickup Date DropDown Handle Event
  handleOnFilterPickupDateChanged = (date) => {
    this.setState({ filterFromDatetime: date.value });
  };

  handleOnFiltertoDateChanged = (date) => {
    this.setState({ filterToDatetime: date.value });
  };

  handleAirportChange = async (e) => {
    if (e.selectedItem !== null) {
      this.setState({
        airportId: e.selectedItem.airportId,
        airportCode: e.selectedItem.airportCode,
      });
    } else {
      this.setState({
        airportId: 0,
        airportCode: "",
      });
    }
  };

  handleServiceProviderChange = async (e) => {
    this.setState({
      accountId: e.selectedItem !== null ? e.selectedItem.accountId : 0,
    });
  };

  //Grid Data
  getUnApprovedApprovedRides = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let userId = sessionStorage.getItem("session_userId");
      var request = {};
      request.AirlineCode = airlineCode;
      request.EmployeeNumber = this.state.empNo;
      request.StartTime =
        formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 00:00:00";
      request.EndTime =
        formatDate(this.state.filterToDatetime, "MM/dd/yyyy") + " 23:59:59";
      //request.StartTime = formatDate('10/1/2022', 'MM/dd/yyyy') + " 00:00:00";
      //request.EndTime = formatDate('10/30/2022', 'MM/dd/yyyy') + " 23:59:59";
      request.BillingStatusId = this.state.billingStatusId;
      request.LoginUserId = parseInt(userId);
      request.AccountId = parseInt(this.state.accountId);
      request.AirportCode = this.state.airportCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Rides/UnApprovedApproveRides",
        requestParams
      );
      let data = await response.json();
      if (data.payload !== null) {
        data = JSON.parse(JSON.stringify(data.payload));
        let pendinglist = data.filter(
          (item) => item.billingStatusId === null || item.billingStatusId === 0
        );
        let schedultlist = data.filter(
          (item) => item.billingStatusId === this.state.billingSchedulingid
        );
        let billinglist = data.filter(
          (item) =>
            item.billingStatusId === parseInt(BillingStatus.UNAPPROVEDBYCREW)
        );
        this.setState({
          pendingDatasource: pendinglist,
          datasource: billinglist,
          approvedDatasource: schedultlist,
          loadPanelVisible: false,
        });
      } else {
        this.setState({
          pendingDatasource: [],
          datasource: [],
          approvedDatasource: [],
          loadPanelVisible: false,
        });
      }
    } catch (ex) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        ex
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  //First Grid of CheckBox Handle Event
  handleMultipleDataSourceSelectionChanged = (e) => {
    this.setState({
      dataSourceSelectedRowKeys: e.selectedRowKeys,
    });
  };

  //Approve by Schedule Button Function
  handleApprovebySchedule = async (e, data) => {
    e.preventDefault();
    try {
      if (
        this.state.dataSourceSelectedRowKeys !== null &&
        this.state.dataSourceSelectedRowKeys.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Please select at least one row.",
        }).then((result) => {
          return;
        });
        return;
      }
      let selectedRowKeys = this.state.dataSourceSelectedRowKeys;
      let selectedRows = this.state.datasource.filter(
        (item) =>
          selectedRowKeys.filter((tripId) => tripId === item.tripId).length > 0
      );
      let filterRows = [];
      var i;
      var j = 0;

      if (selectedRows[0].billingStatusId === this.state.billingId) {
        if (selectedRows.length >= 1) {
          Swal.fire({
            icon: "error",
            text: "Ride[s] are pending from crew approval",
          }).then((result) => {});
          return;
        }
      } else {
        for (i = 0; i < selectedRows.length; i++) {
          if (selectedRows[i].billingStatusId === this.state.billingcrewid) {
            filterRows[j] = selectedRows[i].tripId;
            j++;
            this.setState({ trips: filterRows });
          }
        }
      }
      Swal.fire({
        html: "Are you sure, you want to approve selected ride[s]?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          var newObject = {};
          newObject.BillingStatusId = this.state.scheduleid;
          newObject.UserId = sessionStorage.getItem("session_userId");
          newObject.RideId =
            filterRows.length === 0
              ? this.state.dataSourceSelectedRowKeys
              : filterRows;
          newObject.IsRideApproved = true;
          const requestParams = getRequestParams("POST", newObject);
          fetch(
            process.env.REACT_APP_API_BASE_URL + "Rides/ApproveRide",
            requestParams
          )
            //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: data.isSuccess === true ? "success" : "error",
                  text: "Selected ride[s] approved successfully.",
                  // text: data.message,
                }).then((result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getUnApprovedApprovedRides();
                      this.clearData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  //Approve by Crew Button Function
  handleApprovebyCrew = async (e, data) => {
    e.preventDefault();

    try {
      if (
        this.state.dataSourceSelectedRowKeys !== null &&
        this.state.dataSourceSelectedRowKeys.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Please select at least one row.",
        }).then((result) => {
          this.setState({ isSwapTrip: false });
        });
        return;
      }
      let selectedRowKeys = this.state.dataSourceSelectedRowKeys;
      let selectedRows = this.state.datasource.filter(
        (item) =>
          selectedRowKeys.filter((tripId) => tripId === item.tripId).length > 0
      );
      let filterRows = [];
      var i;
      var j = 0;
      if (selectedRows.length === 1) {
        if (selectedRows[0].billingStatusId !== this.state.billingId) {
          Swal.fire({
            icon: "error",
            text: "Selected ride[s] are already approved.",
          }).then((result) => {});
          return;
        }
      } else {
        for (i = 0; i < selectedRows.length; i++) {
          if (selectedRows[i].billingStatusId === this.state.billingId) {
            filterRows[j] = selectedRows[i].tripId;
            j++;
          }
        }
      }

      this.setState({ trips: filterRows });

      if (selectedRows.length === 1) {
        this.setState({ trips: this.state.dataSourceSelectedRowKeys });
      } else {
        if (filterRows.length === 0) {
          Swal.fire({
            icon: "error",
            text: "Selected ride[s] are already approved.",
          }).then((result) => {});
          return;
        }
      }

      Swal.fire({
        html: "Are you sure, you want to approve selected ride[s] on be half of crew?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });

          var newObject = {};
          newObject.BillingStatusId = this.state.billingSchedulingbyycrewid;
          newObject.UserId = sessionStorage.getItem("session_userId");
          newObject.RideId = this.state.trips;
          newObject.IsRideApproved = false;
          const requestParams = getRequestParams("POST", newObject);
          fetch(
            process.env.REACT_APP_API_BASE_URL + "Rides/ApproveRide",
            requestParams
          )
            //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: data.isSuccess === true ? "success" : "error",
                  text: "Selected ride[s] approved successfully.",
                }).then((result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getUnApprovedApprovedRides();
                      this.clearData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  //Second Grid of CheckBox Handle Event
  handleMultipleApproveDataSourceSelectionChanged = (e) => {
    this.setState({
      approveDataSourceSelectedRowKeys: e.selectedRowKeys,
    });
  };

  //Unapprove Schedule button
  handleUnapprovebySchedule = async (e, data) => {
    e.preventDefault();
    try {
      if (
        this.state.approveDataSourceSelectedRowKeys !== null &&
        this.state.approveDataSourceSelectedRowKeys.length === 0
      ) {
        Swal.fire({
          icon: "error",
          text: "Please select at least one row.",
        }).then((result) => {
          this.setState({ isSwapTrip: false });
        });
        return;
      }
      Swal.fire({
        html: "Are you sure, you want to disapprove selected ride[s]?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          let selectedRowKeys = this.state.approveDataSourceSelectedRowKeys;
          var newObject = {};
          newObject.BillingStatusId = this.state.billingSchedulingbyycrewid;
          newObject.UserId = sessionStorage.getItem("session_userId");
          newObject.RideId = selectedRowKeys;
          newObject.IsRideApproved = false;
          const requestParams = getRequestParams("POST", newObject);
          fetch(
            process.env.REACT_APP_API_BASE_URL + "Rides/ApproveRide",
            requestParams
          )
            //fetch(process.env.REACT_APP_API_BASE_URL + '', requestParams)
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: data.isSuccess === true ? "success" : "error",
                  text: "Selected ride[s] disapproved successfully.",
                  // text: data.message,
                }).then((result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      this.getUnApprovedApprovedRides();
                      this.clearData();
                    }
                  }
                });
              }
            });
        }
      });
    } catch (ex) {
      throw ex;
    }
  };

  //Clear Button
  clearData = async (e) => {
    this.setState({
      loadPanelVisible: false,
      fromDate: new Date(),
      toDate: new Date(),
      //filterFromDatetime: new Date(),
      //filterToDatetime: new Date(),
      empid: 0,
      empNo: "",
      id: 0,
      billingStatusId: 0,
      billingStatusName: "",
      billingcrewid: 0,
      billingSchedulingid: 0,
      dataSourceSelectedRowKeys: [],
      approveDataSourceSelectedRowKeys: [],
    });
    window.location.reload(false);
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getUnApprovedApprovedRides.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };

  onToolbarPreparingApproved = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getUnApprovedApprovedRides.bind(this),
      },
    });
    // e.toolbarOptions.items.unshift({
    //     location: 'before',
    //     template: 'toolbarTemplate'
    // });
  };

  toolbarItemRender = () => {
    return (
      <Fragment>
        <div className="filter-header">
          <div className="row">
            <div className="col-12 pl-0">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleApprovebySchedule}
              >
                <span title="Approve by schedule">
                  <i className="fas fa-check"></i> Approve by schedule
                </span>
              </button>
              &nbsp;&nbsp;
              {/* <button type="submit" className="btn btn-success" onClick={this.handleApprovebyCrew} >
                                        <span title="Approve by crew"><i className="fas fa-check"></i> Approve by crew</span>
                                    </button> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  // toolbarItemRenderUnapproved = () => {
  //     return (
  //         <Fragment>
  //             <div className='filter-header'>
  //                 <div className="row">
  //                     <div className="col-12 pl-0">
  //                         <button type="submit" className="btn btn-success" onClick={this.handleUnapprovebySchedule} >
  //                             <span title="Unapprove"><i className="fas fa-times"></i> Unapprove</span>
  //                         </button>
  //                     </div>
  //                 </div>
  //             </div>
  //         </Fragment>
  //     );
  // }
  render() {
    const renderGridNumberCell = (data) => {
      return (
        <div>
          <small>
            <i className={symbolFormats.ruppesFormat}></i>
          </small>
          &nbsp;&nbsp;
          {parseFloat(data.value !== undefined ? data.value : 0.0).toFixed(
            precisionFormats[2]
          )}
        </div>
      );
    };

    return (
      <Fragment>
        <div className="filter-box">
          <div className="card p-2 m-0">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Employee Number</label>
                  <SelectBox
                    dataSource={this.state.empNoList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="id"
                    defaultValue={0}
                    value={this.state.empid}
                    displayExpr="name"
                    valueExpr="id"
                    onSelectionChanged={this.handleOnfilterEmpNoChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 displayNone">
                <div className="form-group">
                  <label>Billing Status</label>
                  <SelectBox
                    dataSource={this.state.billingStatusList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Billing Status"
                    name="id"
                    defaultValue={0}
                    value={this.state.billingStatusId}
                    displayExpr="name"
                    valueExpr="id"
                    onSelectionChanged={this.handleOnfilterbillingStatusChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterFromDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterFromDatetime}
                    value={this.state.filterFromDatetime}
                    onValueChanged={this.handleOnFilterPickupDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterToDatetime}
                    value={this.state.filterToDatetime}
                    onValueChanged={this.handleOnFiltertoDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Airport(s)</label>
                  <SelectBox
                    dataSource={this.state.airportList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="airportCode"
                    defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    //displayExpr={this.displayExpr}
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Service Provider(s)</label>
                  <SelectBox
                    dataSource={this.state.accountdatasource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Service Providers"
                    name="accountId"
                    defaultValue={0}
                    value={this.state.accountId}
                    displayExpr="accountName"
                    valueExpr="accountId"
                    onSelectionChanged={this.handleServiceProviderChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.getUnApprovedApprovedRides}
                  >
                    <span title="Filter">
                      <i className="fas fa-filter"></i> Filter
                    </span>
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="submit"
                    className="btn btn-custom-gray "
                    onClick={this.clearData}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 border-bottom-0">
          <ul
            className="nav nav-tabs mt-1 ml-2"
            id="custom-tabs-tab"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                id="custom-tabs-pending-tab"
                data-toggle="pill"
                href="#custom-tabs-pending"
                role="tab"
                aria-controls="custom-tabs-pending"
                aria-selected="true"
                onClick={(e) => this.setState({ tabChangeEvent: true })}
              >
                Pending
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-home-tab"
                data-toggle="pill"
                href="#custom-tabs-home"
                role="tab"
                aria-controls="custom-tabs-home"
                aria-selected="false"
                onClick={(e) => this.setState({ tabChangeEvent: false })}
              >
                Unapproved
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-profile-tab"
                data-toggle="pill"
                href="#custom-tabs-profile"
                role="tab"
                aria-controls="custom-tabs-profile"
                aria-selected="false"
                onClick={(e) => this.setState({ tabChangeEvent: false })}
              >
                Approved
              </a>
            </li>
          </ul>
        </div>
        <div className="card">
          <div className="card-body">
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-pending"
                role="tabpanel"
                aria-labelledby="custom-tabs-pending-tab"
              >
                <DataGrid
                  dataSource={this.state.pendingDatasource}
                  keyExpr="tripId"
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  //selectedRowKeys={this.state.approveDataSourceSelectedRowKeys}
                  onSelectionChanged={
                    this.handleMultipleApproveDataSourceSelectionChanged
                  }
                  onToolbarPreparing={this.onToolbarPreparingApproved}
                >
                  {/* <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    /> */}
                  <Editing mode="cell" allowUpdating={false} />
                  <Column
                    dataField="pickupTime"
                    caption="Date"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    format={DateFormat.DateTime24NorthFormat}
                    dataType="datetime"
                  />
                  <Column
                    dataField=""
                    caption="Department"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="EMP ID"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="crewName"
                    caption="Staff Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Designation"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="mobileNo"
                    caption="Mobile Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flt Details"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="tripTypeName"
                    caption="Pickup/Drop"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="pickupLocation"
                    caption="Pick up Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="dropoffLocation"
                    caption="Drop Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="reportingTime"
                    caption="Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="vehicleNumber"
                    caption="Cab No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="totalKms"
                    caption="One Way KM"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="totalFare"
                    caption="Rate"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                    cellRender={renderGridNumberCell}
                  />
                  <Column
                    dataField=""
                    caption="Cost Center"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />

                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRenderUnapproved} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Pending Rides"
                  />
                </DataGrid>
              </div>
              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-home"
                role="tabpanel"
                aria-labelledby="custom-tabs-home-tab"
              >
                <DataGrid
                  dataSource={this.state.datasource}
                  keyExpr="tripId"
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  selectedRowKeys={this.state.dataSourceSelectedRowKeys}
                  onSelectionChanged={
                    this.handleMultipleDataSourceSelectionChanged
                  }
                  onToolbarPreparing={this.onToolbarPreparing}
                >
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                  />
                  <Editing mode="cell" allowUpdating={false} />
                  <Column
                    dataField="pickupTime"
                    caption="Date"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    format={DateFormat.DateTime24NorthFormat}
                    dataType="datetime"
                  />
                  <Column
                    dataField=""
                    caption="Department"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="EMP ID"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="crewName"
                    caption="Staff Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Designation"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="mobileNo"
                    caption="Mobile Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flt Details"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="tripTypeName"
                    caption="Pickup/Drop"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="pickupLocation"
                    caption="Pick up Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="dropoffLocation"
                    caption="Drop Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="reportingTime"
                    caption="Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="vehicleNumber"
                    caption="Cab No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="totalKms"
                    caption="One Way KM"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="totalFare"
                    caption="Rate"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                    cellRender={renderGridNumberCell}
                  />
                  <Column
                    dataField=""
                    caption="Cost Center"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />

                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <Template
                    name="toolbarTemplate"
                    render={this.toolbarItemRender}
                  />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="UnApproved Rides"
                  />
                </DataGrid>
              </div>
              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-profile"
                role="tabpanel"
                aria-labelledby="custom-tabs-profile-tab"
              >
                <DataGrid
                  dataSource={this.state.approvedDatasource}
                  keyExpr="tripId"
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  selectedRowKeys={this.state.approveDataSourceSelectedRowKeys}
                  onSelectionChanged={
                    this.handleMultipleApproveDataSourceSelectionChanged
                  }
                  onToolbarPreparing={this.onToolbarPreparingApproved}
                >
                  {/* <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                    /> */}
                  <Editing mode="cell" allowUpdating={false} />
                  <Column
                    dataField="pickupTime"
                    caption="Date"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    format={DateFormat.DateTime24NorthFormat}
                    dataType="datetime"
                  />
                  <Column
                    dataField=""
                    caption="Department"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="employeeNumber"
                    caption="EMP ID"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="crewName"
                    caption="Staff Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Designation"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="mobileNo"
                    caption="Mobile Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flt Details"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="tripTypeName"
                    caption="Pickup/Drop"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="pickupLocation"
                    caption="Pick up Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="dropoffLocation"
                    caption="Drop Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="pickupTime"
                    caption="Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={170}
                  />
                  <Column
                    dataField="vehicleNumber"
                    caption="Cab No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="totalKms"
                    caption="One Way KM"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="totalFare"
                    caption="Rate"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                    cellRender={renderGridNumberCell}
                  />
                  <Column
                    dataField=""
                    caption="Cost Center"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={90}
                  />

                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRenderUnapproved} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Approved Rides"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
