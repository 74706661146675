import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import moment from "moment";
import DateBox from "devextreme-react/date-box";
import { getRequestParams, formatDate,formatFromDate, formatToDate } from "../../helpers/globalfunctions.js";
import { DateFormat, StatusType, TripStatus } from '../../helpers/fixcodes';
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import { LoadPanel } from "devextreme-react/load-panel";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
const status = [
    { id: 0, name: "All" },
    { id: 1, name: "UpComing" },
    { id: 2, name: "Current" },
    { id: 3, name: "Completed" },
    { id: 4, name: "Cancelled" },
  ];
const position = { of: "#historydiv" };
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

export class DispatchComponent extends Component {
    static displayName = DispatchComponent.name;
    currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));

    constructor(props) {
      super(props);
      this.state = {
        isSubmited: false,
        datasource: null,
        dataUpComing: null,
        dataCurrent: null,
        dataCompleted: null,
        dataCancel: null,
        dropdownStatus: [],
        AccountList: [],
        rideDetailsList: [],
        singleData: null,
        loadPanelVisible: false,
        rideDetailsModal: false,
        statusTypeId: 0,
        filterFromDatetime: new Date(),
        filterToDatetime: moment(new Date()).format("MM/DD/YYYY") + " 23:59",        
        accountCode: "",
        airportList: [],
        airportId: 0,
        airportCode: "",
        rideId: 0,
        statusList: [],
        isOpenTrackModal: false,
        reservationGridDataSource:[],
        isShowCompleted: false,
        isShowCancelled: false,
        isShowPending: false,
        isShowInProcess: false,
        isInHouse: false,
        isFarmIn: false,
        isFarmOut: false,
        isSearch: false,
        rideDetails: {
          name: "",
          phnNo: "",
          cellNo: "",
          email: "",
          noOfPilot: 0,
          noOfFa: 0,
          noOfCrew: 0,
          vehicleType: "",
          vehicleCapacity: "",
          puAddress: "",
          doAddress: "",
          schPickUp: "",
          actualPickUp: "",
          actualDropOff: "",
          flightDatetime: "",
          airline: "",
          flightNo: "",
          flightType: "",
        },
        errors: {
          accountCode: "",
          statusTypeId: 0,
        },
      };
    }
    onToolbarPreparing = (e) => {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: this.getReservations.bind(this),
        },
      });
    };
    async componentDidMount() {
        await this.getStatusList();
        await this.getAirports();
        await this.getPreApprovedRideList();
        //await this.getAccountList();
    }
    async getStatusList() {
        try {
            const data = JSON.parse(sessionStorage.getItem("session_tripstatus"));
            if (data === null) {
                const requestParams = getRequestParams("GET", {});
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Rides/StatusType/' + StatusType.Booking, requestParams);
                const data = await response.json();
                this.setState({
                    statusList: data.payload != null ? data.payload : [],
                });
            }
            else {
                this.setState({
                    statusList: data.statusType
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
    getAirports = async () => {
        this.setState({ loadPanelVisible: true });
        try {
          let airlineCode = sessionStorage.getItem("session_airlineCode");
          let UserId = parseInt(sessionStorage.getItem("session_userId"));
          let UserName = sessionStorage.getItem("session_userName");
          let SourceId = 0;
          var request = {};
          request.AirlineCode = airlineCode;
          request.UserId = UserId;
          request.UserName = UserName;
          request.SourceId = SourceId;
          const requestParams = getRequestParams("POST", request);
          const response = await fetch(
            process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
            requestParams
          );
          const data = await response.json();
          this.setState({
            airportList: data.payload != null ? data.payload : [],
            loadPanelVisible: false,
          });
        } catch (err) {
          console.log(
            "Error in " + this.displayName + ".getAirports function",
            err
          );
          this.setState({
            loadPanelVisible: false,
          });
        }
      };
      getStatusDropdownList = async () => {
        try {
          this.setState({ dropdownStatus: status });
        } catch (ex) {
          console.log(
            "Error in " + this.displayName + ".getStatusDropdownList function",
            ex
          );
        }
      };
    getPreApprovedRideList = async () => {
        let tripStatus = "";
        let airlineCode = sessionStorage.getItem("session_airlineCode");
        let UserId = parseInt(sessionStorage.getItem("session_userId"));
        try {
            for (let vt = 0; vt < this.state.statusList.length; vt++) {
                if (
                    parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                    && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                ) {
                    tripStatus += this.state.statusList[vt].id + ",";
                    tripStatus.substring(0, tripStatus.length - 1);
                }
            }
            if (this.state.isShowCompleted === true) {
                tripStatus += parseInt(TripStatus.COMPLETED).toString() + ", " + parseInt(TripStatus.NOSHOW).toString() + ", ";
            }
            if (this.state.isShowCancelled === true) {
                tripStatus += parseInt(TripStatus.CANCELLED) + ", " + parseInt(TripStatus.AUTOCANCELLED) + ", ";
            }
            tripStatus.substring(0, tripStatus.length - 1);

            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.UserId = parseInt(UserId);
            dispatchReservationRequest.StatusId = parseInt(0);
            dispatchReservationRequest.FromDate = formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 00:00"; //formatFromDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.ToDate = formatDate(this.state.filterToDatetime, "MM/dd/yyyy") + " 23:59"; //formatFromDate(this.state.searchToDate, "MM/dd/yyyy");
            dispatchReservationRequest.AirlineCode = airlineCode;
            dispatchReservationRequest.AirportCode = this.state.airportCode;
            dispatchReservationRequest.VehicleTypeId = "";
            dispatchReservationRequest.VehicleId = "";         
            dispatchReservationRequest.VehicleTypeGroupId = "";
            dispatchReservationRequest.StatusTypeId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);
            
            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            await this.bindGrid(requestParams);
            //await this.bindGPSCalendar(requestParams);
        } catch (e) {
            ////debugger;
            console.log(e);
        }
    }
    async bindGrid(requestParams) {
        this.setState({
            loadPanelVisible: true,
            isSubmited: true
        });

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Rides/GetDispatchRides', requestParams);
            const data = await response.json();
            
            this.setState({
                reservationGridDataSource: data.payload != null ? data.payload : [],
            });
        } catch (e) {
        }

        this.setState({
            loadPanelVisible: false,
            isSubmited: false
        });
    }    
    handleOnFilteFromDateChanged = (date) => {
      this.setState({ filterFromDatetime: date.value });
    };
    handleOnFilteToDateChanged = (date) => {
        this.setState({ filterToDatetime: date.value });
    };

    getReservations = async (e) => {
        try {
            this.setState({ isSearch: true });
            setTimeout(() => this.handleSearch(), 500);
        } catch (e) {

        }
    }
    handleSearch = async () => {
        let airlineCode = sessionStorage.getItem("session_airlineCode");
        let UserId = parseInt(sessionStorage.getItem("session_userId"));
        let UserName = sessionStorage.getItem("session_userName");
        
        try {
            let tripVehicleGroupType = "";
            let tripVehicleType = "";
            let tripStatus = "";
            let tripVehicle = "";

            // for (let vt = 0; vt < this.state.filterVehicleGroupTypeList.length; vt++) {
            //     tripVehicleGroupType += this.state.filterVehicleGroupTypeList[vt] + ",";
            //     tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
            // }

            // for (let vt = 0; vt < this.state.filterVehicleTypeList.length; vt++) {
            //     tripVehicleType += this.state.filterVehicleTypeList[vt] + ",";
            //     tripVehicleType.substring(0, tripVehicleType.length - 1);
            // }

            debugger;
            if (this.state.isShowPending == true) {
                tripStatus += parseInt(TripStatus.PENDING) + ", " + parseInt(TripStatus.VEHICLEUNASSIGNED) + ", " + parseInt(TripStatus.DRIVERREJECTED) + ", ";
            }
            if (this.state.isShowInProcess == true) {
                tripStatus += parseInt(TripStatus.VEHICLEASSIGNED) + ", " + parseInt(TripStatus.DRIVERACCEPTED) + ", ";
                tripStatus += parseInt(TripStatus.ONLOCATION) + ", " + parseInt(TripStatus.PAXLOAD) + ", ";
            }
            if (this.state.isShowCompleted == true) {
                tripStatus += parseInt(TripStatus.COMPLETED) + ", " + parseInt(TripStatus.NOSHOW) + ", ";
            }
            if (this.state.isShowCancelled == true) {
                tripStatus += parseInt(TripStatus.CANCELLED) + ", ";
            }
            if (this.state.isShowAll == true) {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (tripStatus != parseInt(TripStatus.AUTOCANCELLED)) {
                        tripStatus += this.state.statusList[vt].id + ",";
                    }
                }
            }
            tripStatus.substring(0, tripStatus.length - 1);
            
            if (this.state.isSearch === false) 
            {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (
                        parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                    ) {
                        tripStatus += this.state.statusList[vt].id + ",";
                        tripStatus.substring(0, tripStatus.length - 1);
                    }
                }
            }

            if (tripStatus === "") {
                for (let vt = 0; vt < this.state.statusList.length; vt++) {
                    if (
                        parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.COMPLETED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.CANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.AUTOCANCELLED)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOWNONBILLABLE)
                        && parseInt(this.state.statusList[vt].id) != parseInt(TripStatus.NOSHOW)
                    ) {
                        tripStatus += this.state.statusList[vt].id + ",";
                        tripStatus.substring(0, tripStatus.length - 1);
                    }
                }
            }

            // for (let vt = 0; vt < this.state.filterVehicleList.length; vt++) {
            //     tripVehicle += this.state.filterVehicleList[vt] + ",";
            //     tripVehicle.substring(0, tripVehicle.length - 1);
            // }

            var dispatchReservationRequest = new Object();
            dispatchReservationRequest.UserId = parseInt(UserId);
            dispatchReservationRequest.StatusId = parseInt(0);
            dispatchReservationRequest.AirlineCode = airlineCode;
            dispatchReservationRequest.AirportCode = this.state.airportCode;
            dispatchReservationRequest.RideId = this.state.rideId == 0 ? 0 : parseInt(this.state.rideId);
            dispatchReservationRequest.FromDate = formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 00:00"; //formatFromDate(this.state.searchFromDate, "MM/dd/yyyy");
            dispatchReservationRequest.ToDate = formatDate(this.state.filterToDatetime, "MM/dd/yyyy") + " 23:59"; //formatFromDate(this.state.searchToDate, "MM/dd/yyyy");
            dispatchReservationRequest.VehicleTypeGroupId = tripVehicleGroupType; //tripVehicleGroupType == "" ? tripVehicleGroupType : tripVehicleGroupType.substring(0, tripVehicleGroupType.length - 1);
            dispatchReservationRequest.VehicleTypeId = tripVehicleType; //tripVehicleType == "" ? tripVehicleType : tripVehicleType.substring(0, tripVehicleType.length - 1);
            dispatchReservationRequest.VehicleId = tripVehicle; //tripVehicle == "" ? tripVehicle : tripVehicle.substring(0, tripVehicle.length - 1);;
            dispatchReservationRequest.StatusTypeId = tripStatus == "" ? tripStatus : tripStatus.substring(0, tripStatus.length - 1);;
            
            const requestParams = getRequestParams('POST', dispatchReservationRequest);
            await this.bindGrid(requestParams);

            this.setState({
                searchFromDate: dispatchReservationRequest.FromDate,
                searchToDate: dispatchReservationRequest.ToDate,                
                dateRange: "Date Range: " + dispatchReservationRequest.FromDate + " to " + dispatchReservationRequest.ToDate,
            });
            //await this.bindGPSCalendar(requestParams);        
      } catch (error) {
        console.log(
          "Error in " + this.displayName + ".getReservations function",
          error
        );
        this.setState({
          loadPanelVisible: false,
        });
      }
    };
    getAccountList = async () => {
      try {
        let userId = sessionStorage.getItem("session_userId");
        let userName = sessionStorage.getItem("session_userName");
        const requestParams = getRequestParams("GET");
        this.setState({
          loadPanelVisible: true,
        });
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "Account/" +
            userId +
            "/" +
            userName,
          requestParams
        );
        const data = await response.json();
        this.setState({
          AccountList: data.payload,
          loadPanelVisible: false,
        });
      } catch (error) {
        console.log(
          "Error in " + this.displayName + ".getAccountList function",
          error
        );
      }
    };
    handleOnFilterAccountChange = (e) => {
      this.setState({
        accountCode: e.selectedItem !== null ? e.selectedItem.accountCode : "",
      });
      let errors = this.state.errors;
      errors.accountCode = "";
      this.setState({ errors: errors });
    };
    trackVehicle = (e, data) => {
      e.preventDefault();
      try {
        this.refs.locateVehicleModal.showModal(data.vehicleNo, data.rideId);
      } catch (error) {
        console.log(
          "Error in " + this.displayName + ".locateVehicle function",
          error
        );
      }
    };
    replayVehicle = (e, data) => {
      e.preventDefault();
      try {
        this.refs.replayVehicleModel.showModal(
          data.rideId,
          data.fmVehicleId,
          data.onLocationTime,
          data.onCompletedTime
        );
      } catch (error) {
        console.log(
          "Error in " + this.displayName + ".replayVehicle function",
          error
        );
      }
    };
    handleAssignDataToState = async () => {
      if (this.state.rideDetailsList === null) {
        this.setState({
          rideDetails: {
            name: "",
            phnNo: "",
            cellNo: "",
            email: "",
            noOfPilot: 0,
            noOfFa: 0,
            noOfCrew: 0,
            vehicleType: "",
            vehicleCapacity: "",
            puAddress: "",
            doAddress: "",
            schPickUp: "",
            actualPickUp: "",
            actualDropOff: "",
            flightDatetime: "",
            airline: "",
            flightNo: "",
            flightType: "",
          },
        });
      } else {
        const ride = this.state.singleData;
        let rideDetails = this.state.rideDetails;
        rideDetails.name = ride.firstName + " " + ride.lastName;
        rideDetails.phnNo = ride.phoneNo;
        rideDetails.email = ride.email;
        // rideDetails.noOfPilot = ride.;
        // rideDetails.noOfFa = ride[0].;
        rideDetails.noOfCrew = ride.adultNo;
        rideDetails.vehicleType = ride.vehicleType;
        rideDetails.vehicleCapacity = ride.capacity;
        rideDetails.puAddress = ride.puStreet;
        rideDetails.doAddress = ride.doStreet;
        rideDetails.schPickUp = ride.pickUpDateTime;
        rideDetails.actualPickUp = ride.timeLoad == null ? "" : ride.timeLoad;
        rideDetails.actualDropOff = ride.timeUpload;
        rideDetails.flightDatetime = ride.flightDateTime;
        rideDetails.airline = ride.airlineName;
        rideDetails.flightTerminal = ride.flightTerminal;
        rideDetails.flightNo = ride.flightNo;
        rideDetails.flightType = ride.flightType;
        this.setState({
          rideDetails: rideDetails,
        });
      }
    };
    handleResetStateData = () => {
      this.setState({
        isSubmited: false,
        loadPanelVisible: false,
        rideDetailsModal: false,
        statusTypeId: 0,
        filterFromDatetime: new Date(),
        rideDetails: {
          name: "",
          phnNo: "",
          cellNo: "",
          email: "",
          noOfPilot: 0,
          noOfFa: 0,
          noOfCrew: 0,
          vehicleType: "",
          vehicleCapacity: "",
          puAddress: "",
          doAddress: "",
          schPickUp: "",
          actualPickUp: "",
          actualDropOff: "",
          flightDatetime: "",
          airline: "",
          flightNo: "",
          flightType: "",
        },
        errors: {
          accountCode: "",
          statusTypeId: 0,
        },
      });
    };
    handleRowPrepared = (e) => {
      if (e.rowType === "data") {
        if (e.data.rideStatus === "Pending") {
          e.rowElement.style.backgroundColor = "#f2f2f2";
        } else if (e.data.rideStatus === "CANCELLED") {
          e.rowElement.style.backgroundColor = "#f2f2f2";
          e.rowElement.style.foregroundColor = "#ffffff";
        } else if (e.data.rideStatus === "Driver En Route") {
          e.rowElement.style.backgroundColor = "#FFFB77";
        } else if (e.data.rideStatus === "Vehicle unassigned") {
          e.rowElement.style.backgroundColor = "#f2f2f2";
        } else if (e.data.rideStatus === "Driver Rejected Job") {
          e.rowElement.style.backgroundColor = "#f2f2f2";
        } else if (e.data.rideStatus === "On Location") {
          e.rowElement.style.backgroundColor = "#FFFF01";
        } else if (e.data.rideStatus === "Boarded") {
          e.rowElement.style.backgroundColor = "#D4FFD4";
        } else if (e.data.rideStatus === "NO SHOW") {
          e.rowElement.style.backgroundColor = "#eabdf5";
          e.rowElement.style.foregroundColor = "#ffffff";
        } else if (e.data.rideStatus === "NO SHOW - NON BILLABLE") {
          e.rowElement.style.backgroundColor = "#eabdf5";
          e.rowElement.style.foregroundColor = "#ffffff";
        } else if (e.data.rideStatus === "Ride Complete") {
          e.rowElement.style.backgroundColor = "#01B050";
          e.rowElement.style.foregroundColor = "#ffffff";
        }
      }
    };
    handleAirportChange = async (e) => {
      this.setState({
        airportId: e.selectedItem != null ? e.selectedItem.airportId : 0,
        airportCode: e.selectedItem != null ? e.selectedItem.airportCode : "",
      });
    };
    displayExpr = (item) => {
      if (item != null) {
        return item.airportCode;
      } else {
        return "Select Airport";
      }
    };
    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    render() {
      const renderLocateVehicle = (data) => {
        return (
          <div>
            <center>
              <a
                href="#"
                style={{ color: "blue" }}
                title="Locate Vehicle"
                onClick={(e) => {
                  this.trackVehicle(e, data.data);
                }}
              >
                <i className="fas fa-car"></i>
              </a>
            </center>
          </div>
        );
      };
      const renderReplayVehicle = (data) => {
        return (
          <div>
            <center>
              <a
                href="#"
                style={{ color: "blue" }}
                title="Replay Vehicle"
                onClick={(e) => {
                  this.replayVehicle(e, data.data);
                }}
              >
                <i className="far fa-play-circle"></i>
              </a>
            </center>
          </div>
        );
      };
      return (
        <Fragment>
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"> */}
                  <div className="form-group">
                    <label>From Date</label>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="filterFromDatetime"
                      placeholder="MM/dd/yyyy"
                      displayFormat="MM/dd/yyyy"
                      showAnalogClock={true}
                      useMaskBehavior={true}
                      defaultValue={this.state.filterFromDatetime}
                      value={this.state.filterFromDatetime}
                      onValueChanged={this.handleOnFilteFromDateChanged}
                      width="100%"
                      disabled={false}
                      acceptCustomValue={false}
                    />
                  </div>&nbsp;
                {/* </div> */}
                {/* <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"> */}
                  <div className="form-group">
                    <label>To Date</label>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="filterToDatetime"
                      placeholder="MM/dd/yyyy"
                      displayFormat="MM/dd/yyyy"
                      showAnalogClock={true}
                      useMaskBehavior={true}
                      defaultValue={this.state.filterToDatetime}
                      value={this.state.filterToDatetime}
                      onValueChanged={this.handleOnFilteToDateChanged}
                      width="100%"
                      disabled={false}
                      acceptCustomValue={false}
                    />
                  </div>&nbsp;
                {/* </div> */}
                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"> */}
                  <div className="form-group">
                    <label>Airport(s)</label>
                    <SelectBox
                      dataSource={this.state.airportList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="All Airports"
                      name="airportCode"
                      defaultValue={0}
                      value={this.state.airportCode}
                      displayExpr="airportCode"
                      //displayExpr={this.displayExpr}
                      valueExpr="airportCode"
                      onSelectionChanged={this.handleAirportChange}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                    />
                    {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                  </div>&nbsp;
                {/* </div> */}
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <span className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id="isShowPending" name="isShowPending" checked={this.state.isShowPending}
                            onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="isShowPending">Pending Rides</label>
                    </span>
                </div>
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <span className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id="isShowInProcess" name="isShowInProcess" checked={this.state.isShowInProcess} onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="isShowInProcess">InProcess Rides</label>
                    </span>
                </div>
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <span className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id="isShowCompleted" name="isShowCompleted" checked={this.state.isShowCompleted} onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="isShowCompleted">Completed Rides</label>
                    </span>
                </div>
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <span className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id="isShowCancelled" name="isShowCancelled" checked={this.state.isShowCancelled} onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="isShowCancelled">Cancelled Rides</label>
                    </span>
                </div>
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <span className="form-check form-group">
                        <input className="form-check-input" type="checkbox" id="isShowAll" name="isShowAll" checked={this.state.isShowAll} onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="isShowAll">All Rides</label>
                    </span>
                </div>
                <div className="form-group">
                    <label>&nbsp;</label><br />
                    <button type="submit" className="btn btn-primary" onClick={this.getReservations}>
                    {this.state.isSubmitted && (
                      <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>
                    )}
                    {!this.state.isSubmitted && (
                      <span title="Search"><i className="fas fa-search"></i> Search</span>
                    )}
                  </button>{" "}
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 displayNone">
                  <div className="form-group">
                    <label>Account(s)</label>
                    <SelectBox
                      dataSource={this.state.AccountList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Account"
                      //defaultValue={0}
                      value={this.state.accountCode}
                      valueExpr="accountCode"
                      displayExpr="serviceAccountName"
                      onSelectionChanged={this.handleOnFilterAccountChange}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                      width="100%"
                    />
                    {this.state.errors["accountCode"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["accountCode"]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="card">
            <div className="card-body">
                <div>
                <DataGrid
                    dataSource={this.state.reservationGridDataSource}
                    keyExpr="rideId"
                    //selection={{ mode: 'single' }}
                    columnsAutoWidth={false}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnResizing={true}
                    onRowPrepared={this.handleRowPrepared}
                    onRowInserting={this.insertRow}
                    columnResizingMode="widget"
                  >
                    <Column dataField="accountName" caption="Service Provider" allowSearch={true} allowFiltering={false} allowSorting={true} width={200} />
                    <Column dataField="flightInfo" caption="Flight #" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                    <Column dataField="flightTime" caption="Flight Time" width={80} allowFiltering={false} allowSorting={true} />
                    <Column dataField="flightATA" caption="Flight Actual Time" width={160} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                    <Column dataField="dutyReportingTime" caption="Reporting Time" width={160} allowFiltering={false} allowSorting={true} />
                    <Column dataField="deBriefingTime" caption="DeBriefing Time" width={160} allowFiltering={false} allowSorting={true} />
                    <Column dataField="pickupDateTime" caption="Pick-up Time" width={140} allowFiltering={false} allowSorting={true} format={DateFormat.DateTime24Format} dataType="datetime" />
                    <Column dataField="paxName" caption="Crew Name" width={150} allowFiltering={true} allowSorting={true} />
                    <Column dataField="boardingOtp" caption="Boarding Otp" width={80} allowFiltering={true} allowSorting={true} />                    
                    <Column dataField="paxMobileNo" caption="Crew Mobile" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="paxType" caption="Rank" width={70} allowFiltering={true} allowSorting={true} />
                    <Column dataField="rideType" caption="Trip Type" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="puStreet" caption="Pick-up" width={200} allowFiltering={true} allowSorting={true} />
                    <Column dataField="doStreet" caption="Drop Off" width={200} allowFiltering={true} allowSorting={true} />
                    <Column dataField="vehicleNo" caption="Vehicle #" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="allowClubbing" caption="Allow Clubbing" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="rideConfirmed" caption="Ride Confirmation" width={120} allowFiltering={true} allowSorting={true} />
                    <Column dataField="otpVerifiedStatus" caption="OTP Verify Status" width={120} allowFiltering={true} allowSorting={true} />
                    <Column dataField="clubbedStatus" caption="Clubbing Status" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="driverName" caption="Driver Name" width={150} allowFiltering={true} allowSorting={true} />
                    {/* <Column dataField="driverWorkingHours" caption="Driver working Hrs" width={120} allowFiltering={true} allowSorting={true} 
                    visible={allowToAddDriverWorkingHours === "true" ? true : false} /> */}
                    <Column dataField="tripStatusDesc" caption="Ride Status" width={150} allowFiltering={true} allowSorting={true} />
                    <Column dataField="flightTerminal" caption="Flight Terminal" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="dispatcherName" caption="Dispatcher" width={100} allowFiltering={true} allowSorting={true} />
                    <Column dataField="assignedTime" caption="Assign Time" width={100} allowFiltering={false} allowSorting={true} />
                    <Column dataField="onLocationTime" caption="On Location" width={80} allowFiltering={false} allowSorting={true} />
                    <Column dataField="onLoadTime" caption="Boarded" width={80} allowFiltering={false} allowSorting={true} />
                    <Column dataField="onCompletedTime" caption="Drop" width={125} allowFiltering={false} allowSorting={true} />
                    <Column dataField="assignedStatus" caption="Assigned Status" width={125} allowFiltering={false} allowSorting={true} />
                    <Column dataField="rideId" caption="Ride Id" width={90} allowFiltering={true} allowSorting={true} />
                    <SortByGroupSummaryInfo summaryItem="count" />
                    <GroupPanel visible={false} />
                    {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                    <HeaderFilter visible={true} />
                    <SearchPanel
                      visible={true}
                      highlightCaseSensitive={true}
                      width={240}
                      placeholder="Search..."
                    />
                    <Grouping autoExpandAll={true} />
                    <Paging defaultPageSize={20} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[10, 20, 50]}
                      showInfo={true}
                      visible={true}
                    />
                    <Export
                      enabled={true}
                      allowExportSelectedData={false}
                      fileName="Reservation"
                    />
                  </DataGrid>
                </div>
            </div>
          </div>
          <Modal
            ariaHideApp={false}
            isOpen={this.state.rideDetailsModal}
            style={{ content: { width: "65%" } }}
            className={"react-modal"}
            onAfterOpen={this.handleAssignDataToState}
            onRequestClose={this.handleResetStateData}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            <form
              method="post"
              onSubmit={this.handleRoutesSubmit}
              style={
                this.state.isSubmitted
                  ? { pointerEvents: "none", opacity: "0.4" }
                  : {}
              }
            >
              <div className="modal-header" style={{ backgound: "pink" }}>
                <h6 className="modal-title">Ride Id:{this.state.rideId} </h6>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleResetStateData}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card">
                  <div>
                    <h6 className="panel-title">&nbsp;Passenger Details</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label className="value-title">Name: </label>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label className="label-Color">
                          {this.state.rideDetails.name}
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="value-title">Phone Number: </label>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label className="label-Color">
                          {this.state.rideDetails.phnNo}
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="value-title">Cell Phone Number: </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="label-Color">
                          {this.state.rideDetails.cellNo}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="value-title">Email: </label>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="label-Color">
                          {this.state.rideDetails.email}
                        </label>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-2">
                        <label className="value-title">Number of Crew: </label>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-3">
                        <label className="label-Color">
                          {this.state.rideDetails.noOfCrew}
                        </label>
                      </div>
                      {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="value-title">Number of Pilot:</label>
                                          </div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="label-Color">{this.state.rideDetails.noOfPilot}</label>
                                          </div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="value-title">Number of F/A:</label>
                                          </div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="label-Color">{this.state.rideDetails.noOfFa}</label>
                                          </div> */}
                    </div>
                    <div className="row">
                      {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                              <label className="value-title">Number of Crew:</label>
                                          </div> */}
                      {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                              <label className="label-Color">{this.state.rideDetails.noOfCrew}</label>
                                          </div> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div>
                    <h6 className="panel-title">&nbsp;Ride Details</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label className="value-title">Pickup Address: </label>
                      </div>
                      <div className="col-xs-5 col-sm-5 col-md-5">
                        <label className="label-Color">
                          {this.state.rideDetails.puAddress}{" "}
                        </label>
                      </div>
                      <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label className="value-title">Drop Off Address: </label>
                      </div>
                      <div className="col-xs-5 col-sm-5 col-md-5">
                        <label className="label-Color">
                          {this.state.rideDetails.doAddress}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label className="value-title">
                          Vehicle Type: {this.state.rideDetails.vehicleType}
                        </label>
                      </div>
                      {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                              <label className="label-Color">{this.state.rideDetails.vehicleType}</label>
                                          </div> */}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label className="value-title">
                          Vehicle Capacity:{" "}
                          {this.state.rideDetails.vehicleCapacity}
                        </label>
                      </div>
                      {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                              <label className="label-Color">{this.state.rideDetails.vehicleCapacity}</label>
                                          </div> */}
                    </div>
  
                    {/* <div className="row">
                                        
                                      </div> */}
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label className="value-title">
                          Schedule Pickup: &nbsp;
                          {formatDate(
                            this.state.rideDetails.schPickUp,
                            "dd/MM/yyyy hh:mm:ss"
                          )}
                        </label>
                      </div>
                      {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                              <label className="label-Color">{formatDate(this.state.rideDetails.schPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                      {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                              <label className="value-title">Actual Pickup:&nbsp;{formatDate(this.state.rideDetails.actualPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                      {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                              <label className="label-Color">{formatDate(this.state.rideDetails.actualPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                      {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                              <label className="value-title">Actual Drop Off:&nbsp;{formatDate(this.state.rideDetails.actualDropOff, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                      {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                              <label className="label-Color">{formatDate(this.state.rideDetails.actualDropOff, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div>
                    <h6 className="panel-title">&nbsp;Flight Details</h6>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <label className="value-title">
                          Flight Date Time:{" "}
                          {formatDate(
                            this.state.rideDetails.flightDatetime,
                            "dd/MM/yyyy hh:mm:ss"
                          )}
                        </label>
                      </div>
                      {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="label-Color">{formatDate(this.state.rideDetails.flightDatetime, "dd/MM/yyyy hh:mm:ss")}</label>
                                          </div> */}
                      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label className="value-title">
                          Terminal: {this.state.rideDetails.flightTerminal}
                        </label>
                      </div>
                      {/* <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                              <label className="label-Color">{this.state.rideDetails.airline} </label>
                                          </div> */}
  
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label className="value-title">
                          Flight Number: {this.state.rideDetails.flightNo}
                        </label>
                      </div>
                      {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                              <label className="label-Color">{this.state.rideDetails.flightNo}</label>
                                          </div> */}
                      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label className="value-title">
                          Flight Type: {this.state.rideDetails.flightType}
                        </label>
                      </div>
                      {/* <label className="label-Color" id="flightType">{this.state.rideDetails.flightType}</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={position}
            visible={this.state.loadPanelVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          {/* <LocateVehicleComponent ref="locateVehicleModal" />
          <ReplayVehicleComponent ref="replayVehicleModel" /> */}
        </Fragment>
      );
    }
  }