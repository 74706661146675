import React, { Component, Fragment } from 'react';
import DataGrid, {
    Column,
    Export, Grouping,
    GroupPanel,
    HeaderFilter, Pager,
    Paging,
    SearchPanel,
    SortByGroupSummaryInfo
}
    from 'devextreme-react/data-grid';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

export class ServiceProviderComponent extends Component {
    static displayName = ServiceProviderComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            datasource: null,
            loadPanelVisible: false,
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getServiceProvider.bind(this)
            }
        });
    }
    async componentDidMount() {
        await this.getServiceProvider();
    }
    getServiceProvider = async () => {
        this.setState({
            loadPanelVisible: true
        });
        try {
            let airlineCode = sessionStorage.getItem('session_airlineCode');
            let UserId = sessionStorage.getItem('session_userId');
            let UserName = sessionStorage.getItem('session_userName');
            let SourceId = 0;
            var request = {};
            request.AirlineCode = airlineCode;
            request.UserId = UserId;
            request.UserName = UserName;
            request.SourceId = SourceId;
            const requestParams = getRequestParams("POST", request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Account', requestParams);
            const data = await response.json();
            this.setState({
                datasource: data.payload,
                loadPanelVisible: false
            });

        } catch (err) {
            console.log("Error in " + this.displayName + ".getAirports function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getAccountTracking = (e, currentPageName, data) => {        
        e.preventDefault();
        sessionStorage.setItem("session_PageName", currentPageName);
        this.props.history.push({
            pathname: '/tracking',
            state: { serviceProviderId: data.accountId, sourceUrl: "/serviceProviders" }
        });
    }
    render() {
        const renderTrackAccount = (data) => {
            return <div>                
                <a href="#" onClick={(e) => { this.getAccountTracking(e, "Tracking", data.data) }} style={{textDecoration:"underline"}} title={data.data.accountName}>{data.data.accountName}</a>&nbsp;&nbsp;
            </div>;
        }

        return (
            <Fragment>
                <div className="card">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.datasource}
                            keyExpr="accountId"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            columnResizingMode='widget'>
                            <Column dataField="accountCode" caption="Ground Provider Code " allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                            <Column dataField="serviceAccountName" caption="Ground Provider Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} cellRender={renderTrackAccount} />
                            <Column dataField="address" caption="Address" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                            <Column dataField="email" caption="Email" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                            <Column dataField="mobile" caption="Mobile" allowSearch={true} allowFiltering={false} allowSorting={true} width={300} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export enabled={true} allowExportSelectedData={false} fileName="Service Provider" />
                        </DataGrid>
                    </div>
                </div>
            </Fragment>
        )
    }
}