import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';


export class ForgotPasswordComponent extends Component {
    static displayName = ForgotPasswordComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '', message: '',
            errors: {
                emailAddress: '',
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation = () => {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.emailAddress = "";
            this.setState({ message: '' });

            if (this.state.emailAddress === '') {
                formIsValid = false;
                errors.emailAddress = "Please enter username/email.";
            }

            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            var resetPasswordRequest = {};
            resetPasswordRequest.UserName = this.state.emailAddress;
            const requestParams = getRequestParams('POST', resetPasswordRequest);
            try {
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/forget-password-request', requestParams);
                const data = await response.json();
                Swal.fire({
                    icon: data.isSuccess ? 'Error' : 'error',
                    title: data.isSuccess ? 'Success' : 'Oops...',
                    text: data.message,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (data.isSuccess) {
                        if (result.isConfirmed) {
                            this.setState({ emailAddress: "" });
                            window.location.href = "/";
                        }
                        else {
                            this.setState({ isSubmited: false });
                        }
                    } else {
                        this.setState({ isSubmited: false });
                    }
                })
            } catch (err) {
                console.log("Error in " + this.displayName + ".handleSubmit function", err);
                this.setState({ isSubmited: false });
            }
        }
    }

    render() {
        return (
            <div>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="login-brand">
                                <img src="../assets/img/logo_akasa_air.png" alt="logo" width="300" className="mb-3" />
                                </div>

                                <div className="card">
                                    <div className="card-header" style={{minHeight:"auto"}}><h4>Forgot Password</h4></div>

                                    <div className="card-body">
                                        <p className="text-muted">We will send a link to reset your password</p>
                                        <form method="post" onSubmit={this.handleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            <div className="form-group">
                                                <label for="emailAddress">Username / Email</label>
                                                <input type="text" className="form-control" name="emailAddress" placeholder="Username / Email" value={this.state.emailAddress} onChange={this.handleInputChange} tabindex="1" autofocus />
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary btn-lg btn-block" tabindex="4">
                                                    Send
                                                </button>

                                            </div>
                                            <div className="float-right">
                                                <a href="/" className="text-small">
                                                    Back to Login
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="simple-footer">
                                    © Copyright 2022 SilverStar All Rights Reserved. Designed by PlexiTech Transsolutions LLP
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }

}