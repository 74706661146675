import React, { Component } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import List, { ItemDragging, MenuItem } from "devextreme-react/list";
import DataSource from "devextreme/data/data_source";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import { LoadPanel } from "devextreme-react/load-panel";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import RadioGroup from "devextreme-react/radio-group";

const hotelBookingPriorityLst = [
  {
    rowIndex: 1,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FSJPC",
    hotelName: "Fortune Select JP Cosmos",
    crewType: "F",
    crewTypeCategory: "Cabin Crew",
    gender: 0,
    genderText: "Female",
    priority: 1,
  },
  {
    rowIndex: 2,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "WFM",
    hotelName: "World Fair Marina",
    crewType: "F",
    crewTypeCategory: "Cabin Crew",
    gender: 0,
    genderText: "Female",
    priority: 2,
  },
  {
    rowIndex: 3,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FPGPC",
    hotelName: "Fortune Park JP Celestial",
    crewType: "F",
    crewTypeCategory: "Cabin Crew",
    gender: 0,
    genderText: "Female",
    priority: 3,
  },
  {
    rowIndex: 4,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FSJPC",
    hotelName: "Fortune Select JP Cosmos",
    crewType: "M",
    crewTypeCategory: "Cabin Crew",
    gender: 1,
    genderText: "Male",
    priority: 1,
  },
  {
    rowIndex: 4,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "WFM",
    hotelName: "World Fair Marina",
    crewType: "M",
    crewTypeCategory: "Cabin Crew",
    gender: 1,
    genderText: "Male",
    priority: 2,
  },
  {
    rowIndex: 4,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FPGPC",
    hotelName: "Fortune Park JP Celestial",
    crewType: "M",
    crewTypeCategory: "Cabin Crew",
    gender: 1,
    genderText: "Male",
    priority: 3,
  },
  {
    rowIndex: 7,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FSJPC",
    hotelName: "Fortune Select JP Cosmos",
    crewType: "F",
    crewTypeCategory: "Cockpit",
    gender: 0,
    genderText: "Female",
    priority: 1,
  },
  {
    rowIndex: 8,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "WFM",
    hotelName: "World Fair Marina",
    crewType: "F",
    crewTypeCategory: "Cockpit",
    gender: 0,
    genderText: "Female",
    priority: 2,
  },
  {
    rowIndex: 9,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FPGPC",
    hotelName: "Fortune Park JP Celestial",
    crewType: "F",
    crewTypeCategory: "Cockpit",
    gender: 0,
    genderText: "Female",
    priority: 3,
  },
  {
    rowIndex: 10,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FSJPC",
    hotelName: "Fortune Select JP Cosmos",
    crewType: "M",
    crewTypeCategory: "Cockpit",
    gender: 1,
    genderText: "Male",
    priority: 1,
  },
  {
    rowIndex: 11,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "WFM",
    hotelName: "World Fair Marina",
    crewType: "M",
    crewTypeCategory: "Cockpit",
    gender: 1,
    genderText: "Male",
    priority: 2,
  },
  {
    rowIndex: 12,
    airlineCode: "QP",
    airportCode: "BLR",
    hotelCode: "FPGPC",
    hotelName: "Fortune Park JP Celestial",
    crewType: "M",
    crewTypeCategory: "Cockpit",
    gender: 1,
    genderText: "Male",
    priority: 3,
  },
];
const ItemTemplate = (item) => (
  <>
    <div>{item.hotelName}</div>
    <div style={{ alignItems: "right" }}></div>
  </>
);
const position = { of: "#historydiv" };
const CrewTypeCategoryLst = [
  { code: "P", name: "Cockpit" },
  { code: "F", name: "Cabin Crew" },
  { code: "both", name: "Both" },
];
const CrewGenderLst = [
  { code: "M", name: "Male" },
  { code: "F", name: "Female" },
  { code: "both", name: "Both" },
];
export class HotelBookingPriority extends Component {
  static displayName = HotelBookingPriority.name;
  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      isFiltered: false,
      isSubmitted: false,
      filterAirportDropdownDataSource: null,
      filterAirportCode: "",
      hotelBookingPriorityDataSource: [],
      showSetPriorityModal: false,
      airportDropdownDataSource: null,
      airportCode: "",
      hotelDropdownDataSource: null,
      hotelCode: "",
      crewTypeCategoryDataSource: CrewTypeCategoryLst,
      crewTypeCategoryCode: "both",
      crewGenderDataSource: CrewGenderLst,
      crewGender: "both",
      errors: {
        filterAirport: "",
        airport: "",
        hotel: "",
        crewTypeCategoryCode: "",
        crewGender: "",
      },
    };
    this.isUseEffectRendered = false;
    this.reorderGroupNo = 0;
    this.refList = React.createRef();
  }
  componentDidMount() {
    try {
      if (this.isUseEffectRendered) {
        return;
      }
      this.isUseEffectRendered = true;
      this.getAirportDropdownList();
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".componentDidMount function",
        error
      );
    }
  }
  getAirportDropdownList = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET", null);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Airport/AirportDropdown/${airlineCode}`,
        requestParams
      );
      const data = await response.json();
      let airportDropdownDataLst =
        data?.payload?.sort((a, b) =>
          a.airportCode.localeCompare(b.airportCode)
        ) ?? [];

      this.setState((prev) => ({
        ...prev,
        filterAirportDropdownDataSource: airportDropdownDataLst,
      }));
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getAirportDropdownList function",
        error
      );
    }
  };
  handleOnFilterAirportDropdownChange = (e) => {
    try {
      let err = this.state.errors;
      err.filterAirport = "";
      let airportCode = e.value ?? "";
      this.setState((prev) => ({
        ...prev,
        filterAirportCode: airportCode,
        hotelBookingPriorityDataSource: [],
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnFilterAirportDropdownChange function",
        error
      );
    }
  };
  handelOnGetHotelBookingPriority = async () => {
    try {
      const airportCode = this.state.filterAirportCode;
      let err = this.state.errors;
      err.filterAirport = "";
      if (airportCode.trim() === "") {
        err.filterAirport = "Please select airport";
        this.setState((prev) => ({
          ...prev,
          errors: err,
        }));
        return;
      }
      this.setState((prev) => ({
        ...prev,
        isFiltered: true,
      }));
      await this.getHotelBookingPriority();
      this.setState((prev) => ({
        ...prev,
        isFiltered: false,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handelOnGetHotelBookingPriority function",
        error
      );
    }
  };
  getHotelBookingPriority = async () => {
    try {
      if (this.state.filterAirportCode.trim() !== "") {
        this.setState((prev) => ({
          ...prev,
          loadPanelVisible: true,
        }));
        let airlineCode = sessionStorage.getItem("session_airlineCode");
        const airportCode = this.state.filterAirportCode;
        let request = {};
        request.AirlineCode = airlineCode;
        request.AirportCode = airportCode;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}HotelBooking/hotel-priority`,
          requestParams
        );
        const data = await response.json();
        let hotelBookingPriorityDataLst = data?.payload ?? [];

        let dataGropList = [
          ...new Set(
            hotelBookingPriorityDataLst.map((item) => ({
              ...item,
              groupText: `( ${item.airportCode} ) ${item.crewTypeCategory} - ${item.genderText}`,
              groupKey: `${item.airportCode}${item.crewTypeCategory}${item.genderText}`,
            }))
          ),
        ];
        this.setState((prev) => ({
          ...prev,
          hotelBookingPriorityDataSource: dataGropList,
          loadPanelVisible: false,
        }));
        // if (this.reorderGroupNo >= 0) {
        //   this.refList.current.instance.expandGroup(this.reorderGroupNo);
        // }
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handelOnGetHotelBookingPriority function",
        error
      );
      this.setState((prev) => ({
        ...prev,
        loadPanelVisible: false,
      }));
    }
  };
  handleOnAfterOpenSetPriorityModal = async () => {
    try {
      this.setState((prev) => ({
        ...prev,
        loadPanelVisible: true,
      }));
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let requestParams = getRequestParams("GET", null);
      const airportResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Airport/AirportDropdown/${airlineCode}`,
        requestParams
      );
      const airportDataLst = await airportResponse.json();
      let airportDropdownDataLst =
        airportDataLst?.payload?.sort((a, b) =>
          a.airportCode.localeCompare(b.airportCode)
        ) ?? [];

      requestParams = getRequestParams("GET", null);
      const hotelResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Hotel/${airlineCode}`,
        requestParams
      );
      const hotelDataLst = await hotelResponse.json();
      let hotelDropdownDataLst =
        hotelDataLst?.payload?.sort((a, b) =>
          a.hotelName.localeCompare(b.hotelName)
        ) ?? [];
      this.setState((prev) => ({
        ...prev,
        airportDropdownDataSource: airportDropdownDataLst,
        hotelDropdownDataSource: hotelDropdownDataLst,
        loadPanelVisible: false,
      }));
    } catch (error) {
      this.setState((prev) => ({
        ...prev,
        loadPanelVisible: false,
      }));
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAfterOpenSetPriorityModal function",
        error
      );
    }
  };
  handleOnCloseSetPriorityModal = () => {
    try {
      let err = this.state.errors;
      err.airport = "";
      err.hotel = "";
      err.crewTypeCategoryCode = "";
      err.crewGender = "";
      this.setState((prev) => ({
        ...prev,
        airportDropdownDataSource: [],
        airportCode: "",
        hotelDropdownDataSource: [],
        hotelCode: "",
        crewTypeCategoryCode: "both",
        crewGender: "both",
        showSetPriorityModal: false,
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnCloseSetPriorityModal function",
        error
      );
    }
  };
  handleOnAirportDropdownChange = (e) => {
    try {
      let err = this.state.errors;
      err.airport = "";
      let airportCode = e.value ?? "";
      this.setState((prev) => ({
        ...prev,
        airportCode: airportCode,
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAirportDropdownChange function",
        error
      );
    }
  };
  handleOnHotelDropdownChange = (e) => {
    try {
      let err = this.state.errors;
      err.hotel = "";
      let hotelCode = e.value ?? "";
      this.setState((prev) => ({
        ...prev,
        hotelCode: hotelCode,
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnHotelDropdownChange function",
        error
      );
    }
  };
  handelOnChangeSelectionCrewType = (e) => {
    try {
      let err = this.state.errors;
      err.crewTypeCategoryCode = "";
      let crewTypeData = e.value ?? "";
      this.setState((prev) => ({
        ...prev,
        crewTypeCategoryCode: crewTypeData,
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handelOnChangeSelectionCrewType function",
        error
      );
    }
  };
  handelOnChangeSelectionCrewGender = (e) => {
    try {
      let err = this.state.errors;
      err.crewGender = "";
      this.setState((prev) => ({
        ...prev,
        crewGender: e.value,
        errors: err,
      }));
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handelOnChangeSelectionCrewGender function",
        error
      );
    }
  };
  handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.isFormValid()) {
        let msg = `Are you sure want to add`;
        let title = "Booking Hotel Priority";
        let isConfirm = await this.isConfirm("add", msg, title);
        if (!isConfirm) {
          return;
        }
        this.setState((prev) => ({
          ...prev,
          isSubmitted: true,
        }));
        var request = {};
        request.UserId = parseInt(sessionStorage.getItem("session_userId"));
        request.AirlineCode = sessionStorage.getItem("session_airlineCode");
        request.AirportCode = this.state.airportCode;
        request.HotelCode = this.state.hotelCode;
        request.CrewType =
          this.state.crewTypeCategoryCode === "both"
            ? ["P", "F"]
            : [`${this.state.crewTypeCategoryCode}`];
        request.Gender =
          this.state.crewGender === "both"
            ? ["M", "F"]
            : [`${this.state.crewGender}`];
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}HotelBooking/insert-hotel-priority`,
          requestParams
        );
        const data = await response.json();
        this.setState((prev) => ({
          ...prev,
          isSubmitted: false,
        }));
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Hotel has been added ${title} successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.handleOnCloseSetPriorityModal();
              await this.getHotelBookingPriority();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnSubmit function",
        error
      );

      this.setState((prev) => ({
        ...prev,
        isSubmitted: false,
      }));
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  isFormValid = () => {
    let formIsValid = true;
    try {
      let err = this.state.errors;
      err.airport = "";
      err.hotel = "";
      err.crewTypeCategoryCode = "";
      err.crewGender = "";
      if (this.state.airportCode.trim() === "") {
        formIsValid = false;
        err.airport = "Please select airport";
      }
      if (this.state.hotelCode.trim() === "") {
        formIsValid = false;
        err.hotel = "Please select hotel";
      }
      if (this.state.crewTypeCategoryCode.trim() === "") {
        formIsValid = false;
        err.crewTypeCategoryCode = "Please select crew booking for";
      }
      if (this.state.crewGender.trim() === "") {
        formIsValid = false;
        err.crewGender = "Please select gender";
      }
      this.setState((prev) => ({
        ...prev,
        errors: err,
      }));
    } catch (error) {
      formIsValid = false;
      console.log(
        "Error in " + this.displayName + ".isFormValid function",
        error
      );
    }
    return formIsValid;
  };
  handleOnItemReordered = async (item) => {
    try {
      const { group } = { ...item.itemIndex };
      this.reorderGroupNo = group;
      item.component.expandGroup(group);
      const { items } = item.component.option("items")[group];
      let priorityItemsRequest = [];
      items.forEach((element, index) => {
        const { airlineCode, airportCode, hotelCode, crewType, gender } = {
          ...element,
        };
        let item = {};
        item.AirlineCode = airlineCode;
        item.AirportCode = airportCode;
        item.HotelCode = hotelCode;
        item.CrewType = crewType;
        item.Gender = gender ? "M" : "F";
        item.Priority = index + 1;
        priorityItemsRequest.push(item);
      });
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 0.5 sec
      let request = {};
      request.UserId = parseInt(sessionStorage.getItem("session_userId"));
      request.Data = priorityItemsRequest;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}HotelBooking/update-hotel-priority`,
        requestParams
      );
      const data = await response.json();
      if (!data.isSuccess) {
        toast.error(
          data.message === null || data.message === ""
            ? "Unable to process the request."
            : data.message,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          }
        );
        await this.getHotelBookingPriority();
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnItemReordered function",
        error
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleOnItemDelete = async (e) => {
    try {
      e.cancel = true;
      debugger;
      const { group } = { ...e.itemIndex };
      this.reorderGroupNo = group;
      const {
        rowIndex,
        airlineCode,
        airportCode,
        hotelCode,
        hotelName,
        crewType,
        gender,
      } = {
        ...e.itemData,
      };
      let msg = `${hotelName}`;
      let title = "Delete Booking Hotel Priority";
      let isConfirm = await this.isConfirm("delete", msg, title);
      if (!isConfirm) {
        return;
      }
      this.setState((prev) => ({
        ...prev,
        loadPanelVisible: true,
      }));

      await new Promise((resolve) => setTimeout(resolve, 1000)); // 0.5 sec
      let request = {
        AirlineCode: airlineCode,
        AirportCode: airportCode,
        HotelCode: hotelCode,
        CrewType: crewType,
        Gender: gender ? "M" : "F",
      };
      request.UserId = parseInt(sessionStorage.getItem("session_userId"));
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}HotelBooking/${rowIndex}/delete-hotel-priority`,
        requestParams
      );
      const data = await response.json();
      this.setState((prev) => ({
        ...prev,
        loadPanelVisible: false,
      }));
      if (data.isSuccess) {
        e.cancel = false;
        await Swal.fire({
          allowOutsideClick: false,
          icon: "success",
          title: "Success",
          text:
            data.message === null || data.message === ""
              ? `Hotel has been delete
              successfully.`
              : data.message,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.getHotelBookingPriority();
            e.component.expandGroup(group);
          }
        });
      } else {
        toast.error(
          data.message === null || data.message === ""
            ? "Unable to process the request."
            : data.message,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          }
        );
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnItemDelete function",
        error
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  isConfirm = async (type, msg, title) => {
    let isResult = false;
    let confirmMessage = "";
    if (type === "delete") {
      if (msg !== "") {
        confirmMessage = "Are you sure want to delete this <b>" + msg + "</b>?";
      } else {
        confirmMessage = "Are you sure want to delete this ?";
      }
    } else if (type === "status") {
      confirmMessage = msg;
    } else {
      confirmMessage =
        "Are you sure want to " +
        (type === "add" ? "Add" : "Update") +
        " this configuration?";
    }
    await Swal.fire({
      title: title,
      html: "<small>" + confirmMessage + "</small>",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText:
        type === "delete" ? "Delete" : type === "add" ? "Add" : title,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isResult = true;
      }
    });
    return isResult;
  };
  render() {
    return (
      <>
        <div className="card" style={{ height: "90vh" }}>
          <div className="card-body">
            <div className="row">
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <SelectBox
                    dataSource={this.state.filterAirportDropdownDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="ddlairport"
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    value={this.state.filterAirportCode}
                    onValueChanged={this.handleOnFilterAirportDropdownChange}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                  {this.state.errors.filterAirport.length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors.filterAirport}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handelOnGetHotelBookingPriority}
                  >
                    {this.state.isFiltered && (
                      <span title="Filter">
                        <i className="fas fa-spinner fa-spin"></i> Filter
                      </span>
                    )}
                    {!this.state.isFiltered && (
                      <span title="Filter">
                        <i className="fas fa-filter"></i> Filter
                      </span>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState((prev) => ({
                        ...prev,
                        showSetPriorityModal: true,
                      }));
                    }}
                  >
                    <span title="">
                      <i className="fas fa-plus"></i> Set Hotel Priority
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <List
                    ref={this.refList}
                    dataSource={
                      new DataSource({
                        store: this.state.hotelBookingPriorityDataSource,
                        sort: "priority",
                        group: "groupText",
                        paginate: true,
                        pageSize: 10,
                      })
                    }
                    height={600}
                    keyExpr="rowIndex"
                    displayExpr="hotelName"
                    grouped={true}
                    itemRender={ItemTemplate}
                    collapsibleGroups={true}
                    //searchEnabled={true}
                    searchExpr={["hotelName", "crewTypeCategory", "genderText"]}
                    onContentReady={(e) => {
                      var items = e.component.option("items");
                      for (var i = 0; i < items.length; i++) {
                        e.component.collapseGroup(i);
                      }
                      //e.component.expandGroup(this.reorderGroupNo);
                    }}
                    pageLoadMode="scrollBottom"
                    onItemReordered={this.handleOnItemReordered}
                    allowItemDeleting={true}
                    onItemDeleting={this.handleOnItemDelete}
                    indicateLoading={true}
                  >
                    <ItemDragging allowReordering={true} />
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showSetPriorityModal}
          style={{ content: { width: "35%" } }}
          className={"react-modal"}
          onAfterOpen={this.handleOnAfterOpenSetPriorityModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">Add Hotel Booking Priority</h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleOnCloseSetPriorityModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <label>Select Airport</label>
                  <SelectBox
                    dataSource={this.state.airportDropdownDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="ddlairport"
                    defaultValue={""}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onValueChanged={this.handleOnAirportDropdownChange}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                  {this.state.errors["airport"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["airport"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <label>Select stay hotel</label>
                  <SelectBox
                    dataSource={this.state.hotelDropdownDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Hotel"
                    name="ddlhotel"
                    defaultValue={""}
                    value={this.state.hotelCode}
                    displayExpr="hotelName"
                    valueExpr="hotelCode"
                    onValueChanged={this.handleOnHotelDropdownChange}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                  {this.state.errors["hotel"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["hotel"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Hotel Booking For</label>
                  <RadioGroup
                    id="radio-group-with-selection"
                    dataSource={this.state.crewTypeCategoryDataSource}
                    layout="horizontal"
                    value={this.state.crewTypeCategoryCode}
                    valueExpr="code"
                    displayExpr="name"
                    onValueChanged={this.handelOnChangeSelectionCrewType}
                  />
                  {this.state.errors["crewTypeCategoryCode"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["crewTypeCategoryCode"]}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Crew Gender</label>
                  <RadioGroup
                    id="radio-group-with-selection"
                    dataSource={this.state.crewGenderDataSource}
                    layout="horizontal"
                    value={this.state.crewGender}
                    valueExpr="code"
                    displayExpr="name"
                    onValueChanged={this.handelOnChangeSelectionCrewGender}
                  />
                  {this.state.errors.crewGender.length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors.crewGender}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                this.handleOnSubmit(e);
              }}
            >
              {this.state.isSubmitted && (
                <span title="Save">
                  <i className="fas fa-spinner fa-spin"></i> In-process
                </span>
              )}
              {!this.state.isSubmitted && (
                <span title="Save">
                  <i className="fas fa-check"></i> Save
                </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-custom-gray "
              title="Close"
              onClick={this.handleOnCloseSetPriorityModal}
            >
              <i className="fas fa-times"></i> Close
            </button>
          </div>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </>
    );
  }
}
