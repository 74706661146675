import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Template } from "devextreme-react/core/template";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import { LoadPanel } from "devextreme-react/load-panel";
import RadioGroup from "devextreme-react/radio-group";
import { RegEx } from "../../helpers/fixcodes.js";

export const hotelShuttleConfigEntities = [
  { id: 1, text: "Hotel Transportation will be provided on all days of stay" },
  {
    id: 2,
    text: "Hotel Transportation will be provided only on the day of check-in and check-out",
  },
  {
    id: 3,
    text: "Hotel Transportation will be provided on all days of stay except on the day of check-in and check-out",
  },
];

export const crewPerRoomEntities = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
];
const position = { of: "#historydiv" };
export class TransportHotelConfigurationComponent extends Component {
  static displayName = TransportHotelConfigurationComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      showAddEditModel: false,
      isSubmitted: false,
      viewReadOnly: false,
      transportConfigGridDataSource: [],
      configData: null,
      airportDropdownList: [],
      airportCode: "",
      hotelDropdownList: [],
      hotelCode: "",
      hotelApi: "Nexus",
      includeHotelShuttle: true,
      hotelTransportConfigType: hotelShuttleConfigEntities,
      hotelTransportConfigTypeId: hotelShuttleConfigEntities[0].id,
      hotelStayCrewTypeId: 3, //1:Cockpit,2:Cabin,3:Both
      isCabinCrewMaleRoomSharing: false,
      isCabinCrewFemaleRoomSharing: false,
      isCockpitCrewMaleRoomSharing: false,
      isCockpitCrewFemaleRoomSharing: false,
      crewPerRoomList: crewPerRoomEntities,
      roomSharingCabinCrewMaleCount: 0,
      roomSharingCabinCrewFemaleCount: 0,
      roomSharingCockpitCrewMaleCount: 0,
      roomSharingCockpitCrewFemaleCount: 0,
      cabinCrewMaleAllowBookHotel: false,
      cabinCrewFemaleAllowBookHotel: false,
      cabinCockpitMaleAllowBookHotel: false,
      cabinCockpitFemaleAllowBookHotel: false,
      cancelHotelBeforeMinutes: 360,
      errors: {
        airport: "",
        hotel: "",
      },
    };
    //this.toolbarItemRender = this.toolbarItemRender.bind(this);
  }

  async componentDidMount() {
    await this.getTransportConfiguration();
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getTransportConfiguration.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };
  toolbarItemRender = () => {
    return (
      <Fragment>
        <div className="row">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleAddTransportConfiguration}
          >
            <span title="Submit">
              <i className="fas fa-plus"></i> Add Configuration
            </span>
          </button>
        </div>
      </Fragment>
    );
  };
  getTransportConfiguration = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Configuration/TransportConfiguration/`,
        requestParams
      );
      const data = await response.json();
      this.setState({
        transportConfigGridDataSource:
          data.payload !== null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getTransportConfiguration function",
        err
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getAirportDropdownList = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let userId = parseInt(sessionStorage.getItem("session_userId"));
      let userName = sessionStorage.getItem("session_userName");
      let sourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = userId;
      request.UserName = userName;
      request.SourceId = sourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Airport/AirlineAirport/`,
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportDropdownList:
          data?.payload?.sort((a, b) =>
            a.airportCode.localeCompare(b.airportCode)
          ) ?? [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirportDropdownList function",
        err
      );
    }
  };
  getHotelDropdownList = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Hotel/${airlineCode}`,
        requestParams
      );
      const data = await response.json();
      this.setState({
        hotelDropdownList:
          data?.payload?.sort((a, b) =>
            a.hotelName.localeCompare(b.hotelName)
          ) ?? [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getHotelDropdownList function",
        err
      );
    }
  };
  handleAddTransportConfiguration = (e) => {
    this.setState({
      showAddEditModel: true,
      configData: null,
    });
  };
  handleOnEditTransportConfiguration = async (e, data) => {
    e.preventDefault();
    try {
      if (data != null) {
        this.setState({
          showAddEditModel: true,
          configData: data,
        });
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnEditTransportConfiguration function",
        error
      );
    }
  };
  handleOnViewReadOnlyTransportConfiguration = async (e, data) => {
    e.preventDefault();
    try {
      if (data != null) {
        this.setState({
          showAddEditModel: true,
          viewReadOnly: true,
          configData: data,
        });
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnViewReadOnlyTransportConfiguration function",
        error
      );
    }
  };
  handleOnAfterOpenConfigModal = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      if (this.state.configData !== null) {
        let configData = this.state.configData;
        let includeHotelShuttle = false;
        let hotelTransportConfigTypeId = 0;
        if (
          configData.assignHotelShuttleTransporter === true ||
          configData.assignHotelShuttleTranspoterLayover === true
        ) {
          includeHotelShuttle = true;
          if (
            configData.assignHotelShuttleTransporter === true &&
            configData.assignHotelShuttleTranspoterLayover === true
          ) {
            hotelTransportConfigTypeId = 1;
          } else if (
            configData.assignHotelShuttleTransporter === true &&
            configData.assignHotelShuttleTranspoterLayover === false
          ) {
            hotelTransportConfigTypeId = 2;
          } else if (
            configData.assignHotelShuttleTransporter === false &&
            configData.assignHotelShuttleTranspoterLayover === true
          ) {
            hotelTransportConfigTypeId = 3;
          }
        }
        this.setState({
          airportCode: configData.airportCode,
          hotelCode: configData.hotelCode,
          includeHotelShuttle: includeHotelShuttle,
          hotelTransportConfigTypeId: hotelTransportConfigTypeId,
          hotelStayCrewTypeId: configData.hotelStayCrewTypeId,
          isCabinCrewMaleRoomSharing:
            Number(configData.cabinMaleCrewPerRoom) > 0,
          isCabinCrewFemaleRoomSharing:
            Number(configData.cabinFemaleCrewPerRoom) > 0,
          isCockpitCrewMaleRoomSharing:
            Number(configData.pilotMaleCrewPerRoom) > 0,
          isCockpitCrewFemaleRoomSharing:
            Number(configData.pilotFemaleCrewPerRoom) > 0,
          roomSharingCabinCrewMaleCount: Number(
            configData.cabinMaleCrewPerRoom
          ),
          roomSharingCabinCrewFemaleCount: Number(
            configData.cabinFemaleCrewPerRoom
          ),
          roomSharingCockpitCrewMaleCount: Number(
            configData.pilotMaleCrewPerRoom
          ),
          roomSharingCockpitCrewFemaleCount: Number(
            configData.pilotFemaleCrewPerRoom
          ),
          cabinCrewMaleAllowBookHotel: configData.cabinMaleAllowToBookHotel,
          cabinCrewFemaleAllowBookHotel: configData.cabinFemaleAllowToBookHotel,
          cabinCockpitMaleAllowBookHotel: configData.pilotMaleAllowToBookHotel,
          cabinCockpitFemaleAllowBookHotel:
            configData.pilotFemaleAllowToBookHotel,
          cancelHotelBeforeMinutes: configData.cancelHotelBeforeMinutes,
        });
      }
      await this.getAirportDropdownList();
      await this.getHotelDropdownList();
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAfterOpenConfigModal function",
        err
      );
    } finally {
      this.setState({ loadPanelVisible: false });
    }
  };
  handleOnCloseModal = () => {
    try {
      this.setState({
        loadPanelVisible: false,
        showAddEditModel: false,
        isSubmitted: false,
        viewReadOnly: false,
        configData: null,
        airportDropdownList: [],
        airportCode: "",
        hotelDropdownList: [],
        hotelCode: "",
        hotelApi: "Nexus",
        includeHotelShuttle: true,
        hotelTransportConfigType: hotelShuttleConfigEntities,
        hotelTransportConfigTypeId: hotelShuttleConfigEntities[0].id,
        hotelStayCrewTypeId: 3, //1:Cockpit,2:Cabin,3:Both
        isCabinCrewMaleRoomSharing: false,
        isCabinCrewFemaleRoomSharing: false,
        isCockpitCrewMaleRoomSharing: false,
        isCockpitCrewFemaleRoomSharing: false,
        crewPerRoomList: crewPerRoomEntities,
        roomSharingCabinCrewMaleCount: 0,
        roomSharingCabinCrewFemaleCount: 0,
        roomSharingCockpitCrewMaleCount: 0,
        roomSharingCockpitCrewFemaleCount: 0,
        cabinCrewMaleAllowBookHotel: false,
        cabinCrewFemaleAllowBookHotel: false,
        cabinCockpitMaleAllowBookHotel: false,
        cabinCockpitFemaleAllowBookHotel: false,
        cancelHotelBeforeMinutes: 360,
        errors: {
          airport: "",
          hotel: "",
        },
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnCloseModal function",
        error
      );
    }
  };
  handleOnAirportDropdownChange = async (e) => {
    try {
      let err = this.state.errors;
      err.airport = "";
      this.setState({
        airportCode: e.selectedItem?.airportCode ?? "",
        errors: err,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAirportDropdownChange function",
        error
      );
    }
  };
  handleOnHotelDropdownChange = async (e) => {
    try {
      let err = this.state.errors;
      err.hotel = "";
      this.setState({
        hotelCode: e.selectedItem?.hotelCode ?? "",
        errors: err,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnHotelDropdownChange function",
        error
      );
    }
  };

  handleInputChange = (event) => {
    try {
      const target = event.target;
      const value =
        target.type === "checkbox" || target.type === "radio"
          ? target.checked
          : target.value;
      const name = target.name;
      switch (name) {
        case "cancelHotelBeforeMinutes":
          if (value.trim() === "" || RegEx.Numeric.test(value)) {
            if (value.length <= 6) {
              this.setState({ cancelHotelBeforeMinutes: value.trim() });
            }
          }
          break;
        case "hotelStayCabinCrew":
          this.setState({
            hotelStayCrewTypeId: 2,
            isCabinCrewMaleRoomSharing: false,
            isCabinCrewFemaleRoomSharing: false,
            isCockpitCrewMaleRoomSharing: false,
            isCockpitCrewFemaleRoomSharing: false,
            roomSharingCockpitCrewMaleCount: 1,
            roomSharingCockpitCrewFemaleCount: 1,
            roomSharingCabinCrewMaleCount: 1,
            roomSharingCabinCrewFemaleCount: 1,
            cabinCrewMaleAllowBookHotel: false,
            cabinCrewFemaleAllowBookHotel: false,
            cabinCockpitMaleAllowBookHotel: false,
            cabinCockpitFemaleAllowBookHotel: false,
          });
          break;
        case "hotelStayCockpitCrew":
          this.setState({
            hotelStayCrewTypeId: 1,
            isCabinCrewMaleRoomSharing: false,
            isCabinCrewFemaleRoomSharing: false,
            isCockpitCrewMaleRoomSharing: false,
            isCockpitCrewFemaleRoomSharing: false,
            roomSharingCockpitCrewMaleCount: 1,
            roomSharingCockpitCrewFemaleCount: 1,
            roomSharingCabinCrewMaleCount: 1,
            roomSharingCabinCrewFemaleCount: 1,
            cabinCrewMaleAllowBookHotel: false,
            cabinCrewFemaleAllowBookHotel: false,
            cabinCockpitMaleAllowBookHotel: false,
            cabinCockpitFemaleAllowBookHotel: false,
          });
          break;
        case "HotelStayCrewTypeBoth":
          this.setState({
            hotelStayCrewTypeId: 3,
            isCabinCrewMaleRoomSharing: false,
            isCabinCrewFemaleRoomSharing: false,
            isCockpitCrewMaleRoomSharing: false,
            isCockpitCrewFemaleRoomSharing: false,
            roomSharingCockpitCrewMaleCount: 1,
            roomSharingCockpitCrewFemaleCount: 1,
            roomSharingCabinCrewMaleCount: 1,
            roomSharingCabinCrewFemaleCount: 1,
            cabinCrewMaleAllowBookHotel: false,
            cabinCrewFemaleAllowBookHotel: false,
            cabinCockpitMaleAllowBookHotel: false,
            cabinCockpitFemaleAllowBookHotel: false,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleInputChange function",
        error
      );
    }
  };

  handleOnCabinCrewMaleRoomSharingChange = (e) => {
    try {
      this.setState({
        roomSharingCabinCrewMaleCount: e.selectedItem?.id ?? 0,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnCabinCrewMaleRoomSharingChange function",
        error
      );
    }
  };
  handleOnCabinCrewFemaleRoomSharingChange = (e) => {
    try {
      this.setState({
        roomSharingCabinCrewFemaleCount: e.selectedItem?.id ?? 0,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnCabinCrewFemaleRoomSharingChange function",
        error
      );
    }
  };
  handleOnCockpitCrewMaleRoomSharingChange = (e) => {
    try {
      this.setState({
        roomSharingCockpitCrewMaleCount: e.selectedItem?.id ?? 0,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleOnCockpitCrewFemaleRoomSharingChange = (e) => {
    try {
      this.setState({
        roomSharingCockpitCrewFemaleCount: e.selectedItem?.id ?? 0,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.isFormValid()) {
        this.setState({ isSubmitted: true });
        let assignHotelShuttleTransporter = false;
        let assignHotelShuttleTranspoterLayover = false;
        if (this.state.includeHotelShuttle === true) {
          if (this.state.hotelTransportConfigTypeId === 1) {
            assignHotelShuttleTransporter = true;
            assignHotelShuttleTranspoterLayover = true;
          } else if (this.state.hotelTransportConfigTypeId === 2) {
            assignHotelShuttleTransporter = true;
            assignHotelShuttleTranspoterLayover = false;
          } else if (this.state.hotelTransportConfigTypeId === 3) {
            assignHotelShuttleTransporter = false;
            assignHotelShuttleTranspoterLayover = true;
          }
        }
        let request = {};
        request.AirlineCode = sessionStorage.getItem("session_airlineCode");
        request.AirportCode = this.state.airportCode;
        request.HotelCode = this.state.hotelCode;
        request.AssignHotelShuttleTransporter = assignHotelShuttleTransporter;
        request.AssignHotelShuttleTranspoterLayover =
          assignHotelShuttleTranspoterLayover;
        request.IsCockpitCrewMaleRoomSharing =
          this.state.isCockpitCrewMaleRoomSharing;
        request.IsCockpitCrewFemaleRoomSharing =
          this.state.isCockpitCrewFemaleRoomSharing;
        request.IsCabinCrewMaleRoomSharing =
          this.state.isCabinCrewMaleRoomSharing;
        request.IsCabinCrewFemaleRoomSharing =
          this.state.isCabinCrewFemaleRoomSharing;
        request.CockpitMaleCrewPerRoom =
          this.state.roomSharingCockpitCrewMaleCount;
        request.CockpitFemaleCrewPerRoom =
          this.state.roomSharingCockpitCrewFemaleCount;
        request.CabinMaleCrewPerRoom = this.state.roomSharingCabinCrewMaleCount;
        request.CabinFemaleCrewPerRoom =
          this.state.roomSharingCabinCrewFemaleCount;
        request.CockpitMaleAllowToBookHotel =
          this.state.cabinCockpitMaleAllowBookHotel;
        request.CockpitFemaleAllowToBookHotel =
          this.state.cabinCockpitFemaleAllowBookHotel;
        request.CabinMaleAllowToBookHotel =
          this.state.cabinCrewMaleAllowBookHotel;
        request.CabinFemaleAllowToBookHotel =
          this.state.cabinCrewFemaleAllowBookHotel;
        request.CancelHotelBeforeMinutes = this.state.cancelHotelBeforeMinutes;
        request.UserId = parseInt(sessionStorage.getItem("session_userId"));
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}Configuration/AddUpdateTransportConfiguration`,
          requestParams
        );
        const data = await response.json();
        debugger;
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? "Transport configuration has been set successfully."
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.handleOnCloseModal();
              await this.getTransportConfiguration();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleOnSubmit function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    } finally {
      this.setState({ isSubmitted: false });
    }
  };
  isFormValid = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.airport = "";
      errors.hotel = "";
      if (this.state.airportCode.trim() === "") {
        formIsValid = false;
        errors.airport = "Please select airport.";
      }
      if (this.state.hotelCode.trim() === "") {
        formIsValid = false;
        errors.hotel = "Please select hotel.";
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".isFormValid function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };
  handleOnActivateDeactivateTransConfig = async (e, data, isActive) => {
    e.preventDefault();
    try {
      let configId = data.id;
      let msg = `Are you sure want to ${
        isActive ? "Activate" : "Deactivate"
      } this configuration`;
      let title = isActive ? "Activate" : "Deactivate";
      let isConfirm = await this.isConfirm("status", msg, title);
      if (isConfirm) {
        var request = {};
        request.isActive = isActive;
        request.UserId = parseInt(sessionStorage.getItem("session_userId"));
        const requestParams = getRequestParams("PUT", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}Configuration/${configId}/update-status`,
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Transport configuration has been set ${title} successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.getTransportConfiguration();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnActivateDeactivateTransConfig function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleOnDeleteTransConfig = async (event, data) => {
    event.preventDefault();
    try {
      let configId = data.id;
      let isConfirm = await this.isConfirm("delete", "", "Delete");
      if (isConfirm) {
        const requestParams = getRequestParams("DELETE");
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}Configuration/${configId}/delete`,
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Transport configuration has been set deleted successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.getTransportConfiguration();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleOnDeleteTransConfig function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  isConfirm = async (type, msg, title) => {
    let isResult = false;
    let confirmMessage = "";
    if (type === "delete") {
      if (msg !== "") {
        confirmMessage =
          "Are you sure want to delete this configuration <b>" + msg + "</b>?";
      } else {
        confirmMessage = "Are you sure want to delete this configuration?";
      }
    } else if (type === "status") {
      confirmMessage = msg;
    } else {
      confirmMessage =
        "Are you sure want to " +
        (type === "add" ? "Add" : "Update") +
        " this configuration?";
    }
    await Swal.fire({
      title: title,
      html: "<small>" + confirmMessage + "</small>",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText:
        type === "delete" ? "Delete" : type === "add" ? "Add" : title,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isResult = true;
      }
    });
    return isResult;
  };
  handleOnCellPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.isActive === false && e.column.dataField !== "id") {
        if (
          e.column.dataField !== "pilotCrewPerRoom" &&
          e.column.dataField !== "cabinCrewPerRoom" &&
          e.column.dataField !== "hotelName"
        ) {
          e.cellElement.style.backgroundColor = "red";
        } else {
          e.cellElement.bgColor = "#FFFFE0";
          //e.cellElement.style.color = "red";
        }
      }
      if (e.column.dataField === "hotelName") {
        if (
          e.data.bookingHotelCode === null ||
          e.data.bookingHotelCode === ""
        ) {
          e.cellElement.bgColor = "#FFCCCB";
        }
      }
    }
  };
  render() {
    const renderGridCell = (data) => {
      let editLink = (
        <a
          href="#"
          title="Edit transport configuration"
          onClick={(e) => {
            this.handleOnEditTransportConfiguration(e, data.data);
          }}
        >
          <i className="fas fa-edit"></i>
        </a>
      );
      let viewLink = (
        <a
          href="#"
          title="View"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnViewReadOnlyTransportConfiguration(e, data.data);
          }}
        >
          <i className="fas fa-eye"></i>
        </a>
      );
      let inActiveLink = data.data.isActive ? (
        <a
          href="#"
          title="Deactivate"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnActivateDeactivateTransConfig(e, data.data, false);
          }}
        >
          <i className="fas fa-toggle-on"></i>
        </a>
      ) : (
        ""
      );
      let activeLink = !data.data.isActive ? (
        <a
          href="#"
          title="Activate"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnActivateDeactivateTransConfig(e, data.data, true);
          }}
        >
          <i className="text-danger fas fa-toggle-off"></i>
        </a>
      ) : (
        ""
      );
      return (
        <span style={{ textAlign: "left", width: "auto" }}>
          {editLink}
          {viewLink}
          {inActiveLink === "" || inActiveLink}
          {activeLink === "" || activeLink}
          <a
            href="#"
            title="Delete"
            style={{ paddingLeft: "6px" }}
            onClick={(e) => {
              this.handleOnDeleteTransConfig(e, data.data);
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </a>
        </span>
      );
    };
    const renderPilotCrewPerRoomGridCell = (data) => {
      return (
        <div>
          <span
            style={{
              color: data?.data?.pilotMaleAllowToBookHotel ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            Male-{data?.data?.pilotMaleCrewPerRoom ?? 0}
          </span>
          ,{" "}
          <span
            style={{
              color: data?.data?.pilotFemaleAllowToBookHotel ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            Female-
            {data?.data?.pilotFemaleCrewPerRoom ?? 0}
          </span>
        </div>
      );
    };
    const renderCabinCrewPerRoomGridCell = (data) => {
      return (
        <div>
          <span
            style={{
              color: data?.data?.cabinMaleAllowToBookHotel ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            Male-{data?.data?.cabinMaleCrewPerRoom ?? 0}
          </span>
          ,{" "}
          <span
            style={{
              color: data?.data?.cabinFemaleAllowToBookHotel ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            Female-
            {data?.data?.cabinFemaleCrewPerRoom ?? 0}
          </span>
        </div>
      );
    };
    const renderTransporterCellHeader = () => {
      return (
        <div>
          Hotel Shuttle <br />
          Transpoter
        </div>
      );
    };
    const renderLayoverCellHeader = () => {
      return (
        <div>
          Hotel Shuttle <br />
          Transpoter Layover
        </div>
      );
    };
    const renderCancelHotelCellHeader = () => {
      return (
        <div>
          <center>
            Cancel Hotel <br />
            Before Minutes
          </center>
        </div>
      );
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.transportConfigGridDataSource}
              showBorders={true}
              columnAutoWidth={true}
              allowColumnResizing={true}
              showRowLines={true}
              showColumnLines={true}
              onRowPrepared={this.handleRowPrepared}
              onToolbarPreparing={this.onToolbarPreparing}
              onCellPrepared={this.handleOnCellPrepared}
            >
              <Column
                dataField="id"
                caption=""
                allowSorting={false}
                allowSearch={false}
                allowExporting={false}
                allowFiltering={false}
                width={"auto"}
                alignment={"left"}
                fixed={true}
                cellRender={renderGridCell}
              />
              <Column
                dataField="airlineCode"
                caption="Airline"
                allowFiltering={false}
                allowSearch={true}
                width={80}
              />
              <Column
                dataField="airportCode"
                caption="Airport"
                allowFiltering={false}
                allowSearch={true}
                width={80}
              />
              <Column
                dataField="hotelName"
                caption="Hotel"
                allowFiltering={false}
                allowSearch={true}
                width={230}
              />
              <Column
                dataField="assignHotelShuttleTransporter"
                allowFiltering={false}
                allowSearch={true}
                width={150}
                headerCellRender={renderTransporterCellHeader}
              />
              <Column
                dataField="assignHotelShuttleTranspoterLayover"
                allowFiltering={false}
                allowSearch={true}
                width={150}
                headerCellRender={renderLayoverCellHeader}
              />
              <Column
                dataField="cancelHotelBeforeMinutes"
                allowFiltering={false}
                allowSearch={true}
                width={110}
                alignment={"center"}
                headerCellRender={renderCancelHotelCellHeader}
              />
              <Column
                dataField="pilotCrewPerRoom"
                caption="Cockpit Crew Room(s)"
                allowFiltering={false}
                allowSearch={true}
                width={150}
                cellRender={renderPilotCrewPerRoomGridCell}
              />
              <Column
                dataField="cabinCrewPerRoom"
                caption=" Cabin Crew Room(s)"
                allowFiltering={false}
                allowSearch={true}
                width={150}
                cellRender={renderCabinCrewPerRoomGridCell}
              />
              <Column
                dataField="hotelStayCrewType"
                caption="Hotel Stay For"
                allowFiltering={false}
                allowSearch={true}
                width={110}
              />
              <Column
                dataField="activeStatus"
                caption="Status"
                allowFiltering={false}
                allowSearch={true}
                width={80}
              />
              <GroupPanel visible={false} />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Hotel Transport Configuration"
              />
            </DataGrid>
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.showAddEditModel}
          style={{ content: { width: "55%" } }}
          className={"react-modal"}
          onAfterOpen={this.handleOnAfterOpenConfigModal}
          onRequestClose={this.handleOnCloseModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              Transport Configuration{" "}
              {this.state.viewReadOnly ? "(Read only)" : ""}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleOnCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Select Airport</label>
                  <SelectBox
                    dataSource={this.state.airportDropdownList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="ddlairport"
                    defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleOnAirportDropdownChange}
                    showClearButton={true}
                    searchEnabled={true}
                    disabled={this.state.viewReadOnly}
                  />
                  {this.state.errors["airport"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["airport"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <label>Please select stay hotel</label>
                  <SelectBox
                    dataSource={this.state.hotelDropdownList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Hotel"
                    name="ddlhotel"
                    defaultValue={0}
                    value={this.state.hotelCode}
                    displayExpr="hotelName"
                    valueExpr="hotelCode"
                    onSelectionChanged={this.handleOnHotelDropdownChange}
                    showClearButton={true}
                    searchEnabled={true}
                    disabled={this.state.viewReadOnly}
                  />
                  {this.state.errors["hotel"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["hotel"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <div className="form-group">
                <label>Hotel Shuttle</label>
                <div>
                  <button
                    type="button"
                    className={
                      this.state.includeHotelShuttle
                        ? "btn btn-outline-secondary"
                        : "btn btn-primary"
                    }
                    onClick={() => {
                      this.setState({ includeHotelShuttle: false });
                    }}
                    disabled={this.state.viewReadOnly}
                  >
                    <span title="Excluded Hotel Shuttle">
                      <i
                        className={
                          this.state.includeHotelShuttle ? "" : "fas fa-check"
                        }
                      ></i>{" "}
                      Excluded
                    </span>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className={
                      this.state.includeHotelShuttle
                        ? "btn btn-primary"
                        : "btn btn-outline-secondary"
                    }
                    onClick={() => {
                      this.setState({ includeHotelShuttle: true });
                    }}
                    disabled={this.state.viewReadOnly}
                  >
                    <span title="Included Hotel Shuttle">
                      <i
                        className={
                          this.state.includeHotelShuttle ? "fas fa-check" : ""
                        }
                      ></i>{" "}
                      Included
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {this.state.includeHotelShuttle === true && (
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <RadioGroup
                    id="radio-group-with-selection"
                    items={this.state.hotelTransportConfigType}
                    value={this.state.hotelTransportConfigTypeId}
                    valueExpr="id"
                    displayExpr="text"
                    onValueChanged={(e) => {
                      this.setState({ hotelTransportConfigTypeId: e.value });
                    }}
                    disabled={this.state.viewReadOnly}
                  />
                </div>
              </div>
            )}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="dx-widget dx-collection">
                <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                  Hotel Stay for
                </span>
                <div className="dx-field">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hotelStayCabinCrew"
                      name="hotelStayCabinCrew"
                      checked={this.state.hotelStayCrewTypeId === 2}
                      onChange={this.handleInputChange}
                      disabled={this.state.viewReadOnly}
                    />
                    <label
                      className=""
                      htmlFor="hotelStayCabinCrew"
                      style={{ fontSize: "inherit" }}
                    >
                      &nbsp;Cabin Crew
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="hotelStayCockpitCrew"
                      name="hotelStayCockpitCrew"
                      checked={this.state.hotelStayCrewTypeId === 1}
                      onChange={this.handleInputChange}
                      disabled={this.state.viewReadOnly}
                    />
                    <label
                      className=""
                      htmlFor="hotelStayCockpitCrew"
                      style={{ fontSize: "inherit" }}
                    >
                      &nbsp;Cockpit crew
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="HotelStayCrewTypeBoth"
                      name="HotelStayCrewTypeBoth"
                      checked={this.state.hotelStayCrewTypeId === 3}
                      onChange={this.handleInputChange}
                      disabled={this.state.viewReadOnly}
                    />
                    <label
                      className=""
                      htmlFor="HotelStayCrewTypeBoth"
                      style={{ fontSize: "inherit" }}
                    >
                      &nbsp;Both
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <span style={{ fontSize: 1 + "em", fontWeight: "bold" }}>
                  Hotel Stay Crew Room Sharing Configuration
                </span>{" "}
                <i>(Only selected crew type setting will be affected )</i>
              </div>
            </div>
            {(this.state.hotelStayCrewTypeId === 2 ||
              this.state.hotelStayCrewTypeId === 3) && (
              <div className="row">
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <div className="dx-field">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cabinCrewMaleRoomSharing"
                        checked={this.state.isCabinCrewMaleRoomSharing}
                        onChange={(e) => {
                          this.setState({
                            isCabinCrewMaleRoomSharing: e.target.checked,
                            roomSharingCabinCrewMaleCount: 1,
                            cabinCrewMaleAllowBookHotel: false,
                          });
                        }}
                        disabled={this.state.viewReadOnly}
                      />
                      <label
                        htmlFor="cabinCrewMaleRoomSharing"
                        style={{ fontSize: "inherit" }}
                      >
                        Cabin Male
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.isCabinCrewMaleRoomSharing === true &&
                  (this.state.hotelStayCrewTypeId === 2 ||
                    this.state.hotelStayCrewTypeId === 3) && (
                    <>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="form-group">
                          <SelectBox
                            dataSource={this.state.crewPerRoomList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Room"
                            name="ddlCabinCrewMaleRoomSharingCount"
                            defaultValue={0}
                            value={this.state.roomSharingCabinCrewMaleCount}
                            displayExpr="name"
                            valueExpr="id"
                            onSelectionChanged={
                              this.handleOnCabinCrewMaleRoomSharingChange
                            }
                            showClearButton={true}
                            searchEnabled={true}
                            disabled={this.state.viewReadOnly}
                          />
                        </div>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="dx-field">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="cabinCrewMaleAllowBookHotel"
                              checked={this.state.cabinCrewMaleAllowBookHotel}
                              onChange={(e) => {
                                this.setState({
                                  cabinCrewMaleAllowBookHotel: e.target.checked,
                                });
                              }}
                              disabled={this.state.viewReadOnly}
                            />
                            <label
                              htmlFor="cabinCrewMaleAllowBookHotel"
                              style={{ fontSize: "inherit" }}
                            >
                              Book Hotel
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <div className="dx-field">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cabinCrewFemaleRoomSharing"
                        checked={this.state.isCabinCrewFemaleRoomSharing}
                        onChange={(e) => {
                          this.setState({
                            isCabinCrewFemaleRoomSharing: e.target.checked,
                            roomSharingCabinCrewFemaleCount: 1,
                            cabinCrewFemaleAllowBookHotel: false,
                          });
                        }}
                        disabled={this.state.viewReadOnly}
                      />
                      <label
                        htmlFor="cabinCrewFemaleRoomSharing"
                        style={{ fontSize: "inherit" }}
                      >
                        Cabin Female
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.isCabinCrewFemaleRoomSharing === true &&
                  (this.state.hotelStayCrewTypeId === 2 ||
                    this.state.hotelStayCrewTypeId === 3) && (
                    <>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="form-group">
                          <SelectBox
                            dataSource={this.state.crewPerRoomList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Room"
                            name="ddlCabinCrewFemaleRoomSharingCount"
                            defaultValue={0}
                            value={this.state.roomSharingCabinCrewFemaleCount}
                            displayExpr="name"
                            valueExpr="id"
                            onSelectionChanged={
                              this.handleOnCabinCrewFemaleRoomSharingChange
                            }
                            showClearButton={true}
                            searchEnabled={true}
                            disabled={this.state.viewReadOnly}
                          />
                        </div>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="dx-field">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="cabinCrewFemaleAllowBookHotel"
                              checked={this.state.cabinCrewFemaleAllowBookHotel}
                              onChange={(e) => {
                                this.setState({
                                  cabinCrewFemaleAllowBookHotel:
                                    e.target.checked,
                                });
                              }}
                              disabled={this.state.viewReadOnly}
                            />
                            <label
                              htmlFor="cabinCrewFemaleAllowBookHotel"
                              style={{ fontSize: "inherit" }}
                            >
                              Book Hotel
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}
            {(this.state.hotelStayCrewTypeId === 1 ||
              this.state.hotelStayCrewTypeId === 3) && (
              <div className="row">
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <div className="dx-field">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cockpitCrewMaleRoomSharing"
                        checked={this.state.isCockpitCrewMaleRoomSharing}
                        onChange={(e) => {
                          this.setState({
                            isCockpitCrewMaleRoomSharing: e.target.checked,
                            roomSharingCockpitCrewMaleCount: 1,
                            cabinCockpitMaleAllowBookHotel: false,
                          });
                        }}
                        disabled={this.state.viewReadOnly}
                      />
                      <label
                        htmlFor="cockpitCrewMaleRoomSharing"
                        style={{ fontSize: "inherit" }}
                      >
                        Cockpit Male
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.isCockpitCrewMaleRoomSharing === true &&
                  (this.state.hotelStayCrewTypeId === 1 ||
                    this.state.hotelStayCrewTypeId === 3) && (
                    <>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="form-group">
                          <SelectBox
                            dataSource={this.state.crewPerRoomList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Room"
                            name="ddlCockpitCrewMaleRoomSharingCount"
                            defaultValue={0}
                            value={this.state.roomSharingCockpitCrewMaleCount}
                            displayExpr="name"
                            valueExpr="id"
                            onSelectionChanged={
                              this.handleOnCockpitCrewMaleRoomSharingChange
                            }
                            showClearButton={true}
                            searchEnabled={true}
                            disabled={this.state.viewReadOnly}
                          />
                        </div>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="dx-field">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="cabinCockpitMaleAllowBookHotel"
                              checked={
                                this.state.cabinCockpitMaleAllowBookHotel
                              }
                              onChange={(e) => {
                                this.setState({
                                  cabinCockpitMaleAllowBookHotel:
                                    e.target.checked,
                                });
                              }}
                              disabled={this.state.viewReadOnly}
                            />
                            <label
                              htmlFor="cabinCockpitMaleAllowBookHotel"
                              style={{ fontSize: "inherit" }}
                            >
                              Book Hotel
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <div className="dx-field">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cockpitCrewFemaleRoomSharing"
                        checked={this.state.isCockpitCrewFemaleRoomSharing}
                        onChange={(e) => {
                          this.setState({
                            isCockpitCrewFemaleRoomSharing: e.target.checked,
                            roomSharingCockpitCrewFemaleCount: 1,
                            cabinCockpitFemaleAllowBookHotel: false,
                          });
                        }}
                        disabled={this.state.viewReadOnly}
                      />
                      <label
                        htmlFor="cockpitCrewFemaleRoomSharing"
                        style={{ fontSize: "inherit" }}
                      >
                        Cockpit Female
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.isCockpitCrewFemaleRoomSharing === true &&
                  (this.state.hotelStayCrewTypeId === 1 ||
                    this.state.hotelStayCrewTypeId === 3) && (
                    <>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="form-group">
                          <SelectBox
                            dataSource={this.state.crewPerRoomList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Room"
                            name="ddlCockpitCrewFemaleRoomSharingCount"
                            defaultValue={0}
                            value={this.state.roomSharingCockpitCrewFemaleCount}
                            displayExpr="name"
                            valueExpr="id"
                            onSelectionChanged={
                              this.handleOnCockpitCrewFemaleRoomSharingChange
                            }
                            showClearButton={true}
                            searchEnabled={true}
                            disabled={this.state.viewReadOnly}
                          />
                        </div>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <div className="dx-field">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="cabinCockpitFemaleAllowBookHotel"
                              disabled={this.state.viewReadOnly}
                              checked={
                                this.state.cabinCockpitFemaleAllowBookHotel
                              }
                              onChange={(e) => {
                                this.setState({
                                  cabinCockpitFemaleAllowBookHotel:
                                    e.target.checked,
                                });
                              }}
                            />
                            <label
                              htmlFor="cabinCockpitFemaleAllowBookHotel"
                              style={{ fontSize: "inherit" }}
                            >
                              Book Hotel
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-inline">
                  <div className="form-group">
                    <span style={{ fontSize: 1 + "em", fontWeight: "bold" }}>
                      Allow crew to cancel hotel stay before
                    </span>
                    <input
                      type="text"
                      className="form-control mx-sm-1 form-control-sm"
                      style={{ width: 60 + "px" }}
                      name="cancelHotelBeforeMinutes"
                      width="25px"
                      title="Cancel hotel before minutes"
                      placeholder="Cancel hotel before minutes"
                      maxLength={5}
                      value={Number(this.state.cancelHotelBeforeMinutes)}
                      onChange={this.handleInputChange}
                      disabled={this.state.viewReadOnly}
                    />
                    <small id="" className="text-muted">
                      Minute
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.state.viewReadOnly}
              onClick={async (e) => {
                await this.handleOnSubmit(e);
              }}
            >
              {this.state.isSubmitted && (
                <span title="In-process">
                  <i className="fas fa-spinner fa-spin"></i> In-process
                </span>
              )}
              {!this.state.isSubmitted && (
                <span title="Save Configuration">
                  <i className="fas fa-check"></i> Save Configuration
                </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-custom-gray "
              title="Close"
              onClick={this.handleOnCloseModal}
            >
              <i className="fas fa-times"></i> Close
            </button>
          </div>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
