import React, { Component } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Template } from "devextreme-react/core/template";
import Modal from "react-modal";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import { LoadPanel, RadioGroup, SelectBox } from "devextreme-react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { DateFormat } from "../../helpers/fixcodes.js";

const position = { of: "#historydiv" };

const CrewTypeCategoryLst = [
  { id: 1, code: "P", name: "Cockpit" },
  { id: 2, code: "F", name: "Cabin Crew" },
  { id: 0, code: "", name: "Both" },
];

export class CrewTypeTransportConfigurationComponent extends Component {
  static displayName = CrewTypeTransportConfigurationComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      isSubmitted: false,
      showCrewTypeTransportModal: false,
      viewReadOnly: false,
      airportCode: "",
      transportId: 0,
      airportListDropdown: [],
      transporterListDropdown: [],
      transporterGridDataSource: [],
      crewTypeCategoryDataSource: [],
      crewTypeCategoryCode: "",
      crewTypeConfigData: null,
      errors: {
        airportCode: "",
        transport: "",
        crewTypeCategoryCode: "",
      },
    };
  }

  async componentDidMount() {
    this.setState({
      crewTypeCategoryDataSource: CrewTypeCategoryLst,
    });
    await this.getCrewTypeTransportConfiguration();
  }

  getCrewTypeTransportConfiguration = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}CrewTypeConfiguration/CrewTypeTransporterList/`,
        requestParams
      );
      const data = await response.json();
      this.setState({
        transporterGridDataSource: data.payload !== null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".getCrewTypeTransportConfiguration function",
        err
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getCrewTypeTransportConfiguration.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };

  toolbarItemRender = () => {
    return (
      <>
        <div className="row">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleAddCrewTypeTransportConfiguration}
          >
            <span title="Submit">
              <i className="fas fa-plus"></i> Add Configuration
            </span>
          </button>
        </div>
      </>
    );
  };

  getAirports = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Airport/AirportDropdown/" +
          airlineCode +
          "/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportListDropdown: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  handleAirportChange = async (e) => {
    try {
      let airportCode = e.value ?? "";
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      this.setState({
        airportCode: airportCode,
      });
      if (airportCode) {
        await this.getTransporterDropdown(airlineCode, airportCode);
      } else {
        this.setState({
          transporterListDropdown: [],
          transportId: 0,
        });
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleAirportChange function",
        error
      );
    }
  };

  getTransporterDropdown = async (airlineCode, airportCode) => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      var request = {};
      request.AirlineCode = airlineCode;
      request.AirportCode = airportCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "CrewTypeConfiguration/TransporterDropdown/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        transporterListDropdown: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getTransporterDropdown function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  handleTransporterChange = async (e) => {
    try {
      this.setState({
        transportId: e.value ?? 0,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleTransporterChange function",
        error
      );
    }
  };

  handleAddCrewTypeTransportConfiguration = (e) => {
    try {
      this.setState({
        showCrewTypeTransportModal: true,
        crewTypeCategoryCode: "",
        crewTypeConfigData: null,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleAddCrewTypeTransportConfiguration function",
        error
      );
    }
  };

  handleOnEditCrewTypeTransportConfiguration = async (e, data) => {
    try {
      if (data != null) {
        this.setState({
          showCrewTypeTransportModal: true,
          crewTypeConfigData: data,
        });
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnEditCrewTypeTransportConfiguration function",
        error
      );
    }
  };

  handleOnSyncTransportConfiguration = async (e, data) => {
    try {
      debugger;
      let crewTypeData = [];
      crewTypeData.push(data.crewType);
      let rowIndex = data.rowIndex;
      let request = {};
      request.AirlineCode = sessionStorage.getItem("session_airlineCode");
      request.AirportCode = data.airportCode;
      request.TransporterId = data.transporterId;
      request.CrewType = crewTypeData;
      request.UserId = parseInt(sessionStorage.getItem("session_userId"));
      let type = "sync";
      let title = "Sync";
      let isConfirm = await this.isConfirm(type, title);
      if (isConfirm) {
        const requestParams = getRequestParams("PUT", request);
        const url = `${process.env.REACT_APP_API_BASE_URL}CrewTypeConfiguration/UpdateCrewTypeTransportConfiguration/${rowIndex}`;
        debugger;
        const response = await fetch(url, requestParams);
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? "Crew Type Transport configuration has been set successfully."
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.getCrewTypeTransportConfiguration();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleOnSubmit function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    } finally {
      this.setState({ isSubmitted: false });
    }
  };

  handleOnViewReadOnlyCrewTypeTransportConfiguration = async (e, data) => {
    try {
      if (data != null) {
        this.setState({
          showCrewTypeTransportModal: true,
          viewReadOnly: true,
          crewTypeConfigData: data,
        });
      }
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnEditCrewTypeTransportConfiguration function",
        error
      );
    }
  };

  handleOnAfterOpenCrewTypeTransportModal = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let configData = this.state.crewTypeConfigData;
      if (configData !== null) {
        this.setState({
          airportCode: configData.airportCode,
          transportId: configData.transporterId,
          crewTypeCategoryCode: configData.crewType,
          crewTypeCategoryDataSource: CrewTypeCategoryLst.filter((f) => f.code),
        });
        await this.getTransporterDropdown(airlineCode, configData.airportCode);
      } else {
        this.setState({
          crewTypeCategoryDataSource: CrewTypeCategoryLst,
        });
      }
      await this.getAirports();
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAfterOpenCrewTypeTransportModal function",
        error
      );
    } finally {
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  handleOnCloseCrewTypeTransportModal = () => {
    try {
      let error = this.state.errors;
      error.airportCode = "";
      error.transport = "";
      error.crewTypeCategoryCode = "";
      this.setState({
        showCrewTypeTransportModal: false,
        airportListDropdown: [],
        transporterListDropdown: [],
        crewTypeCategoryCode: "",
        airportCode: "",
        transportId: 0,
        crewTypeConfigData: null,
        viewReadOnly: false,
        errors: error,
      });
    } catch (error) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnCloseCrewTypeTransportModal function",
        error
      );
    }
  };

  changeSelectionCrewType = (e) => {
    try {
      let crewTypeData = e.value ?? "";
      this.setState({
        crewTypeCategoryCode: crewTypeData,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".changeSelectionCrewType function",
        error
      );
    }
  };

  isFormValid = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.airportCode = "";
      errors.transport = "";
      errors.crewTypeCategoryCode = "";
      if (!this.state.airportCode) {
        formIsValid = false;
        errors.airportCode = "Please select airport.";
      }
      if (this.state.transportId === 0) {
        formIsValid = false;
        errors.transport = "Please select transporter.";
      }
      if (this.state.crewTypeConfigData !== null) {
        if (!this.state.crewTypeCategoryCode) {
          formIsValid = false;
          errors.crewTypeCategoryCode = "Please select crew type.";
        }
      }
      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".isFormValid function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };

  isConfirm = async (type, title) => {
    let isResult = false;
    let confirmMessage = "";
    if (type === "add") {
      confirmMessage = "Are you sure want to add new crew type configuration?";
    } else if (type === "sync") {
      confirmMessage = "Are you sure want to sync transport details?";
    } else {
      confirmMessage =
        "Are you sure want to update this crew type configuration?";
    }
    await Swal.fire({
      title: title,
      html: "<strong>" + confirmMessage + "</strong>",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText:
        type === "add"
          ? "Add"
          : type === "update"
          ? "Update"
            ? type === "sync"
            : "Sync"
          : title,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isResult = true;
      }
    });
    return isResult;
  };

  handleOnSubmit = async (e) => {
    //e.preventDefault();
    try {
      if (this.isFormValid()) {
        let crewTypeData = [];

        this.setState({ isSubmitted: true });
        if (this.state.crewTypeConfigData !== null) {
          crewTypeData.push(this.state.crewTypeCategoryCode);
        } else {
          if (this.state.crewTypeCategoryCode) {
            crewTypeData = CrewTypeCategoryLst?.filter(
              (f) => f.code === this.state.crewTypeCategoryCode
            ).map((m) => m.code);
          } else {
            crewTypeData = this.state.crewTypeCategoryDataSource
              ?.filter((f) => f.code !== "")
              .map((m) => m.code);
          }
        }
        let request = {};
        request.AirlineCode = sessionStorage.getItem("session_airlineCode");
        request.AirportCode = this.state.airportCode;
        request.TransporterId = this.state.transportId;
        request.CrewType = crewTypeData;
        request.UserId = parseInt(sessionStorage.getItem("session_userId"));
        let type = "add";
        let title = "Add";
        let isConfirm = await this.isConfirm(type, title);
        if (isConfirm) {
          const requestParams = getRequestParams("POST", request);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}CrewTypeConfiguration/AddCrewTypeTransportConfiguration/`,
            requestParams
          );
          const data = await response.json();
          if (data.isSuccess) {
            await Swal.fire({
              allowOutsideClick: false,
              icon: data.isSuccess ? "success" : "error",
              title: data.isSuccess ? "Success" : "Oops...",
              text:
                data.isSuccess === false &&
                (data.message === null || data.message === "")
                  ? "Unable to process the request."
                  : data.message === null || data.message === ""
                  ? "Crew Type Transport configuration has been set successfully."
                  : data.message,
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.handleOnCloseCrewTypeTransportModal();
                await this.getCrewTypeTransportConfiguration();
              }
            });
          } else {
            toast.error(
              data.message === null || data.message === ""
                ? "Unable to process the request."
                : data.message,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: "310px" },
                hideProgressBar: false,
                newestOnTop: false,
                closeButton: false,
              }
            );
          }
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleOnSubmit function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    } finally {
      this.setState({ isSubmitted: false });
    }
  };

  render() {
    const renderGridCell = (data) => {
      let editLink = (
        <a
          title="Edit Crew Type transport configuration"
          onClick={(e) => {
            this.handleOnEditCrewTypeTransportConfiguration(e, data.data);
          }}
        >
          <i className="fas fa-edit"></i>
        </a>
      );
      let syncTransportConfiguration = (
        <a
          title="Sync transport configuration"
          onClick={(e) => {
            this.handleOnSyncTransportConfiguration(e, data.data);
          }}
        >
          <i class="fas fa-exchange-alt fa-rotate-90"></i>
        </a>
      );
      let viewLink = (
        <a
          title="View"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnViewReadOnlyCrewTypeTransportConfiguration(
              e,
              data.data
            );
          }}
        >
          <i className="fas fa-eye"></i>
        </a>
      );
      return (
        <span style={{ textAlign: "left", width: "auto" }}>
          {/* {editLink} */}
          {syncTransportConfiguration}
          {/* {viewLink} */}
        </span>
      );
    };
    return (
      <>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.transporterGridDataSource}
              columnAutoWidth={true}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Column
                dataField="rowIndex"
                width={"auto"}
                caption=""
                allowFiltering={false}
                allowSorting={false}
                alignment={"left"}
                cellRender={renderGridCell}
              />
              <Column
                dataField="airlineCode"
                caption="Airline"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="airportCode"
                caption="Airport"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="crewTypeCategory"
                caption="Crew Type Stay For"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="transporterName"
                caption="Transport"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="transporterEmail"
                caption="Email"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="transporterPhoneNo"
                caption="Phone"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="singleTripVehicleType"
                caption="Single Trip Vehicle Type"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="pilotVehicleType"
                caption="Pilot Vehicle Type"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="flightAttendantVehicleType"
                caption="Flight Attendant Vehicle Type"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                dataField="effectedDate"
                caption="Effected Date"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
                dataType="datetime"
                format={DateFormat.DateTime24NorthFormat}
              />
              <GroupPanel visible={false} />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Crew Type Transport Configuration"
              />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
            </DataGrid>
          </div>
        </div>

        {/* Add/Edit/ReadOnly Crew Type Transport Configuration */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showCrewTypeTransportModal}
          style={{ content: { width: "40%" } }}
          className={"react-modal"}
          onAfterOpen={this.handleOnAfterOpenCrewTypeTransportModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              Crew Type Transport Configuration
              {this.state.viewReadOnly ? "(View only)" : ""}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleOnCloseCrewTypeTransportModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <span>Airport</span>
                  <SelectBox
                    dataSource={this.state.airportListDropdown}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportCode"
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onValueChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={this.state.viewReadOnly}
                    searchEnabled={true}
                  />
                  {this.state.errors["airportCode"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["airportCode"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <span>Transporter</span>
                  <SelectBox
                    dataSource={this.state.transporterListDropdown}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Transporter"
                    name="transporterName"
                    defaultValue={0}
                    value={this.state.transportId}
                    displayExpr="transporterName"
                    valueExpr="transporterId"
                    onValueChanged={this.handleTransporterChange}
                    showClearButton={true}
                    disabled={this.state.viewReadOnly}
                    searchEnabled={true}
                  />
                  {this.state.errors["transport"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["transport"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="form-group">
                  <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                    Transport For
                  </span>
                  <RadioGroup
                    id="radio-group-with-selection"
                    dataSource={this.state.crewTypeCategoryDataSource}
                    layout="horizontal"
                    value={this.state.crewTypeCategoryCode}
                    valueExpr="code"
                    displayExpr="name"
                    disabled={this.state.viewReadOnly}
                    onValueChanged={this.changeSelectionCrewType}
                  />
                  {this.state.errors["crewTypeCategoryCode"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["crewTypeCategoryCode"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              hidden={this.state.viewReadOnly}
              onClick={(e) => {
                this.handleOnSubmit(e);
              }}
            >
              {this.state.isSubmitted && (
                <span title="In-process">
                  <i className="fas fa-spinner fa-spin"></i> In-process
                </span>
              )}
              {!this.state.isSubmitted && (
                <span title="Save Configuration">
                  <i className="fas fa-check"></i> Save Configuration
                </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-custom-gray "
              title="Close"
              onClick={this.handleOnCloseCrewTypeTransportModal}
            >
              <i className="fas fa-times"></i> Close
            </button>
          </div>
        </Modal>

        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </>
    );
  }
}
