export const DateFormat = {
  DateTime24WesternFormat: "MM/dd/yyyy HH:mm",
  DateTime12WesternFormat: "MM/dd/yyyy hh:mm",
  DateTime24WesternLongFormat: "MM/dd/yyyy HH:mm:ss",
  DateWesternFormat: "MM/dd/yyyy",

  DateTime24NorthFormat: "dd/MM/yyyy HH:mm",
  DateTime12NorthFormat: "dd/MM/yyyy hh:mm",
  DateTime24NorthLongFormat: "dd/MM/yyyy HH:mm:ss",
  DateNorthFormat: "dd/MM/yyyy",

  Time24Format: "HH:mm",
  Time12Format: "hh:mm",
  Time24FormatLong: "HH:mm:ss",
  Time24FormatLongAMPM: "hh:mm:ss a",
};

export const ApplicationType = {
  UTPro: 12,
};
export const FeatureActionType = {
  UTWiz: 9,
};
export const ParentAccount = {
  UTWiz: 1,
};
export const ModuleCode = {
  Self: 1,
  TourOperator: 2,
  CommissionAgent: 3,
  Affiliate: 4,
  TravelAgent: 5,
  Crew: 6,
  Customer: 7,
  Hotel: 8,
  HotelAggregator: 9,
};
export const CustomerType = {
  AirlineCrew: 1,
  CustomerService: 2,
  Charter: 3,
  Contracts: 4,
  Hotel: 5,
  VoucherTourOperator: 6,
  TravelAgents: 7,
  Affiliates: 8,
};
export const IndustryType = {
  DAIRY: "DAIRY",
  EMERGENCYSERVICE: "EMERGENCYSERVICE",
  GENERALSERVICE: "GENERALSERVICE",
};
export const TransferType = {
  Dairy: 3,
};
export const ServiceType = {
  MilkCollection: 5,
  DairyTransfer: 6,
  InterState: 7,
};
export const DairyTripStatus = {
  CREATED: 901,
  OPEN: 902,
  STARTED: 903,
  COMPLETED: 904,
  CANCELLED: 906,
};
export const BillingStatus = {
  APPROVEDBYCREW: 2001,
  APPROVEDBYSCHEDULINGDEPT: 2002,
  APPROVEDBYTRANSPORTDEPT: 2003,
  APPROVEDONBEHALFOFCREW: 2004,
  UNAPPROVEDBYCREW: 2005,
};

export const symbolFormats = {
  ruppesFormat: "fas fa-rupee-sign",
  dollarFormat: "fas fa-dollar-sign",
};

export const precisionFormats = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
};

export const currencySymbolFormats = {
  currencyFormat: "fas fa-rupee-sign",
};
export const RegEx = {
  MobileNumber: /^\d{10}$/,
  Numeric: /^[0-9\b]+$/,
  AlphaNumeric: /^[A-Za-z0-9-]*$/,
  Decimal2Digit: /^\d*(\.\d{0,2})?$/,
  DecimalRulePattern2Digit: /^\\d{0,5}(\\.{0,1})\\d{0,2}$/,
  Mobile: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  Email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PanNumber: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  AadharNumber: /^\d{12}$/,
  LatLong: /^[-+0-9.]+$/,
};
export const StatusType = {
  Booking: 1,
  Invoice: 2,
  Vehicle: 3,
  Licence: 4,
  Quotation: 5,
};

export const TripStatus = {
  PENDING: 101,
  VEHICLEASSIGNED: 102,
  VEHICLEUNASSIGNED: 103,
  DRIVERACCEPTED: 104,
  DRIVERREJECTED: 105,
  ONHIRE: 106,
  ONLOCATION: 107,
  PAXLOAD: 108,
  NOSHOW: 109,
  NOSHOWNONBILLABLE: 110,
  CANCELLED: 111,
  ONRIDEINPROCESS: 112,
  SOONFREE: 113,
  PAXUNLOAD: 114,
  COMPLETEDBREAKDOWN: 115,
  COMPLETED: 116,
  APPROVED: 117,
  AUTOCANCELLED: 118,
  HOLD: 119,
};
