import React, { Component, Fragment } from "react";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LoadPanel } from "devextreme-react/load-panel";
import DateBox from "devextreme-react/date-box";
let currentTime =
  sessionStorage.getItem("session_locationTime") === null ||
  sessionStorage.getItem("session_locationTime") === ""
    ? new Date()
    : new Date(sessionStorage.getItem("session_locationTime"));
const position = { of: "#historydiv" };
export class ScheduleDashBoardComponent extends Component {
  static displayName = ScheduleDashBoardComponent.name;
  currentTime =
    sessionStorage.getItem("session_locationTime") === null ||
    sessionStorage.getItem("session_locationTime") === ""
      ? new Date()
      : new Date(sessionStorage.getItem("session_locationTime"));
  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      filterPickupDatetime: new Date(),
      // filterPickupToDateTime: new Date(),
      id: 0,
      airportList: [],
      airportid: 0,
      airportcodenumber: "",
      statusList: [],
      statusid: 0,
      transporterList: [],
      accountId: 0,
      transactionName: "",
      scheduleApprovalDataSource: null,
      isRegister: true,
    };
  }
  async componentDidMount() {
    this.getAirportList(); //Airport List Function
    this.getStatusList(); //Status List Function
    this.getTransporterList(); //Transporter List Function
    this.getScheduleApprovalGridList();
  }

  // Filter Pickup Date DropDown Handle Event
  handleOnFilterPickupDateChanged = (date) => {
    this.setState({ filterPickupDatetime: date.value });
  };
  // Filter Pickup Date DropDown Handle Event
  // handleOnFilterPickupToDateChanged = (date) => {
  //     this.setState({ filterPickupToDateTime: date.value });
  // }

  //Filter Airport List Fucntion
  getAirportList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportList: data.payload,
        loadPanelVisible: false,
      });
    } catch (ex) {
      throw ex;
    }
  };
  //Filter Airport List Handle Event
  handleOnfilterAirportChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        airportid:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.airportId
            : 0,
      });
      this.setState({
        airportcodenumber:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.airportCode
            : "",
      });
    } else {
      this.setState({ airportid: 0, airportcodenumber: "" });
    }
  };

  //Filter Status List Fucntion
  getStatusList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
    } catch (ex) {
      throw ex;
    }
  };
  //Filter Status List Handle Event
  handleOnfilterStatusChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        statusid:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.id
            : 0,
      });
    } else {
      this.setState({ statusid: 0 });
    }
  };

  //Filter Transporter List Fucntion
  getTransporterList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Account/transpoterAirlineAccount",
        requestParams
      );
      const data = await response.json();
      this.setState({
        transporterList: data.payload,
        loadPanelVisible: false,
      });
    } catch (ex) {
      throw ex;
    }
  };
  //Filter Transporter List Handle Event
  handleOnfilterTransporterChange = async (selectedOption) => {
    if (selectedOption.selectedItem !== null) {
      this.setState({
        accountId:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.accountId
            : 0,
      });
      this.setState({
        transactionName:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.serviceAccountName
            : "",
      });
    } else {
      this.setState({ accountId: 0, transactionName: "" });
    }
  };

  //Filter/Grid List Fucntion
  getScheduleApprovalGridList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.StartTime =
        formatDate(this.state.filterPickupDatetime, "MM/dd/yyyy") + " 00:00:01";
      request.EndTime =
        formatDate(this.state.filterPickupDatetime, "MM/dd/yyyy") + " 23:59:59";
      request.AirportCode = this.state.airportcodenumber;
      request.TransactionId = this.state.transactionName;
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Reservation",
        requestParams
      );
      const data = await response.json();
      this.setState({
        scheduleApprovalDataSource: data.payload,
        loadPanelVisible: false,
      });
    } catch (ex) {
      throw ex;
    }
  };
  handleCancel = async () => {
    this.setState({
      loadPanelVisible: false,
      filterPickupDatetime: new Date(),
      // filterPickupToDateTime: new Date(),
      id: 0,
      airportid: 0,
      airportcodenumber: "",
      statusid: 0,
      accountId: 0,
      transactionName: "",
    });
    window.location.reload(false);
  };

  render() {
    return (
      <Fragment>
        <div className="filter-box">
          <div className="card p-2 m-0">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Flight Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterPickupDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterPickupDatetime}
                    value={this.state.filterPickupDatetime}
                    onValueChanged={this.handleOnFilterPickupDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-2">
                                <div className="form-group"> */}
              {/* <label>Date</label> */}
              {/* <DateBox type="date"
                                        className="replay-date-time"
                                        name="filterPickupToDateTime"
                                        placeholder="dd/MM/yyyy"
                                        displayFormat="dd/MM/yyyy"
                                        showAnalogClock={true}
                                        useMaskBehavior={true}
                                        defaultValue={this.state.filterPickupToDateTime}
                                        value={this.state.filterPickupToDateTime}
                                        onValueChanged={this.handleOnFilterPickupToDateChanged}
                                        width="100%"
                                        disabled={false}
                                        acceptCustomValue={false}
                                    /> */}
              {/* </div>
                            </div> */}
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Airport</label>
                  <SelectBox
                    dataSource={this.state.airportList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportId"
                    defaultValue={0}
                    value={this.state.airportid}
                    displayExpr="airportCode"
                    valueExpr="airportId"
                    onSelectionChanged={this.handleOnfilterAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Transporter</label>
                  <SelectBox
                    dataSource={this.state.transporterList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Transporter"
                    name="accountId"
                    defaultValue={0}
                    value={this.state.accountId}
                    displayExpr="serviceAccountName"
                    valueExpr="accountId"
                    onSelectionChanged={this.handleOnfilterTransporterChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.getScheduleApprovalGridList}
                  >
                    <span title="Filter">
                      <i className="fas fa-filter"></i> Filter
                    </span>
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="submit"
                    className="btn btn-custom-gray "
                    onClick={this.handleCancel}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.scheduleApprovalDataSource != null ? (
          <div className="card">
            <div className="card-body">
              <div className="sch-crew">
                <div className="header">
                  <table border="0" width="100%" cellPadding="5">
                    <tbody>
                      <tr>
                        <td width="145px">Flight</td>
                        <td>Crew Status</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="body">
                  {this.state.scheduleApprovalDataSource &&
                    this.state.scheduleApprovalDataSource.map((item, index) => (
                      <div className="box" key={index} id="divSchedule">
                        <div className="info_flight" key={index} id="divflight">
                          <div className="timeandflight">
                            <span className="time">{item.flightTime}</span>
                            <span className="flightno">
                              {item.airlineCode}{" "}
                            </span>
                            <span className="flightno">{item.flightNo} </span>
                          </div>
                          <div className="location">
                            <span className="pickup">{item.source}</span> -{" "}
                            <span className="dropoff">
                              {item.destination !== ""
                                ? item.destination
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="info_member">
                          <div className="view_scroll">
                            {item.crewName1 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName1.split(/[\s`,]+/)[
                                      item.crewName1.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName1.split(/[\s`,]+/)[
                                      item.crewName1.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName1.split(/[\s`,]+/)[
                                      item.crewName1.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  {/* {item.crewName1.replace(/#/g, '\r\n')} */}
                                  <div className="name">
                                    {item.crewName1.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName1.split(/[\s`,]+/)[
                                          item.crewName1.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName1.split(/[\s`,]+/)[
                                          item.crewName1.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName1.split(/[\s`,]+/)[
                                          item.crewName1.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName1.split(/[\s`,]+/)[
                                          item.crewName1.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {
                                      item.crewName1
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                  {/* <div className='transportation' style={{ display: item.crewName1.split(/[\s`,]+/)[item.crewName1.split(/[\s`,]+/).length - 1] == "1" ? 'none' : 'block' }}> {item.crewName1.split(/#/g,)[1]}</div> */}
                                </div>
                              </div>
                            ) : null}
                            {item.crewName2 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName2.split(/[\s`,]+/)[
                                      item.crewName2.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName2.split(/[\s`,]+/)[
                                      item.crewName2.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName2.split(/[\s`,]+/)[
                                      item.crewName2.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName2.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName2.split(/[\s`,]+/)[
                                          item.crewName2.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName2.split(/[\s`,]+/)[
                                          item.crewName2.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName2.split(/[\s`,]+/)[
                                          item.crewName2.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName2.split(/[\s`,]+/)[
                                          item.crewName2.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName2
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }{" "}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName3 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName3.split(/[\s`,]+/)[
                                      item.crewName3.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName3.split(/[\s`,]+/)[
                                      item.crewName3.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName3.split(/[\s`,]+/)[
                                      item.crewName3.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName3.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName3.split(/[\s`,]+/)[
                                          item.crewName3.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName3.split(/[\s`,]+/)[
                                          item.crewName3.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName3.split(/[\s`,]+/)[
                                          item.crewName3.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName3.split(/[\s`,]+/)[
                                          item.crewName3.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName3
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName4 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName4.split(/[\s`,]+/)[
                                      item.crewName4.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName4.split(/[\s`,]+/)[
                                      item.crewName4.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName4.split(/[\s`,]+/)[
                                      item.crewName4.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName4.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName4.split(/[\s`,]+/)[
                                          item.crewName4.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName4.split(/[\s`,]+/)[
                                          item.crewName4.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName4.split(/[\s`,]+/)[
                                          item.crewName4.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName4.split(/[\s`,]+/)[
                                          item.crewName4.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName4
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName5 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName5.split(/[\s`,]+/)[
                                      item.crewName5.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName5.split(/[\s`,]+/)[
                                      item.crewName5.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName5.split(/[\s`,]+/)[
                                      item.crewName5.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName5.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName5.split(/[\s`,]+/)[
                                          item.crewName5.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName5.split(/[\s`,]+/)[
                                          item.crewName5.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName5.split(/[\s`,]+/)[
                                          item.crewName5.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName5.split(/[\s`,]+/)[
                                          item.crewName5.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName5
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName6 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName6.split(/[\s`,]+/)[
                                      item.crewName6.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName6.split(/[\s`,]+/)[
                                      item.crewName6.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName6.split(/[\s`,]+/)[
                                      item.crewName6.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName6.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName6.split(/[\s`,]+/)[
                                          item.crewName6.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName6.split(/[\s`,]+/)[
                                          item.crewName6.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName6.split(/[\s`,]+/)[
                                          item.crewName6.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName6.split(/[\s`,]+/)[
                                          item.crewName6.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName6
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div
                              style={{ height: 1 + "px", fontSize: 1 + "px" }}
                            >
                              <br />
                            </div>

                            {item.crewName7 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName7.split(/[\s`,]+/)[
                                      item.crewName7.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName7.split(/[\s`,]+/)[
                                      item.crewName7.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName7.split(/[\s`,]+/)[
                                      item.crewName7.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName7.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName7.split(/[\s`,]+/)[
                                          item.crewName7.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName7.split(/[\s`,]+/)[
                                          item.crewName7.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName7.split(/[\s`,]+/)[
                                          item.crewName7.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName7.split(/[\s`,]+/)[
                                          item.crewName7.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName7
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {item.crewName8 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName8.split(/[\s`,]+/)[
                                      item.crewName8.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName8.split(/[\s`,]+/)[
                                      item.crewName8.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName8.split(/[\s`,]+/)[
                                      item.crewName8.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName8.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName8.split(/[\s`,]+/)[
                                          item.crewName8.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName8.split(/[\s`,]+/)[
                                          item.crewName8.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName8.split(/[\s`,]+/)[
                                          item.crewName8.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName8.split(/[\s`,]+/)[
                                          item.crewName8.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName8
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName9 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName9.split(/[\s`,]+/)[
                                      item.crewName9.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName9.split(/[\s`,]+/)[
                                      item.crewName9.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName9.split(/[\s`,]+/)[
                                      item.crewName9.split(/[\s`,]+/).length - 1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName9.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName9.split(/[\s`,]+/)[
                                          item.crewName9.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName9.split(/[\s`,]+/)[
                                          item.crewName9.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName9.split(/[\s`,]+/)[
                                          item.crewName9.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName9.split(/[\s`,]+/)[
                                          item.crewName9.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName9
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName10 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName10.split(/[\s`,]+/)[
                                      item.crewName10.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName10.split(/[\s`,]+/)[
                                      item.crewName10.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName10.split(/[\s`,]+/)[
                                      item.crewName10.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName10.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName10.split(/[\s`,]+/)[
                                          item.crewName10.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName10.split(/[\s`,]+/)[
                                          item.crewName10.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName10.split(/[\s`,]+/)[
                                          item.crewName10.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName10.split(/[\s`,]+/)[
                                          item.crewName10.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName10
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {item.crewName11 !== null ? (
                              <div
                                className="list"
                                style={{
                                  background:
                                    item.crewName11.split(/[\s`,]+/)[
                                      item.crewName11.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "#ebf7ee"
                                      : "#fcedea",
                                  color:
                                    item.crewName11.split(/[\s`,]+/)[
                                      item.crewName11.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "#00903d"
                                      : "#c74227",
                                  border:
                                    item.crewName11.split(/[\s`,]+/)[
                                      item.crewName11.split(/[\s`,]+/).length -
                                        1
                                    ] === 1
                                      ? "1px solid #a7e0b5"
                                      : "1px solid #f6cec6",
                                }}
                              >
                                <div id="list_1">
                                  <div className="name">
                                    {item.crewName11.split(/#/g)[0]}
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName11.split(/[\s`,]+/)[
                                          item.crewName11.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "block"
                                          : "none",
                                      color:
                                        item.crewName11.split(/[\s`,]+/)[
                                          item.crewName11.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    No Transpoter
                                  </div>
                                  <div
                                    className="transportation"
                                    style={{
                                      display:
                                        item.crewName11.split(/[\s`,]+/)[
                                          item.crewName11.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "none"
                                          : "block",
                                      color:
                                        item.crewName11.split(/[\s`,]+/)[
                                          item.crewName11.split(/[\s`,]+/)
                                            .length - 1
                                        ] === "0"
                                          ? "#ca4e34"
                                          : "#31a461",
                                    }}
                                  >
                                    {" "}
                                    {
                                      item.crewName11
                                        .split(/#/g)[1]
                                        .split("`")[0]
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div
              className="card-body"
              style={{ textAlign: "center", fontSize: "21px" }}
            >
              {" "}
              No record found
            </div>
          </div>
        )}
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={false}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
