import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Template } from "devextreme-react/core/template";
import { LoadPanel } from "devextreme-react/load-panel";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import RadioGroup from "devextreme-react/radio-group";
const position = { of: "#historydiv" };

const regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class EmailConfigurationComponent extends Component {
  static displayName = EmailConfigurationComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      loadPanelVisible: false,
      emailConfigurationGridDataSource: [],

      isAddCategoryTypeSubmitted: false,
      openAddCategoryModal: false,
      radioEmailCategoryType: 1,
      hotelDropdownDataSource: [],
      transportDropdownDataSource: [],

      isEmailSubmitted: false,
      openEmailConfigModel: false,
      emailAlertConfigurationId: 0,
      isCrewTypeApplicable: false,
      crewTypeCategoryDataSource: [],
      crewTypeCategoryCode: "",
      emailGridDataSource: [],
      email: "",
      errors: {
        email: "",
        hotel: "",
        transport: "",
        crewTypeCategoryCode: "",
      },
    };
  }
  async componentDidMount() {
    await this.getApiData();
    console.log("render count");
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.GetEmailConfiguration.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };
  toolbarItemRender = () => {
    return (
      <>
        <div className="row">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.setState({ openAddCategoryModal: true });
            }}
          >
            <span title="button">
              <i className="fas fa-plus"></i>&nbsp;Add
            </span>
          </button>
        </div>
      </>
    );
  };

  getApiData = async () => {
    let hotelDropdownData = [];
    let transportDropdownData = [];
    try {
      this.setState({
        loadPanelVisible: true,
      });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      hotelDropdownData = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Hotel/GetDropDownAllHotelsWeb/${airlineCode}`,
        requestParams
      )
        .then((response) => response.json())
        .then(
          (data) =>
            data?.payload?.sort((a, b) =>
              a.hotelName.localeCompare(b.hotelName)
            ) ?? []
        );

      transportDropdownData = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/TransporterList/${airlineCode}`,
        requestParams
      )
        .then((response) => response.json())
        .then(
          (data) =>
            data?.payload?.sort((a, b) =>
              a.transporterName.localeCompare(b.transporterName)
            ) ?? []
        );
      await this.GetEmailConfiguration();
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getApiData function",
        error
      );
    }
    this.setState({
      hotelDropdownDataSource: hotelDropdownData,
      transportDropdownDataSource: transportDropdownData,
      loadPanelVisible: false,
    });
  };
  GetEmailConfiguration = async () => {
    let emailConfigurationLst = [];
    try {
      this.setState({
        loadPanelVisible: true,
      });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/${airlineCode}`,
        requestParams
      ).then((response) => response.json());
      emailConfigurationLst = data?.payload ?? [];
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".GetEmailConfiguration function",
        err
      );
    }
    this.setState({
      emailConfigurationGridDataSource: emailConfigurationLst,
      loadPanelVisible: false,
    });
  };
  getCrewTypeCategory = async () => {
    let crewTypeCategoryLst = [];
    try {
      this.setState({
        loadPanelVisible: true,
      });
      const requestParams = getRequestParams("GET");
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/CrewTypeCategory`,
        requestParams
      ).then((response) => response.json());
      crewTypeCategoryLst = data?.payload ?? [];
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getCrewTypeCategory function",
        error
      );
    }
    this.setState({
      loadPanelVisible: false,
      crewTypeCategoryDataSource: crewTypeCategoryLst,
    });
  };
  handleInputChange = (event) => {
    try {
      const target = event.target;
      const value =
        target.type === "checkbox" || target.type === "radio"
          ? target.checked
          : target.value;
      const name = target.name;
      let errors = this.state.errors;
      switch (name) {
        case "email":
          errors.email = "";
          if (value.trim() === "" || value.length <= 100) {
            this.setState({ email: value });
          }
          break;
        default:
          break;
      }
      this.setState({ errors: errors });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleInputChange function",
        error
      );
    }
  };
  handleOnAddEmailCategorySubmitted = async (e) => {
    e.preventDefault();
    try {
      let formIsValid = true;
      let err = this.state.errors;
      err.hotel = "";
      err.transport = "";
      const hotelSelectedData =
        this.refHotelDropdown?.instance?.option("selectedItem") ?? null;
      const hotelCode = hotelSelectedData?.hotelCode ?? "";
      const hotelName = hotelSelectedData?.hotelName ?? "";

      let transportSelectedData =
        this.refTransportDropdown?.instance?.option("selectedItem") ?? null;
      let transporterId = transportSelectedData?.sourceTransporterId ?? 0;
      let transporterName = transportSelectedData?.transporterName ?? "";

      if (this.state.radioEmailCategoryType === 1) {
        if (hotelCode === "") {
          err.hotel = "Please select hotel.";
          formIsValid = false;
        }
      }
      if (this.state.radioEmailCategoryType === 2) {
        if (transporterId === 0) {
          err.transport = "Please select transport.";
          formIsValid = false;
        }
      }
      this.setState({ errors: err });
      if (formIsValid === true) {
        try {
          this.setState({ isAddCategoryTypeSubmitted: true });
          var request = {};
          request.AirlineCode = sessionStorage.getItem("session_airlineCode");
          request.HotelCode = hotelCode;
          request.HotelName = hotelName;
          request.TransporterId = transporterId;
          request.TransporterName = transporterName;
          request.IsActive = true;
          request.UserName = sessionStorage.getItem("session_userName");
          request.CategoryType =
            this.state.radioEmailCategoryType === 1 ? "Hotel" : "Transport";
          const requestParams = getRequestParams("POST", request);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/AddConfiguration`,
            requestParams
          );
          const data = await response.json();
          if (data.isSuccess) {
            await Swal.fire({
              icon: "success",
              title: "Success",
              text: data.message,
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.handelOnCloseCategoryModal();
                await this.GetEmailConfiguration();
              }
            });
          } else {
            toast.error(
              data.message === null || data.message === ""
                ? "Unable to process the request."
                : data.message,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: "310px" },
                hideProgressBar: false,
                newestOnTop: false,
                closeButton: false,
              }
            );
          }
        } catch (err) {
          console.log(
            "Error in " +
              this.displayName +
              ".handleOnAddEmailCategorySubmitted function",
            err
          );
          toast.error("Something went wrong! Unable to process the request.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
        }
      }
    } catch (ex) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnAddEmailCategorySubmitted function",
        ex
      );
    } finally {
      this.setState({ isAddCategoryTypeSubmitted: false });
    }
  };
  handelOnCloseCategoryModal = () => {
    try {
      let error = this.state.errors;
      error.hotel = "";
      error.transport = "";
      this.setState({
        loadPanelVisible: false,
        isAddCategoryTypeSubmitted: false,
        openAddCategoryModal: false,
        radioEmailCategoryType: 1,
        errors: error,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handelOnOpenEmailConfigModal = async (e, data) => {
    e.preventDefault();
    try {
      this.setState({
        openEmailConfigModel: true,
        emailAlertConfigurationId: data.rowIndex,
        isCrewTypeApplicable: data.crewTypeApplicable,
      });
      await this.GetEmailAlertConfigurationUsers(data.rowIndex);
      await this.getCrewTypeCategory();
    } catch (error) {
      console.log(error);
    }
  };
  GetEmailAlertConfigurationUsers = async (emailAlertConfigurationId) => {
    let emailLst = [];
    try {
      this.setState({
        loadPanelVisible: true,
      });
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/Emails/${emailAlertConfigurationId}`,
        requestParams
      );
      const data = await response.json();
      emailLst = data?.payload ?? [];
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".GetEmailAlertConfigurationUsers function",
        err
      );
    }
    this.setState({
      emailGridDataSource: emailLst,
      loadPanelVisible: false,
    });
  };
  handelOnCloseEmailConfigModal = () => {
    try {
      let error = this.state.errors;
      error.email = "";
      this.setState({
        loadPanelVisible: false,
        isEmailSubmitted: false,
        openEmailConfigModel: false,
        emailAlertConfigurationId: 0,
        isCrewTypeApplicable: false,
        crewTypeCategoryCode: "",
        emailGridDataSource: [],
        email: "",
        errors: error,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleEmailConfigurationSubmit = async (e) => {
    try {
      debugger;
      let formIsValid = true;
      let errors = this.state.errors;
      errors.email = "";
      errors.crewTypeCategoryCode = "";
      if (this.state.email === null || this.state.email.trim() === "") {
        formIsValid = false;
        errors.email = "Please enter email.";
      }
      if (this.state.email !== "") {
        if (!regEmail.test(this.state.email)) {
          formIsValid = false;
          errors.email = "Please enter valid email.";
        }
      }
      if (this.state.isCrewTypeApplicable) {
        if (
          this.state.crewTypeCategoryCode === "" ||
          this.state.crewTypeCategoryCode === null
        ) {
          formIsValid = false;
          errors.crewTypeCategoryCode = "Please Select Crew Type Category.";
        }
      }

      this.setState({ errors: errors });
      if (formIsValid === true) {
        this.setState({ isEmailSubmitted: true });
        var request = {};
        request.AirlineCode = sessionStorage.getItem("session_airlineCode");
        request.EmailAlertConfigId = this.state.emailAlertConfigurationId;
        request.Email = this.state.email.trim();
        request.CrewTypeCategoryCode = this.state.isCrewTypeApplicable
          ? this.state.crewTypeCategoryCode
          : "";
        request.IsActive = true;
        request.UserName = sessionStorage.getItem("session_userName");
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/AddUpdateEmail`,
          requestParams
        );
        const data = await response.json();
        this.setState({ isEmailSubmitted: false });
        if (data.isSuccess) {
          await Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.setState({ email: "" });
              await this.GetEmailAlertConfigurationUsers(
                this.state.emailAlertConfigurationId
              );
              await this.GetEmailConfiguration();
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
      this.setState({ isEmailSubmitted: false });
    }
  };

  handleOnDeleteEmail = async (e, data) => {
    e.preventDefault();
    try {
      Swal.fire({
        html: "Are you sure, you want to delete this email configuration?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loadPanelVisible: true });
          const requestParams = getRequestParams("DELETE");
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/DeleteEmail/${data.rowIndex}`,
            requestParams
          )
            .then((response) => {
              if (response.ok) return response.json();
            })
            .then((data) => {
              this.setState({ loadPanelVisible: false });
              if (data.isSuccess === true) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: data.message,
                  allowOutsideClick: false,
                }).then(async (result) => {
                  if (data.isSuccess === true) {
                    if (result.isConfirmed) {
                      await this.GetEmailAlertConfigurationUsers(
                        this.state.emailAlertConfigurationId
                      );
                      await this.GetEmailConfiguration();
                    }
                  }
                });
              } else {
                toast.error(
                  data.message === null || data.message === ""
                    ? "Unable to process the request."
                    : data.message,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: { width: "310px" },
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeButton: false,
                  }
                );
              }
            });
        }
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  handleOnActivateDeactivateEmailConfig = async (
    e,
    emailConfigId,
    isActive
  ) => {
    e.preventDefault();
    try {
      let msg = `Are you sure want to ${
        isActive ? "Activate" : "Deactivate"
      } this email`;
      let title = isActive ? "Activate" : "Deactivate";
      let isConfirm = await this.isConfirm("status", msg, title);
      if (isConfirm) {
        var request = {};
        request.isActive = isActive;
        request.UserName = sessionStorage.getItem("session_userName");
        const requestParams = getRequestParams("PUT", request);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}EmailConfiguration/email/${emailConfigId}/update-status`,
          requestParams
        );
        const data = await response.json();
        if (data.isSuccess) {
          await Swal.fire({
            allowOutsideClick: false,
            icon: data.isSuccess ? "success" : "error",
            title: data.isSuccess ? "Success" : "Oops...",
            text:
              data.isSuccess === false &&
              (data.message === null || data.message === "")
                ? "Unable to process the request."
                : data.message === null || data.message === ""
                ? `Email has been set ${title} successfully.`
                : data.message,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.GetEmailAlertConfigurationUsers(
                this.state.emailAlertConfigurationId
              );
            }
          });
        } else {
          toast.error(
            data.message === null || data.message === ""
              ? "Unable to process the request."
              : data.message,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { width: "310px" },
              hideProgressBar: false,
              newestOnTop: false,
              closeButton: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnActivateDeactivateEmailConfig function",
        err
      );
      toast.error("Something went wrong! Unable to process the request.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        style: { width: "310px" },
        hideProgressBar: false,
        newestOnTop: false,
        closeButton: false,
      });
    }
  };
  isConfirm = async (type, msg, title) => {
    let isResult = false;
    let confirmMessage = "";
    if (type === "delete") {
      if (msg !== "") {
        confirmMessage = "Are you sure want to delete this <b>" + msg + "</b>?";
      } else {
        confirmMessage = "Are you sure want to delete this ?";
      }
    } else if (type === "status") {
      confirmMessage = msg;
    } else {
      confirmMessage =
        "Are you sure want to " +
        (type === "add" ? "Add" : "Update") +
        " this configuration?";
    }
    await Swal.fire({
      title: title,
      html: "<small>" + confirmMessage + "</small>",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText:
        type === "delete" ? "Delete" : type === "add" ? "Add" : title,
    }).then(async (result) => {
      if (result.isConfirmed) {
        isResult = true;
      }
    });
    return isResult;
  };
  render() {
    const renderConfigGridCell = (data) => {
      return (
        <a
          href="#"
          className="fas fa-envelope"
          title="Add Email"
          onClick={(e) => {
            this.handelOnOpenEmailConfigModal(e, data.data);
          }}
        ></a>
      );
    };
    const renderEmailGridCell = (data) => {
      let inActiveLink = data.data.isActive ? (
        <a
          href="#"
          title="Deactivate"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnActivateDeactivateEmailConfig(
              e,
              data.data.rowIndex,
              false
            );
          }}
        >
          <i className="fas fa-toggle-on"></i>
        </a>
      ) : (
        ""
      );
      let activeLink = !data.data.isActive ? (
        <a
          href="#"
          title="Activate"
          style={{ paddingLeft: "6px" }}
          onClick={(e) => {
            this.handleOnActivateDeactivateEmailConfig(
              e,
              data.data.rowIndex,
              true
            );
          }}
        >
          <i className="text-danger fas fa-toggle-off"></i>
        </a>
      ) : (
        ""
      );
      return (
        <span style={{ textAlign: "left", width: "auto" }}>
          <a
            href="#"
            className="far fa-trash-alt"
            title="Delete"
            onClick={(e) => {
              this.handleOnDeleteEmail(e, data.data);
            }}
          ></a>
          {inActiveLink === "" || inActiveLink}
          {activeLink === "" || activeLink}
        </span>
      );
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div>
              <DataGrid
                dataSource={this.state.emailConfigurationGridDataSource}
                selection={{ mode: "single" }}
                columnsAutoWidth={false}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
                onToolbarPreparing={this.onToolbarPreparing}
              >
                <Column
                  dataField="rowIndex"
                  width={35}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption=""
                  alignment={"center"}
                  cellRender={renderConfigGridCell}
                />
                <Column
                  dataField="categoryType"
                  width={100}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Type"
                />
                <Column
                  dataField="emailCategory"
                  width={200}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Email Category "
                />

                <Column
                  dataField="crewTypeApplicableStatus"
                  width={"auto"}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  alignment={"center"}
                  caption="Crew Type Based"
                />
                <Column
                  dataField="hotelCode"
                  width={100}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Hotel Code"
                />
                <Column
                  dataField="hotelName"
                  width={250}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Hotel"
                />
                <Column
                  dataField="transporterName"
                  width={250}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Transporter"
                />
                <Column
                  dataField="isActive"
                  width={65}
                  alignment={"center"}
                  caption="Active"
                />
                <Column
                  dataField="email"
                  width={250}
                  allowSearch={true}
                  allowFiltering={false}
                  allowSorting={true}
                  caption="Email"
                />

                <SortByGroupSummaryInfo summaryItem="count" />
                <GroupPanel visible={false} />
                <Template
                  name="toolbarTemplate"
                  render={this.toolbarItemRender}
                />
                <HeaderFilter visible={false} />
                <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 20, 50]}
                  showInfo={true}
                  visible={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={false}
                  fileName="HotelRating"
                />
              </DataGrid>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.openAddCategoryModal}
          style={{
            content: {
              width: "30%",
            },
          }}
          className={"react-modal"}
          onRequestClose={this.handelOnCloseCategoryModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">
              Add Configuration For Hotel/Transport
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handelOnCloseCategoryModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="dx-widget dx-collection">
                  <div className="dx-field">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="radioHotelEmailCategory"
                        checked={this.state.radioEmailCategoryType === 1}
                        onChange={() => {
                          this.setState({ radioEmailCategoryType: 1 });
                        }}
                      />
                      <label
                        className=""
                        htmlFor="radioHotelEmailCategory"
                        style={{ fontSize: "inherit" }}
                      >
                        &nbsp;Hotel
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="radioTransportEmailCategory"
                        checked={this.state.radioEmailCategoryType === 2}
                        onChange={() => {
                          this.setState({ radioEmailCategoryType: 2 });
                        }}
                      />
                      <label
                        className=""
                        htmlFor="radioTransportEmailCategory"
                        style={{ fontSize: "inherit" }}
                      >
                        &nbsp;Transport
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.radioEmailCategoryType === 1 && (
                  <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                    <SelectBox
                      dataSource={this.state.hotelDropdownDataSource}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Hotel"
                      defaultValue={""}
                      displayExpr="hotelName"
                      valueExpr="hotelCode"
                      onSelectionChanged={() => {
                        let err = this.state.errors;
                        err.hotel = "";
                        this.setState({ errors: err });
                      }}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                      ref={(select) => (this.refHotelDropdown = select)}
                    />
                    {this.state.errors["hotel"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["hotel"]}
                      </span>
                    )}
                    {/* </div> */}
                  </div>
                )}
                {this.state.radioEmailCategoryType === 2 && (
                  <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                    <SelectBox
                      dataSource={this.state.transportDropdownDataSource}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Transport"
                      defaultValue={0}
                      displayExpr="transporterName"
                      valueExpr="sourceTransporterId"
                      onSelectionChanged={() => {
                        let err = this.state.errors;
                        err.transport = "";
                        this.setState({ errors: err });
                      }}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                      ref={(select) => (this.refTransportDropdown = select)}
                    />
                    {this.state.errors["transport"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["transport"]}
                      </span>
                    )}
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group" style={{ marginLeft: 24 + "px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleOnAddEmailCategorySubmitted}
              >
                {this.state.isAddCategoryTypeSubmitted && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isAddCategoryTypeSubmitted && (
                  <span title="Submit">
                    <i className="fas fa-check"></i> Submit
                  </span>
                )}
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-custom-gray "
                onClick={this.handelOnCloseCategoryModal}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.openEmailConfigModel}
          style={{
            content: {
              width: "45%",
            },
          }}
          className={"react-modal"}
          onRequestClose={this.handelOnCloseEmailConfigModal}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header">
            <h6 className="modal-title">Add Email</h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handelOnCloseEmailConfigModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-8 col-sm-9 col-md-9 col-lg-9">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="email"
                    placeholder="test@domain.com"
                    title="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors["email"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["email"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <label></label>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleEmailConfigurationSubmit}
                  >
                    {this.state.isEmailSubmitted && (
                      <span title="In-process">
                        <i className="fas fa-spinner fa-spin"></i> In-process
                      </span>
                    )}
                    {!this.state.isEmailSubmitted && (
                      <span title="Submit">
                        <i className="fas fa-check"></i> Add
                      </span>
                    )}
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-custom-gray "
                    onClick={this.handelOnCloseEmailConfigModal}
                    title="Close"
                  >
                    <i className="fas fa-times"></i> Close
                  </button>
                </div>
              </div>
            </div>
            {this.state.isCrewTypeApplicable === true && (
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group">
                  <RadioGroup
                    id="radio-group-with-selection"
                    dataSource={this.state.crewTypeCategoryDataSource}
                    value={this.state.crewTypeCategoryCode}
                    valueExpr="code"
                    displayExpr="name"
                    onValueChanged={(e) => {
                      this.setState({
                        crewTypeCategoryCode: e.value,
                      });
                    }}
                    layout="horizontal"
                  />
                  {this.state.errors["crewTypeCategoryCode"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["crewTypeCategoryCode"]}
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <DataGrid
                dataSource={this.state.emailGridDataSource}
                selection={{ mode: "single" }}
                columnsAutoWidth={true}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                allowColumnResizing={true}
                columnResizingMode="widget"
              >
                <Column
                  dataField="rowIndex"
                  width={50}
                  alignment={"center"}
                  allowSearch={false}
                  allowFiltering={false}
                  allowSorting={false}
                  caption=""
                  cellRender={renderEmailGridCell}
                />
                <Column dataField="email" width={"60%"} caption="Email" />

                {this.state.isCrewTypeApplicable === true && (
                  <Column
                    dataField="crewTypeCategory"
                    width={"25%"}
                    caption="Category"
                  />
                )}
                <Column
                  dataField="isActive"
                  width={"15%"}
                  alignment={"center"}
                  caption="Active"
                />
                <SortByGroupSummaryInfo summaryItem="count" />
                <HeaderFilter visible={true} />
                {/* <SearchPanel
                  visible={true}
                  highlightCaseSensitive={true}
                  width={240}
                  placeholder="Search..."
                /> */}
                <Paging defaultPageSize={5} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[5, 10, 20]}
                  showInfo={true}
                  visible={true}
                />
              </DataGrid>
            </div>
          </div>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
