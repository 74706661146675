import React, { Component, Fragment } from 'react';
import { ChangePasswordComponent } from '../ChangePasswordComponent';

export class TopUserSetupComponent extends Component {
    static displayName = TopUserSetupComponent.name;

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    handleLogout = async (e) => {
        e.preventDefault();        
        // const requestParams = getRequestParams('GET');
        // let userId = parseInt(sessionStorage.getItem("session_userId"));
        // let loginHistoryId = parseInt(sessionStorage.getItem("session_LoginHistoryId"));        
        // const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        sessionStorage.clear();
        window.location.href = "/";
    }
    openchangesPasswordModel = (e) => {
        e.preventDefault();
        this.refs.changepasswordcomponent.showModal();
    }
    render() {
        return (
            <Fragment>
                <ul className="navbar-nav navbar-right">
                    <li className="dropdown mr-0 user-profile">
                        <a href="#" data-toggle="dropdown" className="dropdown-toggle nav-link-user">
                            {/* <div className="d-sm-inline-block d-lg-inline-block text-white pr-2">{sessionStorage.getItem("session_accountname").toUpperCase()}</div> */}
                            <img alt="image" src="./assets/img/avatar/avatar-1.png" className="rounded-circle mr-1" style={{ width: "20px" }} />
                        </a>
                        <div className="dropdown-menu dropdown-list dropdown-menu-right rounded-vlg">
                            <div className="dropdown-header pb-0">
                                Hey {sessionStorage.getItem('session_userName')}!
                            </div>
                            <div className="text-center mt-3 mb-3">
                                <figure className="avatar mr-2 avatar-xl">
                                    <img src="../assets/img/avatar/avatar-1.png" alt="..." />
                                    <i className="avatar-presence online"></i>
                                </figure>
                            </div>
                            <a href="#" className="dropdown-item has-icon" onClick={(e) => { this.openchangesPasswordModel(e) }}>
                                <i className="fas fa-lock-open"></i> Change Password
                            </a>
                            <a href="#" onClick={this.handleLogout} className="dropdown-item has-icon mb-2">
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </a>
                            {/* <div className="dropdown-footer text-center pt-0 pb-2"><ClockComponent /></div> */}
                        </div>
                    </li>
                </ul>
                <ChangePasswordComponent ref="changepasswordcomponent" />
            </Fragment>
        );
    }
}