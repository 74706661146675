import React, { Component, Fragment } from "react";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import DateBox from "devextreme-react/date-box";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
const customStyles = {
  content: {
    width: "70%",
    //height:'600px'
  },
};

export class CancelHotelComponent extends Component {
  static displayName = CancelHotelComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      isOpenModel: false,
      hotelDropdownList: [],
      airportDropdownList: [],
      crewList: [],
      loadPanelVisible: false,
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
      airportCode: "",
      hotelId: 0,
      employeeNumber: "",
      uniqueFlightNumber: [],
      flightTime: "",
      excelData: [],
    };
  }

  async componentDidMount() {
    await this.getHotelsList();
    await this.getAirports();
    await this.getCrewMember();
  }

  getCrewMember = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.Status = "Active";
      request.AirportCode = "";
      request.FlightNo = "";
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      );
      const data = await response.json();
      this.setState({
        crewList: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  getCancelledHotel = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserTypeId = parseInt(sessionStorage.getItem("session_userTypeId"));
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserMasterId = parseInt(1);
      request.HotelId = this.state.hotelId;
      request.FromDate = formatDate(
        this.state.filterFromDatetime,
        "yyyy-MM-dd"
      );
      request.ToDate = formatDate(this.state.filterToDatetime, "yyyy-MM-dd");
      request.AirportCode = this.state.airportCode;
      request.EmployeeNumber = this.state.employeeNumber;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Report",
        requestParams
      );
      const data = await response.json();
      this.setState({
        datasource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getAirports = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportDropdownList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getHotelsList = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Hotel/GetDropDownAllHotelsWeb/${airlineCode}`,
        requestParams
      );

      const data = await response.json();
      this.setState({
        hotelDropdownList:
          data?.payload?.sort((a, b) =>
            a.hotelName.localeCompare(b.hotelName)
          ) ?? [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getHotels function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  handleHotelChange = async (e) => {
    this.setState({
      hotelId: e.selectedItem != null ? e.selectedItem.hotelId : 0,
    });
  };
  handleAirportChange = async (e) => {
    this.setState({
      airportCode: e.selectedItem != null ? e.selectedItem.airportCode : "",
    });
  };
  handleOnFilterPickupDateChanged = (date) => {
    this.setState({ filterFromDatetime: date.value });
  };
  handleOnFiltertoDateChanged = (date) => {
    this.setState({ filterToDatetime: date.value });
  };
  handleEmployeeChange = async (e) => {
    this.setState({
      employeeNumber:
        e.selectedItem != null ? e.selectedItem.employeeNumber : "",
    });
  };
  clearData = async (e) => {
    this.setState({
      loadPanelVisible: false,
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
      airportCode: "",
      hotelId: 0,
      isOpenModel: false,
      datasource: null,
    });
  };
  openModelCrew = async (e, _data, code, hotelName) => {
    this.setState({
      isOpenModel: true,
      flightTime: _data,
    });
    try {
      if (hotelName === "" || hotelName === null) {
        hotelName = "";
      }
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.FromDate = _data;
      request.AirportCode = code;
      request.HotelName = String(hotelName);
      request.EmployeeNumber = this.state.employeeNumber;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Report/GetCancelledHotelStaySummaryReportDetails",
        requestParams
      );
      const data = await response.json();
      this.setState({
        stayDetailSummary: data.payload,
        loadPanelVisible: false,
      });
      var uniqueFlights = {};
      var output = [];
      for (let i of this.state.stayDetailSummary) {
        uniqueFlights = {};
        uniqueFlights.flightNumber = i.flightNumber;
        uniqueFlights.flightTime = i.flightTime;
        output.push(uniqueFlights);
      }
      const uniqueFlightNumber = output.filter(
        (obj, index) =>
          output.findIndex((item) => item.flightNumber === obj.flightNumber) ===
          index
      );
      this.setState({ uniqueFlightNumber: uniqueFlightNumber });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".openModelCrew function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  modelClose = async () => {
    this.setState({
      isOpenModel: false,
      stayDetailSummary: [],
      flightTime: "",
      uniqueFlightNumber: [],
    });
  };
  downloadExcel = async (e) => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserMasterId = parseInt(1);
      request.HotelId = this.state.hotelId;
      request.FromDate = formatDate(
        this.state.filterFromDatetime,
        "yyyy-MM-dd"
      );
      request.ToDate = formatDate(this.state.filterToDatetime, "yyyy-MM-dd");
      request.AirportCode = this.state.airportCode;
      request.EmployeeNumber = this.state.employeeNumber;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Report/GetCancelHotelExcel",
        requestParams
      );
      const data = await response.json();
      this.setState({
        excelData: data.payload,
        loadPanelVisible: false,
      });
      if (data.payload != null) {
        let Heading = [
          [
            "Airline",
            "Airport",
            "Hotel",
            "Employee No",
            "First Name",
            "Last Name",
            "Crew Type",
            "Flight No",
            "Flight Time",
            "Pickup Time",
            "Dropoff Time",
            "Pickup",
            "Dropoff",
            "Reason",
            "Cancellation Date",
          ],
        ];
        const worksheet = XLSX.utils.json_to_sheet(this.state.excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, Heading);
        XLSX.utils.sheet_add_json(worksheet, this.state.excelData, {
          origin: "A2",
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "CancelledHotelStay.xlsx");
      } else {
        Swal.fire({
          // icon: 'success',
          title: "Success",
          text: "Data Not Found",
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Hotel</label>
                  <SelectBox
                    dataSource={this.state.hotelDropdownList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Hotel"
                    name="hotelId"
                    //defaultValue={0}
                    value={this.state.hotelId}
                    displayExpr="hotelName"
                    valueExpr="hotelId"
                    onSelectionChanged={this.handleHotelChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["hotelName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["hotelName"]}</span>} */}
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Airport</label>
                  <SelectBox
                    dataSource={this.state.airportDropdownList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportCode"
                    //defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["hotelName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["hotelName"]}</span>} */}
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Employee</label>
                  <SelectBox
                    dataSource={this.state.crewList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="employeeNumber"
                    //defaultValue={0}
                    value={this.state.employeeNumber}
                    displayExpr="employeeNumber"
                    valueExpr="employeeNumber"
                    onSelectionChanged={this.handleEmployeeChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterFromDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterFromDatetime}
                    value={this.state.filterFromDatetime}
                    onValueChanged={this.handleOnFilterPickupDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterToDatetime}
                    value={this.state.filterToDatetime}
                    onValueChanged={this.handleOnFiltertoDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.getCancelledHotel}
                  >
                    <span title="Filter">
                      <i className="fas fa-filter"></i> Filter
                    </span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-custom-gray "
                    onClick={this.clearData}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={(e) => this.downloadExcel(e)}
                  >
                    <span title="Export">
                      <i class="fas fa-download"></i> Download
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card overFlow">
          <table
            id="emp"
            cellPadding="0"
            border="0"
            cellSpacing="1"
            className="custom-table"
          >
            <thead>
              <tr>
                <th>Stay Location</th>
                <th>Layover Location</th>
                {this.state.datasource != null
                  ? this.state.datasource[0].summaries &&
                    this.state.datasource[0].summaries.map((item, i) => (
                      <td>{item.month}</td>
                    ))
                  : ""}
              </tr>
            </thead>
            <tbody>
              {this.state.datasource != null
                ? this.state.datasource &&
                  this.state.datasource.map((item, i) => (
                    <tr>
                      <td>{item.hotelName}</td>
                      <td>{item.airportCode}</td>

                      {item.summaries &&
                        item.summaries.map((_item, i) => (
                          <td>
                            <span
                              className="eventClick"
                              title="Click here to see details"
                              onClick={(e) =>
                                this.openModelCrew(
                                  e,
                                  _item.month,
                                  item.airportCode,
                                  item.hotelName
                                )
                              }
                            >
                              {_item.cancelCount}
                            </span>
                          </td>
                        ))}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.isOpenModel}
          style={customStyles}
          className={"react-modal"}
          onAfterOpen={this.afterOpenViewModal}
          onRequestClose={this.modelClose}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleViewJobSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">
                Crew Details :{this.state.flightTime}{" "}
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.modelClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="overflow-scroll">
                {this.state.stayDetailSummary != null ? (
                  this.state.uniqueFlightNumber &&
                  this.state.uniqueFlightNumber.map((item, i) => (
                    <div className="row mt-2 mb-1 pb-3 line-1">
                      <div className="col-6 mb-2">
                        Flight Number:{" "}
                        <strong className="font-weight">
                          {item.flightNumber}
                        </strong>
                      </div>
                      <div className="col-6 text-right mb-2">
                        <label>Flight Time:</label>
                        <strong className="font-weight">
                          {item.flightTime}
                        </strong>
                      </div>
                      <div className="col-12">
                        <table
                          id="emp"
                          cellPadding="0"
                          border="0"
                          cellSpacing="1"
                          className="custom-table"
                        >
                          <thead className="thead-dark">
                            <tr>
                              <th>Employee</th>
                              <th>Crew</th>
                              <th>CrewType</th>
                              <th>Airline CrewType</th>
                              <th>PickUp</th>
                              <th>PickUp Time</th>
                              <th>DropOff Time</th>
                              <th>Check In Time</th>
                              <th>Check Out Time</th>
                              <th>Cancellation Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.stayDetailSummary
                              .filter(
                                (p) => p.flightNumber === item.flightNumber
                              )
                              .map((_item, k) => (
                                <tr>
                                  <td>{_item.employeeNumber}</td>
                                  <td>{_item.crewLastName}</td>
                                  <td>{_item.crewTypeCategory}</td>
                                  <td>{_item.airlineCrewType}</td>
                                  <td>{_item.pickupLocation}</td>
                                  <td>{_item.pickupTime}</td>
                                  <td>{_item.dropoffTime}</td>
                                  <td>{_item.checkinTime}</td>
                                  <td>{_item.checkoutTime}</td>
                                  <td>
                                    {formatDate(
                                      _item.cancellationDate,
                                      "MM/dd/yyyy hh:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="box">
                    <b>Data Not Found</b>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}
