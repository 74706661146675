import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { getRequestParams } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LoadPanel } from "devextreme-react/load-panel";
import { Template } from "devextreme-react/core/template";
import Modal from "react-modal";
import { SelectBox } from "devextreme-react/select-box";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
const position = { of: "#historydiv" };
const CustomStyles = {
  content: {
    width: "30%",
    // /height: '30%'
  },
};
export class HotelsComponent extends Component {
  static displayName = HotelsComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      hotelGridDataSource: null,
      hotelDropdownList: [],
      loadPanelVisible: false,
      hotelModelIsOpen: false,
      cityId: 0,
      hotelId: 0,
      hotelName: "",
      hotelCode: "",
      ContactNumber: "",
      Email: "",
      StreetName: "",
      PostalCode: "",
      Latitude: 0,
      Longitude: 0,
      IsActive: true,
      errors: {
        hotelName: "",
      },
    };
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getHotels.bind(this),
      },
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      template: "toolbarTemplate",
    });
  };
  async componentDidMount() {
    await this.getHotels();
    await this.getHotelsList();
  }
  getHotels = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Hotel/ServiceHotels/" +
          airlineCode,
        requestParams
      );
      const data = await response.json();
      this.setState({
        hotelGridDataSource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getHotels function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  getHotelsList = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Hotel/" + airlineCode,
        requestParams
      );
      const data = await response.json();
      this.setState({
        hotelDropdownList:
          data?.payload?.sort((a, b) =>
            a.hotelName.localeCompare(b.hotelName)
          ) ?? [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log("Error in " + this.displayName + ".getHotels function", err);
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  openHotelModel = (e) => {
    e.preventDefault();
    this.setState({
      hotelModelIsOpen: true,
      hotelName: "",
    });
  };
  handleHotelChange = async (e) => {
    this.setState({
      hotelId: e.selectedItem != null ? e.selectedItem.hotelId : 0,
      hotelCode: e.selectedItem != null ? e.selectedItem.hotelCode : "",
      hotelName: e.selectedItem != null ? e.selectedItem.hotelName : "",
    });
  };

  handleHotelSubmit = async (e) => {
    e.preventDefault();
    try {
      let formIsValid = true;
      let errors = this.state.errors;
      errors.hotelName = "";
      if (this.state.hotelName.trim() === "") {
        formIsValid = false;
        errors.hotelName = "Please enter hotel.";
        this.setState({ errors: errors });
        return formIsValid;
      }

      this.setState({ isSubmited: true });
      var newObject = {};
      newObject.Id = this.state.hotelId;
      newObject.Code = this.state.hotelCode;
      newObject.Name = this.state.hotelName;
      const requestParams = getRequestParams("POST", newObject);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Hotel",
        requestParams
      );
      const data = await response.json();
      this.setState({ isSubmited: false });
      if (data.isSuccess) {
        await Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.getHotels();
            await this.closeUserModal();
          }
        });
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          style: { width: "310px" },
          hideProgressBar: false,
          newestOnTop: false,
          closeButton: false,
        });
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleUserSubmit function",
        err
      );
      this.setState({ isSubmited: false });
    }
  };
  closeUserModal = () => {
    this.setState({
      isSubmited: false,
      loadPanelVisible: false,
      hotelModelIsOpen: false,
      cityId: 0,
      hotelId: 0,
      hotelName: "",
      hotelCode: "",
      ContactNumber: "",
      Email: "",
      StreetName: "",
      PostalCode: "",
      Latitude: 0,
      Longitude: 0,
      IsActive: true,
      errors: {
        hotelName: "",
      },
    });
  };
  toolbarItemRender = () => {
    return (
      <Fragment>
        <div className="row">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.openHotelModel}
          >
            <span title="Submit">
              <i className="fas fa-plus"></i>&nbsp;Add Hotel
            </span>
          </button>
        </div>
      </Fragment>
    );
  };
  // handleRowPrepared = (e) => {

  //     if (e.rowType === 'data') {
  //         if (e.data.validData === false) {
  //             e.rowElement.style.color = '#FF0000';
  //         }

  //     }
  // }
  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.hotelGridDataSource}
              keyExpr="id"
              selection={{ mode: "single" }}
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
              //onRowPrepared={this.handleRowPrepared}
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Column dataField="id" caption="Hotel Id" width={90} />
              <Column dataField="name" caption="Hotel Name" width={200} />
              <Column
                dataField="contactNumber"
                caption="Contact Number"
                width={130}
              />
              <Column
                dataField="postalCode"
                caption="Postal Code"
                width={120}
              />
              <Column dataField="streetName" caption="Address" width={300} />
              <Column dataField="city" caption="City" />
              <Column dataField="state" caption="State" />
              <Column dataField="latitude" caption="Latitude" />
              <Column dataField="longitude" caption="Longitude" />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              <Template
                name="toolbarTemplate"
                render={this.toolbarItemRender}
              />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Hotels"
              />
            </DataGrid>
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.hotelModelIsOpen}
          style={CustomStyles}
          className={"react-modal"}
          onAfterOpen={this.afterOpenviewEditModal}
          onRequestClose={this.editBaseLocationResetStateData}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleHotelSubmit}
            style={
              this.state.isSubmited
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header">
              <h6 className="modal-title">Add Hotel</h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeUserModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group">
                    <label>Hotel</label>
                    <SelectBox
                      dataSource={this.state.hotelDropdownList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Hotel"
                      name="hotelName"
                      //defaultValue={0}
                      value={this.state.hotelName}
                      displayExpr="hotelName"
                      valueExpr="hotelName"
                      onSelectionChanged={this.handleHotelChange}
                      showClearButton={true}
                      disabled={false}
                      searchEnabled={true}
                    />
                    {this.state.errors["hotelName"].length > 0 && (
                      <span className="error invalid-feedback">
                        {this.state.errors["hotelName"]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={this.handleHotelSubmit}
              >
                {this.state.isSubmited && (
                  <span title="In-process">
                    <i className="fas fa-spinner fa-spin"></i> In-process
                  </span>
                )}
                {!this.state.isSubmited && (
                  <span title="Save">
                    <i className="fas fa-check"></i> Save
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-custom-gray "
                onClick={this.closeUserModal}
                title="Close"
              >
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
