import React from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { Component } from "devextreme-react/core/component";
import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { symbolFormats, precisionFormats } from "../../helpers/fixcodes.js";

export class ChildBookingDataComponent extends Component {
  static displayName = ChildBookingDataComponent.name;

  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
      bookingGuestGridDataSource:
        props?.bookingGuestData?.filter(
          (f) => f.bookingReferenceNo === props.bookingReferenceNo
        ) ?? [],
      bookingRateGridDataSource:
        props?.bookingRates?.filter(
          (f) => f.bookingReferenceNo === props.bookingReferenceNo
        ) ?? [],
      bookingReferenceNo: props?.bookingReferenceNo ?? "",
    };
    this.renderGuestTab = this.renderGuestTab.bind(this);
    this.renderBookingRateTab = this.renderBookingRateTab.bind(this);
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    return false;
  }
  handleOnSelectionTab = (e) => {
    try {
      if (e.addedItems[0].title === "Booking Rate") {
        this.setState({
          selectedTabIndex: 1,
        });
      } else {
        this.setState({
          selectedTabIndex: 0,
        });
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnSelectionTab function",
        error
      );
    }
  };

  handleOnExportToExcel = async (e) => {
    e.preventDefault();
    try {
      if (this.state.selectedTabIndex === 0) {
        this.refGuestDataGrid.instance.option(
          "export.fileName",
          "HotelBookingGuest"
        );
        this.refGuestDataGrid.instance.exportToExcel(false);
      } else {
        this.refRateDataGrid.instance.option(
          "export.fileName",
          "HotelBookingRate"
        );
        this.refRateDataGrid.instance.exportToExcel(false);
      }
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnExportToExcel function",
        error
      );
    }
  };

  handleOnReplaceGuest = async (e, data) => {
    e.preventDefault();
    try {
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnReplaceGuest function",
        error
      );
    }
  };

  render() {
    return (
      <>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="form-group">
            {/* <button type="button" className="btn btn-primary">
              <span title="Add New Guest">
                <i className="fas fa-plus"></i> Add New Guest
              </span>
            </button>
            &nbsp;&nbsp;
            <button type="button" className="btn btn-danger">
              <span title="Cancel Booking">
                <i className="fas fa-trash-alt"></i> Cancel Booking
              </span>
            </button>
            &nbsp;&nbsp; */}
            <button
              style={{ cursor: "pointer" }}
              type="button"
              //className="btn btn-link"
              className="btn btn-success"
              onClick={(e) => {
                this.handleOnExportToExcel(e);
              }}
            >
              <span title="Export">
                <i className="fas fa-download"></i> Export
              </span>
            </button>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <TabPanel
            id="tbBooking"
            //loop={true}
            //swipeEnabled={true}
            animationEnabled={true}
            onSelectionChanged={(e) => {
              this.handleOnSelectionTab(e);
            }}
          >
            <Item title="Guest" render={this.renderGuestTab} />
            <Item title="Booking Rate" render={this.renderBookingRateTab} />
          </TabPanel>
        </div>
      </>
    );
  }

  renderGuestTab() {
    const renderGuestResponse = (data) => {
      return (
        <a
          href="!#"
          title="Replace"
          onClick={(e) => {
            this.handleOnReplaceGuest(e, data.data);
          }}
        >
          <i className="fas fa-edit"></i>
          Replace
        </a>
      );
    };
    return (
      <>
        <DataGrid
          columnAutoWidth={true}
          dataSource={this.state.bookingGuestGridDataSource}
          selection={{ mode: "single" }}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          ref={(ref) => (this.refGuestDataGrid = ref)}
        >
          <Column
            visible={false}
            dataField=""
            cellRender={renderGuestResponse}
          />
          <Column dataField="employeeNumber" caption="Emp #" />
          <Column dataField="crewFirstName" caption="Crew First Name" />
          <Column dataField="crewLastName" caption="Crew Last Name" />
          <Column dataField="crewGender" caption="Gender" />
          <Column dataField="airlineCrewType" caption="Crew Type" />
          <Column dataField="crewTypeCategory" caption="Crew Category" />
          <Column dataField="amendType" caption="Guest Type" />
          <Column dataField="tripId" caption="Trip #" />
          <Column
            dataField="checkinTime"
            caption="Check In Time"
            dataType="datetime"
            format="MM/dd/yyyy HH:mm:ss"
          />
          <Column
            dataField="checkoutTime"
            caption="Check Out Time"
            dataType="datetime"
            format="MM/dd/yyyy HH:mm:ss"
          />
          <Column dataField="hotelTripCancelledStatus" caption="Trip Cancel" />
          <Column
            dataField="HotelTripCancelledDateTime"
            caption="Trip Cancelled Time"
            dataType="datetime"
            format="MM/dd/yyyy HH:mm:ss"
          />
          <Column dataField="nameAmendedStatus" caption="Amended" />
          <Column
            dataField="nameAmendedDateTime"
            caption="Amended Time"
            dataType="datetime"
            format="MM/dd/yyyy HH:mm:ss"
          />
          <Column dataField="prevReplacedEmployeeNo" caption="Replaced Emp #" />
          <Column
            dataField="createdDate"
            caption="Created At"
            dataType="datetime"
            format="MM/dd/yyyy HH:mm:ss"
          />
        </DataGrid>
      </>
    );
  }
  renderBookingRateTab() {
    const renderCurrencyFormat = (data) => {
      return (
        <div>
          <small>
            <i
              className={
                data.data.purchaseCurrency === "INR"
                  ? symbolFormats.ruppesFormat
                  : symbolFormats.dollarFormat
              }
            ></i>
          </small>
          &nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[3])}
        </div>
      );
    };
    return (
      <>
        <DataGrid
          columnAutoWidth={true}
          dataSource={this.state.bookingRateGridDataSource}
          selection={{ mode: "single" }}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          ref={(ref) => (this.refRateDataGrid = ref)}
        >
          <Column dataField="roomTypeName" caption="Room Type" />
          <Column dataField="rateCode" caption="Rate Code" />
          <Column dataField="purchaseCurrency" caption="Currency" />
          <Column
            dataField="netPricePurchaseCurrency"
            caption="Net Purchase"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="taxAndChargesPurchaseCurrency"
            caption="Tax Purchase"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="netPriceRequestedCurrency"
            caption="Net Requested"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="grossPriceRequestedCurrency"
            caption="gross Requested"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="taxAndChargesRequestedCurrency"
            caption="Tax Requested"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="totalNetPriceRequestedCurrency"
            caption="Total Net"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="totalGrossPriceRequestedCurrency"
            caption="Total Gross"
            cellRender={renderCurrencyFormat}
          />
          <Column
            dataField="totalTaxAndChargesRequestedCurrency"
            caption="Total Tax"
            cellRender={renderCurrencyFormat}
          />
        </DataGrid>
      </>
    );
  }
}
