import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import moment from "moment";
import DateBox from "devextreme-react/date-box";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import { LoadPanel } from "devextreme-react/load-panel";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { LocateVehicleComponent } from "../Shared/LocateVehicleComponent";
import { ReplayVehicleComponent } from "../Shared/ReplayVehicleComponent";

const status = [
  { id: 0, name: "All" },
  { id: 1, name: "UpComing" },
  { id: 2, name: "Current" },
  { id: 3, name: "Completed" },
  { id: 4, name: "Cancelled" },
];
const position = { of: "#historydiv" };
let currentTime =
  sessionStorage.getItem("session_locationTime") === ""
    ? new Date()
    : new Date(sessionStorage.getItem("session_locationTime"));
export class ReservationsComponent extends Component {
  static displayName = ReservationsComponent.name;
  currentTime =
    sessionStorage.getItem("session_locationTime") === ""
      ? new Date()
      : new Date(sessionStorage.getItem("session_locationTime"));
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      dataUpComing: null,
      dataCurrent: null,
      dataCompleted: null,
      dataCancel: null,
      dropdownStatus: [],
      AccountList: [],
      rideDetailsList: [],
      singleData: null,
      loadPanelVisible: false,
      rideDetailsModal: false,
      statusTypeId: 0,
      filterFromDatetime: new Date(),
      filterToDatetime: moment(currentTime).format("MM/DD/YYYY") + " 23:59",
      accountCode: "",
      airportList: [],
      airportId: 0,
      airportCode: "",
      rideId: 0,
      isOpenTrackModal: false,
      rideDetails: {
        name: "",
        phnNo: "",
        cellNo: "",
        email: "",
        noOfPilot: 0,
        noOfFa: 0,
        noOfCrew: 0,
        vehicleType: "",
        vehicleCapacity: "",
        puAddress: "",
        doAddress: "",
        schPickUp: "",
        actualPickUp: "",
        actualDropOff: "",
        flightDatetime: "",
        airline: "",
        flightNo: "",
        flightType: "",
      },
      errors: {
        accountCode: "",
        statusTypeId: 0,
      },
    };
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        onClick: this.getReservations.bind(this),
      },
    });
  };
  async componentDidMount() {
    await this.getAirports();
    await this.getReservations();
    await this.getStatusDropdownList();
    //await this.getAccountList();
  }
  // getReservations = async () => {
  //     this.setState({
  //         loadPanelVisible: true
  //     });
  //     try {
  //         let airlineCode = sessionStorage.getItem('session_airlineCode');
  //         let UserId = sessionStorage.getItem('session_userId');
  //         let UserName = sessionStorage.getItem('session_userName');
  //         var request = {};
  //         request.AirlineCode = airlineCode;
  //         request.UserId = UserId;
  //         request.UserName = UserName;
  //         request.StatusId = this.state.statusTypeId;
  //         request.AccountCode = this.state.accountCode;
  //        // request.RideDate = "06-14-2022"
  //          request.RideDate = formatDate(this.state.filterFromDatetime, 'MM/dd/yyyy')
  //         const requestParams = getRequestParams("POST", request);

  //         const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'Rides', requestParams);
  //         const data = await response.json();
  //         this.setState({
  //             datasource: data.payload,
  //             loadPanelVisible: false
  //         });

  //     } catch (err) {
  //         console.log("Error in " + this.displayName + ".getAirports function", err);
  //         this.setState({
  //             loadPanelVisible: false
  //         });
  //     }
  // }
  getAirports = async () => {
    this.setState({ loadPanelVisible: true });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getStatusDropdownList = async () => {
    try {
      this.setState({ dropdownStatus: status });
    } catch (ex) {
      console.log(
        "Error in " + this.displayName + ".getStatusDropdownList function",
        ex
      );
    }
  };
  handleOnFilteFromDateChanged = (date) => {
    this.setState({ filterFromDatetime: date.value });
  };
  handleOnFilterTripStatusChange = (e) => {
    this.setState({
      statusTypeId: e.selectedItem !== null ? e.selectedItem.id : 0,
    });
    let errors = this.state.errors;
    errors.statusTypeId = "";
    this.setState({ errors: errors });
  };
  getReservations = async () => {
    try {
      var request = {};
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      request.UserId = sessionStorage.getItem("session_userId");
      request.AirlineCode = airlineCode;
      request.StatusId = Number(this.state.statusTypeId);
      request.FromDate =
        formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 00:00:00";
      request.ToDate =
        formatDate(this.state.filterFromDatetime, "MM/dd/yyyy") + " 23:59:59";
      request.AirportCode = this.state.airportCode;
      const requestParams = getRequestParams("POST", request);
      this.setState({
        loadPanelVisible: true,
      });
      let url = process.env.REACT_APP_API_BASE_URL + "Rides/AirlineRides";
      const response = await fetch(url, requestParams);
      const data = await response.json();
      var allRides = [];
      var currentRides = [];
      var completedRides = [];
      var upComingRides = [];
      var cancelledRides = [];
      //if (data.payload != null)
      {
        allRides = data.payload != null ? data.payload : [];
        upComingRides = allRides.filter((e) => e.tripStatusId === 101);
        currentRides = allRides.filter(
          (e) =>
            e.tripStatusId === 102 ||
            e.tripStatusId === 104 ||
            e.tripStatusId === 107 ||
            e.tripStatusId === 108
        );
        completedRides = allRides.filter(
          (e) => e.tripStatusId === 116 || e.tripStatusId === 109
        );
        cancelledRides = allRides.filter((e) => e.tripStatusId === 111);
        this.setState({
          datasource: allRides,
          dataUpComing: upComingRides,
          dataCurrent: currentRides,
          dataCompleted: completedRides,
          dataCancel: cancelledRides,
          loadPanelVisible: false,
        });
      }
      this.setState({ loadPanelVisible: false });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getReservations function",
        error
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  getAccountList = async () => {
    try {
      let userId = sessionStorage.getItem("session_userId");
      let userName = sessionStorage.getItem("session_userName");
      const requestParams = getRequestParams("GET");
      this.setState({
        loadPanelVisible: true,
      });
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
          "Account/" +
          userId +
          "/" +
          userName,
        requestParams
      );
      const data = await response.json();
      this.setState({
        AccountList: data.payload,
        loadPanelVisible: false,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getAccountList function",
        error
      );
    }
  };
  handleOnFilterAccountChange = (e) => {
    this.setState({
      accountCode: e.selectedItem !== null ? e.selectedItem.accountCode : "",
    });
    let errors = this.state.errors;
    errors.accountCode = "";
    this.setState({ errors: errors });
  };
  handleValidation = () => {
    let formIsValid = true;
    try {
      let errors = this.state.errors;
      errors.accountCode = "";
      errors.statusTypeId = "";
      if (this.state.accountCode.trim() === "") {
        formIsValid = false;
        errors.accountCode = "Please enter Account.";
      }

      this.setState({ errors: errors });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleValidation function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };
  getRideDetails = async (e, rideData) => {
    e.preventDefault();
    try {
      let rideId = rideData.rideId;
      this.setState({ rideId: rideData.rideId });
      const requestParams = getRequestParams("GET");
      this.setState({
        loadPanelVisible: true,
      });
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Rides/" + rideId,
        requestParams
      );
      const data = await response.json();
      this.setState({
        rideDetailsList: data.payload,
        loadPanelVisible: false,
      });

      this.setState({
        rideDetailsModal: true,
        singleData: this.state.rideDetailsList,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getRideDetails function",
        error
      );
    }
  };
  trackVehicle = (e, data) => {
    e.preventDefault();
    try {
      this.refs.locateVehicleModal.showModal(data.vehicleNo, data.rideId);
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".locateVehicle function",
        error
      );
    }
  };
  replayVehicle = (e, data) => {
    e.preventDefault();
    try {
      this.refs.replayVehicleModel.showModal(
        data.rideId,
        data.fmVehicleId,
        data.onLocationTime,
        data.onCompletedTime
      );
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".replayVehicle function",
        error
      );
    }
  };
  handleAssignDataToState = async () => {
    if (this.state.rideDetailsList === null) {
      this.setState({
        rideDetails: {
          name: "",
          phnNo: "",
          cellNo: "",
          email: "",
          noOfPilot: 0,
          noOfFa: 0,
          noOfCrew: 0,
          vehicleType: "",
          vehicleCapacity: "",
          puAddress: "",
          doAddress: "",
          schPickUp: "",
          actualPickUp: "",
          actualDropOff: "",
          flightDatetime: "",
          airline: "",
          flightNo: "",
          flightType: "",
        },
      });
    } else {
      const ride = this.state.singleData;
      let rideDetails = this.state.rideDetails;
      rideDetails.name = ride.firstName + " " + ride.lastName;
      rideDetails.phnNo = ride.phoneNo;
      rideDetails.email = ride.email;
      // rideDetails.noOfPilot = ride.;
      // rideDetails.noOfFa = ride[0].;
      rideDetails.noOfCrew = ride.adultNo;
      rideDetails.vehicleType = ride.vehicleType;
      rideDetails.vehicleCapacity = ride.capacity;
      rideDetails.puAddress = ride.puStreet;
      rideDetails.doAddress = ride.doStreet;
      rideDetails.schPickUp = ride.pickUpDateTime;
      rideDetails.actualPickUp = ride.timeLoad == null ? "" : ride.timeLoad;
      rideDetails.actualDropOff = ride.timeUpload;
      rideDetails.flightDatetime = ride.flightDateTime;
      rideDetails.airline = ride.airlineName;
      rideDetails.flightTerminal = ride.flightTerminal;
      rideDetails.flightNo = ride.flightNo;
      rideDetails.flightType = ride.flightType;
      this.setState({
        rideDetails: rideDetails,
      });
    }
  };
  handleResetStateData = () => {
    this.setState({
      isSubmited: false,
      loadPanelVisible: false,
      rideDetailsModal: false,
      statusTypeId: 0,
      filterFromDatetime: new Date(),
      rideDetails: {
        name: "",
        phnNo: "",
        cellNo: "",
        email: "",
        noOfPilot: 0,
        noOfFa: 0,
        noOfCrew: 0,
        vehicleType: "",
        vehicleCapacity: "",
        puAddress: "",
        doAddress: "",
        schPickUp: "",
        actualPickUp: "",
        actualDropOff: "",
        flightDatetime: "",
        airline: "",
        flightNo: "",
        flightType: "",
      },
      errors: {
        accountCode: "",
        statusTypeId: 0,
      },
    });
  };
  handleRowPrepared = (e) => {
    if (e.rowType === "data") {
      if (e.data.rideStatus === "Pending") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "CANCELLED") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "Driver En Route") {
        e.rowElement.style.backgroundColor = "#FFFB77";
      } else if (e.data.rideStatus === "Vehicle unassigned") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "Driver Rejected Job") {
        e.rowElement.style.backgroundColor = "#f2f2f2";
      } else if (e.data.rideStatus === "On Location") {
        e.rowElement.style.backgroundColor = "#FFFF01";
      } else if (e.data.rideStatus === "Boarded") {
        e.rowElement.style.backgroundColor = "#D4FFD4";
      } else if (e.data.rideStatus === "NO SHOW") {
        e.rowElement.style.backgroundColor = "#eabdf5";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "NO SHOW - NON BILLABLE") {
        e.rowElement.style.backgroundColor = "#eabdf5";
        e.rowElement.style.foregroundColor = "#ffffff";
      } else if (e.data.rideStatus === "Ride Complete") {
        e.rowElement.style.backgroundColor = "#01B050";
        e.rowElement.style.foregroundColor = "#ffffff";
      }
    }
  };
  handleAirportChange = async (e) => {
    this.setState({
      airportId: e.selectedItem != null ? e.selectedItem.airportId : 0,
      airportCode: e.selectedItem != null ? e.selectedItem.airportCode : "",
    });
  };
  displayExpr = (item) => {
    if (item != null) {
      return item.airportCode;
    } else {
      return "Select Airport";
    }
  };
  render() {
    const renderLocateVehicle = (data) => {
      return (
        <div>
          <center>
            <a
              href="#"
              style={{ color: "blue" }}
              title="Locate Vehicle"
              onClick={(e) => {
                this.trackVehicle(e, data.data);
              }}
            >
              <i className="fas fa-car"></i>
            </a>
          </center>
        </div>
      );
    };
    const renderReplayVehicle = (data) => {
      return (
        <div>
          <center>
            <a
              href="#"
              style={{ color: "blue" }}
              title="Replay Vehicle"
              onClick={(e) => {
                this.replayVehicle(e, data.data);
              }}
            >
              <i className="far fa-play-circle"></i>
            </a>
          </center>
        </div>
      );
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterFromDatetime"
                    placeholder="MM/dd/yyyy"
                    displayFormat="MM/dd/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterFromDatetime}
                    value={this.state.filterFromDatetime}
                    onValueChanged={this.handleOnFilteFromDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>Airport(s)</label>
                  <SelectBox
                    dataSource={this.state.airportList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="airportCode"
                    defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    //displayExpr={this.displayExpr}
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              {/* <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                                <div className="form-group">
                                    <label>Trip Status</label>
                                    <SelectBox
                                        dataSource={this.state.dropdownStatus}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Trip Status"
                                        defaultValue={0}
                                        value={this.state.statusTypeId}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onSelectionChanged={this.handleOnFilterTripStatusChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                        width="100%"
                                    />                                    
                                </div>
                            </div> */}
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 displayNone">
                <div className="form-group">
                  <label>Account(s)</label>
                  <SelectBox
                    dataSource={this.state.AccountList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Account"
                    //defaultValue={0}
                    value={this.state.accountCode}
                    valueExpr="accountCode"
                    displayExpr="serviceAccountName"
                    onSelectionChanged={this.handleOnFilterAccountChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                    width="100%"
                  />
                  {this.state.errors["accountCode"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["accountCode"]}
                    </span>
                  )}
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-6 col-md-2 col-lg-2"
                style={{ marginTop: "15px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.getReservations}
                >
                  {this.state.isSubmitted && (
                    <span title="In-process">
                      <i className="fas fa-spinner fa-spin"></i> In-process
                    </span>
                  )}
                  {!this.state.isSubmitted && (
                    <span title="Search">
                      <i className="fas fa-search"></i> Search
                    </span>
                  )}
                </button>{" "}
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-2"
                id="custom-tabs-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-upcoming-tab"
                    data-toggle="pill"
                    href="#custom-tabs-upcoming"
                    role="tab"
                    aria-controls="custom-tabs-upcoming"
                    aria-selected="true"
                    value={0}
                  >
                    Upcoming
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-current-tab"
                    data-toggle="pill"
                    href="#custom-tabs-current"
                    role="tab"
                    aria-controls="custom-tabs-current"
                    aria-selected="false"
                    value={1}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-completed-tab"
                    data-toggle="pill"
                    href="#custom-tabs-completed"
                    role="tab"
                    aria-controls="custom-tabs-completed"
                    aria-selected="false"
                    value={2}
                  >
                    Completed
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-cancelled-tab"
                    data-toggle="pill"
                    href="#custom-tabs-cancelled"
                    role="tab"
                    aria-controls="custom-tabs-cancelled"
                    aria-selected="false"
                    value={2}
                  >
                    Cancelled
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-upcoming"
                role="tabpanel"
                aria-labelledby="custom-tabs-upcoming-tab"
              >
                <DataGrid
                  dataSource={this.state.dataUpComing}
                  //className ={(this.state.singleData)= "Ride Complete" ? 'grd-row': 'grd-row'
                  keyExpr="rideId"
                  //selection={{ mode: 'single' }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  onRowPrepared={this.handleRowPrepared}
                  onRowInserting={this.insertRow}
                  columnResizingMode="widget"
                >
                  {/* <Column dataField="rideId" caption="GT Provider Name " allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderColorGridCell} /> */}
                  <Column
                    dataField="filghtDetails"
                    caption="Flt. No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="accountName"
                    caption="Transport Company"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="employeeNo"
                    caption="EmployeeNo"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="paxName"
                    caption="Crew"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Rank"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="puStreet"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="doStreet"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  {/* <Column caption="Address Details">
                                <Column dataField="puStreet" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="doStreet" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            </Column> */}
                  <Column
                    dataField="vehicleNo"
                    caption="Vehicle"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="driverName"
                    caption="Driver"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="otpVerifiedStatus"
                    caption="OTP Verify Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverWorkHours"
                    caption="Work Hrs"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="flightDateTime"
                    caption="Flight Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={110}
                  />
                  {/* <Column dataField="gmtFlightDateTime" caption="Pickup(GMT)" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={100} /> */}
                  <Column
                    dataField="pickUpDateTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={120}
                    sortOrder="asc"
                  />
                  <Column
                    dataField="dutyReportingTime"
                    caption=" Duty Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  <Column
                    dataField="deBriefingTime"
                    caption="Debriefing Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  {/* <Column dataField="flightType" caption="Flight Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  {/* <Column dataField="flightTerminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  {/* <Column dataField="eta" caption="ETA" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={50} />
                                    <Column dataField="timeLoad" caption="Load Time" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format="HH:mm" width={80} />
                                    <Column dataField="onSite" caption="Loc. Time" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={75} />
                                    <Column dataField="timeLoad" caption="Dropoff Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} /> */}
                  <Column
                    dataField="rideStatus"
                    caption="Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={70}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Reservation"
                  />
                </DataGrid>
              </div>

              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-current"
                role="tabpanel"
                aria-labelledby="custom-tabs-current-tab"
              >
                <DataGrid
                  dataSource={this.state.dataCurrent}
                  //className ={(this.state.singleData)= "Ride Complete" ? 'grd-row': 'grd-row'
                  keyExpr="rideId"
                  //selection={{ mode: 'single' }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  onRowPrepared={this.handleRowPrepared}
                  onRowInserting={this.insertRow}
                  columnResizingMode="widget"
                >
                  {/* <Column dataField="rideId" caption="GT Provider Name " allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderColorGridCell} /> */}
                  <Column
                    dataField=""
                    caption="Locate Vehicle"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                    cellRender={renderLocateVehicle}
                  />
                  <Column
                    dataField="filghtDetails"
                    caption="Flt. No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="accountName"
                    caption="Transport Company"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="employeeNo"
                    caption="Employee No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="paxName"
                    caption="Crew"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Rank"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="puStreet"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="doStreet"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  {/* <Column caption="Address Details">
                                <Column dataField="puStreet" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="doStreet" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            </Column> */}
                  <Column
                    dataField="vehicleNo"
                    caption="Vehicle"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverName"
                    caption="Driver"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="otpVerifiedStatus"
                    caption="OTP Verify Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverWorkHours"
                    caption="Work Hrs"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="flightDateTime"
                    caption="Flight Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={110}
                  />
                  {/* <Column dataField="gmtFlightDateTime" caption="Pickup(GMT)" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={100} /> */}
                  <Column
                    dataField="eta"
                    caption="ETA"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={50}
                  />
                  <Column
                    dataField="pickUpDateTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={120}
                    sortOrder="asc"
                  />
                  <Column
                    dataField="dutyReportingTime"
                    caption="Duty Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={180}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  <Column
                    dataField="deBriefingTime"
                    caption="Debriefing Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  {/* <Column dataField="flightType" caption="Flight Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  {/* <Column dataField="flightTerminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  <Column
                    dataField="onSite"
                    caption="On Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={90}
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Boarded"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format="HH:mm"
                    width={80}
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Dropped"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="rideStatus"
                    caption="Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  {/* <Column dataField="dutyReportingTime" caption="Reporting Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}  dataType="datetime" format="dd/MM/yyyy HH:mm"/>
                                        <Column dataField="deBriefingTime" caption="Dedreifing Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" /> */}
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Reservation"
                  />
                </DataGrid>
              </div>

              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-completed"
                role="tabpanel"
                aria-labelledby="custom-tabs-completed-tab"
              >
                <DataGrid
                  dataSource={this.state.dataCompleted}
                  //className ={(this.state.singleData)= "Ride Complete" ? 'grd-row': 'grd-row'
                  keyExpr="rideId"
                  //selection={{ mode: 'single' }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  onRowPrepared={this.handleRowPrepared}
                  //onRowInserting={this.insertRow}
                  columnResizingMode="widget"
                >
                  <Column
                    dataField="rideId"
                    caption=""
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={50}
                    cellRender={renderReplayVehicle}
                  />
                  <Column
                    dataField="filghtDetails"
                    caption="Flt. No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="accountName"
                    caption="Transport Company"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="employeeNo"
                    caption="Employee No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="paxName"
                    caption="Crew"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Rank"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="puStreet"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="doStreet"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  {/* <Column caption="Address Details">
                                <Column dataField="puStreet" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="doStreet" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            </Column> */}
                  <Column
                    dataField="vehicleNo"
                    caption="Vehicle"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverName"
                    caption="Driver"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="otpVerifiedStatus"
                    caption="OTP Verify Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverWorkHours"
                    caption="Work Hrs"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="flightDateTime"
                    caption="Flight Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={100}
                  />
                  {/* <Column dataField="gmtFlightDateTime" caption="Pickup(GMT)" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={100} /> */}
                  <Column
                    dataField="eta"
                    caption="ETA"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={50}
                  />
                  <Column
                    dataField="pickUpDateTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={120}
                    sortOrder="asc"
                  />
                  {/* <Column dataField="flightType" caption="Flight Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  {/* <Column dataField="flightTerminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  <Column
                    dataField="dutyReportingTime"
                    caption="Duty Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={180}
                    dataType="datetime"
                  />
                  <Column
                    dataField="deBriefingTime"
                    caption="Debriefing Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={130}
                    dataType="datetime"
                  />
                  <Column
                    dataField="onSite"
                    caption="On Location"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={90}
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Boarded"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format="HH:mm"
                    width={80}
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Dropped"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="rideStatus"
                    caption="Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  {/* <Column dataField="dutyReportingTime" caption="Reporting Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={180}  dataType="datetime" format="dd/MM/yyyy HH:mm"/>
                                        <Column dataField="deBriefingTime" caption="Dedreifing Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={180} dataType="datetime" format="dd/MM/yyyy HH:mm" />                                    <SortByGroupSummaryInfo summaryItem="count" /> */}
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Reservation"
                  />
                </DataGrid>
              </div>
              <div
                className="tab-pane fade"
                id="custom-tabs-cancelled"
                role="tabpanel"
                aria-labelledby="custom-tabs-cancelled-tab"
              >
                <DataGrid
                  dataSource={this.state.dataCancel}
                  keyExpr="rideId"
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  onRowPrepared={this.handleRowPrepared}
                  columnResizingMode="widget"
                >
                  {/* <Column dataField="rideId" caption="GT Provider Name" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderColorGridCell} /> */}
                  <Column
                    dataField="filghtDetails"
                    caption="Flt. No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="accountName"
                    caption="Transport Company"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="employeeNo"
                    caption="Employee No"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="paxName"
                    caption="Crew"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={200}
                  />
                  <Column
                    dataField="crewPosition"
                    caption="Rank"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="puStreet"
                    caption="Pickup"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  <Column
                    dataField="doStreet"
                    caption="Dropoff"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={250}
                  />
                  {/* <Column caption="Address Details">
                                <Column dataField="puStreet" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                                <Column dataField="doStreet" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            </Column> */}
                  <Column
                    dataField="vehicleNo"
                    caption="Vehicle"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverName"
                    caption="Driver"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="otpVerifiedStatus"
                    caption="OTP Verify Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="driverWorkHours"
                    caption="Work Hrs"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="flightDateTime"
                    caption="Flight Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={100}
                  />
                  {/* <Column dataField="gmtFlightDateTime" caption="Pickup(GMT)" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={100} /> */}
                  <Column
                    dataField="eta"
                    caption="ETA"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={50}
                  />
                  <Column
                    dataField="pickUpDateTime"
                    caption="Pickup Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={120}
                    sortOrder="asc"
                  />
                  {/* <Column dataField="flightType" caption="Flight Type" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  {/* <Column dataField="flightTerminal" caption="Terminal" allowSearch={true} allowFiltering={false} allowSorting={true} width={130} /> */}
                  <Column
                    dataField="dutyReportingTime"
                    caption="Duty Reporting Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={180}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  <Column
                    dataField="deBriefingTime"
                    caption="Dedreifing Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={180}
                    dataType="datetime"
                    format="dd/MM/yyyy HH:mm"
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Load Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format="HH:mm"
                    width={80}
                  />
                  <Column
                    dataField="onSite"
                    caption="Loc. Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    dataType="datetime"
                    format=" HH:mm"
                    width={75}
                  />
                  <Column
                    dataField="timeLoad"
                    caption="Dropoff Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="rideStatus"
                    caption="Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Reservation"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card">
                    <div className="card-body">
                        <DataGrid
                            dataSource={this.state.datasource}
                            keyExpr="rideId"
                            selection={{ mode: 'single' }}
                            columnsAutoWidth={false}
                            showColumnLines={true}
                            showRowLines={true}
                            showBorders={true}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            onRowPrepared={this.handleRowPrepared}
                            onRowInserting={this.insertRow}
                            columnResizingMode='widget'>
                            <Column dataField="filghtDetails" caption="Flt. No" allowSearch={true} allowFiltering={false} allowSorting={true} width={80} />
                            <Column dataField="accountName" caption="Transport Company" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="paxName" caption="Crew" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} cellRender={renderGridCell} />
                            <Column dataField="puStreet" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="doStreet" caption="Dropoff" allowSearch={true} allowFiltering={false} allowSorting={true} width={150} />
                            <Column dataField="vehicleNo" caption="Vehicle" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                            <Column dataField="flightDateTime" caption="Pickup" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={60} />
                            <Column dataField="gmtFlightDateTime" caption="Pickup(GMT)" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={100} />
                            <Column dataField="eta" caption="ETA" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={50} />
                            <Column dataField="pickUpDateTime" caption="ATA" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={50} />
                            <Column dataField="timeLoad" caption="Load Time" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format="HH:mm" width={80} />
                            <Column dataField="onSite" caption="Loc. Time" allowSearch={true} allowFiltering={false} allowSorting={true} dataType="datetime" format=" HH:mm" width={75} />
                            <Column dataField="timeLoad" caption="Dropoff Time" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                            <Column dataField="rideStatus" caption="Status" allowSearch={true} allowFiltering={false} allowSorting={true} width={100} />
                            <SortByGroupSummaryInfo summaryItem="count" />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={true} width={240} placeholder="Search..." />
                            <Grouping autoExpandAll={true} />
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} visible={true} />
                            <Export enabled={true} allowExportSelectedData={false} fileName="Reservation" />
                        </DataGrid>
                    </div>
                </div> */}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.rideDetailsModal}
          style={{ content: { width: "65%" } }}
          className={"react-modal"}
          onAfterOpen={this.handleAssignDataToState}
          onRequestClose={this.handleResetStateData}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <form
            method="post"
            onSubmit={this.handleRoutesSubmit}
            style={
              this.state.isSubmitted
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
          >
            <div className="modal-header" style={{ backgound: "pink" }}>
              <h6 className="modal-title">Ride Id:{this.state.rideId} </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleResetStateData}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div>
                  <h6 className="panel-title">&nbsp;Passenger Details</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <label className="value-title">Name: </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <label className="label-Color">
                        {this.state.rideDetails.name}
                      </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="value-title">Phone Number: </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <label className="label-Color">
                        {this.state.rideDetails.phnNo}
                      </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="value-title">Cell Phone Number: </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="label-Color">
                        {this.state.rideDetails.cellNo}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="value-title">Email: </label>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="label-Color">
                        {this.state.rideDetails.email}
                      </label>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-2">
                      <label className="value-title">Number of Crew: </label>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3">
                      <label className="label-Color">
                        {this.state.rideDetails.noOfCrew}
                      </label>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="value-title">Number of Pilot:</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="label-Color">{this.state.rideDetails.noOfPilot}</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="value-title">Number of F/A:</label>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="label-Color">{this.state.rideDetails.noOfFa}</label>
                                        </div> */}
                  </div>
                  <div className="row">
                    {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                            <label className="value-title">Number of Crew:</label>
                                        </div> */}
                    {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                            <label className="label-Color">{this.state.rideDetails.noOfCrew}</label>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="card">
                <div>
                  <h6 className="panel-title">&nbsp;Ride Details</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <label className="value-title">Pickup Address: </label>
                    </div>
                    <div className="col-xs-5 col-sm-5 col-md-5">
                      <label className="label-Color">
                        {this.state.rideDetails.puAddress}{" "}
                      </label>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                      <label className="value-title">Drop Off Address: </label>
                    </div>
                    <div className="col-xs-5 col-sm-5 col-md-5">
                      <label className="label-Color">
                        {this.state.rideDetails.doAddress}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <label className="value-title">
                        Vehicle Type: {this.state.rideDetails.vehicleType}
                      </label>
                    </div>
                    {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                            <label className="label-Color">{this.state.rideDetails.vehicleType}</label>
                                        </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <label className="value-title">
                        Vehicle Capacity:{" "}
                        {this.state.rideDetails.vehicleCapacity}
                      </label>
                    </div>
                    {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                            <label className="label-Color">{this.state.rideDetails.vehicleCapacity}</label>
                                        </div> */}
                  </div>

                  {/* <div className="row">
                                      
                                    </div> */}
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label className="value-title">
                        Schedule Pickup: &nbsp;
                        {formatDate(
                          this.state.rideDetails.schPickUp,
                          "dd/MM/yyyy hh:mm:ss"
                        )}
                      </label>
                    </div>
                    {/* <div className="col-xs-6 col-sm-6 col-md-2">
                                            <label className="label-Color">{formatDate(this.state.rideDetails.schPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label className="value-title">Actual Pickup:&nbsp;{formatDate(this.state.rideDetails.actualPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                    {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                            <label className="label-Color">{formatDate(this.state.rideDetails.actualPickUp, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label className="value-title">Actual Drop Off:&nbsp;{formatDate(this.state.rideDetails.actualDropOff, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                    {/* <div className="col-xs-6 col-sm-6 col-md-3">
                                            <label className="label-Color">{formatDate(this.state.rideDetails.actualDropOff, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="card">
                <div>
                  <h6 className="panel-title">&nbsp;Flight Details</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                      <label className="value-title">
                        Flight Date Time:{" "}
                        {formatDate(
                          this.state.rideDetails.flightDatetime,
                          "dd/MM/yyyy hh:mm:ss"
                        )}
                      </label>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="label-Color">{formatDate(this.state.rideDetails.flightDatetime, "dd/MM/yyyy hh:mm:ss")}</label>
                                        </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                      <label className="value-title">
                        Terminal: {this.state.rideDetails.flightTerminal}
                      </label>
                    </div>
                    {/* <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                            <label className="label-Color">{this.state.rideDetails.airline} </label>
                                        </div> */}

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <label className="value-title">
                        Flight Number: {this.state.rideDetails.flightNo}
                      </label>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                            <label className="label-Color">{this.state.rideDetails.flightNo}</label>
                                        </div> */}
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <label className="value-title">
                        Flight Type: {this.state.rideDetails.flightType}
                      </label>
                    </div>
                    {/* <label className="label-Color" id="flightType">{this.state.rideDetails.flightType}</label> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
        <LocateVehicleComponent ref="locateVehicleModal" />
        <ReplayVehicleComponent ref="replayVehicleModel" />
      </Fragment>
    );
  }
}
