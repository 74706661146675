import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import RadioGroup from "devextreme-react/radio-group";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const rideType = [
  { id: 1, name: "Arrival" },
  { id: 2, name: "Departure" },
  { id: 3, name: "Both" },
];

const priorities = [
  { id: 1, text: "Individual" },
  { id: 2, text: "Broadcast" },
];

const notificationSourceLst = [
  { id: 1, text: "App" },
  { id: 2, text: "Email" },
];
const appTypeLst = [
  { id: 1, name: "IOS" },
  { id: 2, name: "Android" },
];

let currentTime =
  sessionStorage.getItem("session_locationTime") === ""
    ? new Date()
    : new Date(sessionStorage.getItem("session_locationTime"));
export class NotificationComponent extends Component {
  static displayName = NotificationComponent.name;
  currentTime =
    sessionStorage.getItem("session_locationTime") === ""
      ? new Date()
      : new Date(sessionStorage.getItem("session_locationTime"));
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      datasource: null,
      airportList: [],
      airportId: 0,
      airportCode: "",
      crewMemberList: [],
      employeeId: 0,
      employeeNumber: "",
      dropdownRideType: [],
      rideTypeId: 0,
      notificationSource: notificationSourceLst[0],
      priority: priorities[0],
      notificationMessage: "",
      employeeNotifications: [],
      employeeSosNotifications: [],
      autoNotifications: [],
      flightNo: "",
      appTypeId: 1,
      dropdownFcmMessageType: [],
      fcmMessageTypeId: 0,
      isShowPopup: false,
      errors: {
        appType: "",
        fcmMessageType: "",
        notificationMessage: "",
        employeeNumber: "",
      },
    };
  }

  async componentDidMount() {
    this.setState({
      loadPanelVisible: true,
    });
    await this.getAutoNotifications();
    await this.getEmployeeNotifications();
    await this.getEmployeeSosNotifications();
    await this.getAirports();
    await this.getCrewMember();
    await this.getRideTypeDropdownList();
    await this.getFcmMessageTypeDropdown(this.state.appTypeId);
    this.setState({
      loadPanelVisible: false,
    });
  }

  getEmployeeNotifications = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Notification/Employee/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        employeeNotifications: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getEmployeeNotifications function",
        err
      );
    }
  };
  getEmployeeSosNotifications = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Notification/SosNotification/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        employeeSosNotifications: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getEmployeeNotifications function",
        err
      );
    }
  };

  getAutoNotifications = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Notification/Airline/",
        requestParams
      );
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.isSuccess) {
          let data = responseData?.payload ?? [];
          let autoNotificationLst = data.filter((el) => {
            el.receivedDate = this.convertUTCToLocal(el.receivedDate);
            return el;
          });
          this.setState({ autoNotifications: autoNotificationLst ?? [] });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAutoNotifications function",
        err
      );
    }
  };
  convertUTCToLocal = (utcDateTime) => {
    try {
      if (utcDateTime !== "") {
        if (!utcDateTime.toLowerCase().includes("utc")) {
          utcDateTime = `${utcDateTime} UTC`;
        }
        utcDateTime = new Date(utcDateTime).toLocaleString();
        utcDateTime = utcDateTime.replace(",", "");
      }
      return utcDateTime;
    } catch (ex) {
      console.log(ex);
      return "";
    }
  };
  getAirports = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportList: data.payload != null ? data.payload : [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
    }
  };

  getCrewMember = async () => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let airportCode = this.state.airportCode;
      let flightNo = this.state.flightNo;
      var request = {};
      request.AirlineCode = airlineCode;
      request.Status = "Active";
      request.AirportCode = airportCode;
      request.FlightNo = flightNo;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      );
      const data = await response.json();
      this.setState({
        crewMemberList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        err
      );
    }
  };

  getRideTypeDropdownList = async () => {
    try {
      this.setState({ dropdownRideType: rideType });
    } catch (ex) {
      console.log(
        "Error in " + this.displayName + ".getStatusDropdownList function",
        ex
      );
    }
  };
  handleAirportChange = async (e) => {
    if (e.selectedItem != null) {
      this.setState({
        airportId: e.selectedItem.airportId,
        airportCode: e.selectedItem.airportCode,
      });
    } else {
      this.setState({
        airportId: 0,
        airportCode: "",
      });
    }
    await this.getCrewMember();
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
    });
  };

  handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
    switch (name) {
      case "flightNo":
        setTimeout(() => {
          this.getCrewMember();
        }, 1000);
        break;
    }
    this.state.errors[name] = "";
  };

  handleCrewChange = async (e) => {
    if (e.selectedItem != null) {
      this.setState({
        employeeId: e.selectedItem.userId,
        employeeNumber: e.selectedItem.employeeNumber,
      });
    } else {
      this.setState({
        employeeId: 0,
        employeeNumber: "",
      });
    }
  };

  handleRideTypeChange = async (e) => {
    e.preventDefault();
    if (e.selectedItem != null) {
      this.setState({
        rideTypeId: e.selectedItem.Id,
      });
    } else {
      this.setState({
        rideTypeId: 0,
      });
    }
  };

  handleOnNotificationSourceChange = async (e) => {
    if (e.value !== null) {
      this.setState({ notificationSource: e.value });
    } else {
      this.setState({
        notificationSource: [],
      });
    }
  };

  handlePriorityChanged = async (e) => {
    let error = this.state.errors;
    error.employeeNumber = "";
    this.setState({ priority: e.value, employeeNumber: "", errors: error });
  };

  handleValidation = () => {
    let formIsValid = true;
    try {
      let error = this.state.errors;
      error.appType = "";
      error.fcmMessageType = "";
      error.notificationMessage = "";
      error.employeeNumber = "";
      if (Number(this.state.appTypeId) === 0) {
        formIsValid = false;
        error.appType = "Please select app type";
      }
      if (Number(this.state.fcmMessageTypeId) === 0) {
        formIsValid = false;
        error.fcmMessageType = "Please select topic";
      }
      if (
        this.state.notificationMessage.trim() === "" &&
        this.state.isShowPopup === true
      ) {
        formIsValid = false;
        error.notificationMessage = "Please enter notification message.";
      }
      if (this.state.priority.id === 1) {
        if (this.state.employeeNumber === "") {
          formIsValid = false;
          error.employeeNumber = "Please select employee.";
        }
      }
      this.setState({ errors: error });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleValidation function",
        err
      );
      formIsValid = false;
    }
    return formIsValid;
  };

  handleNotificationSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.handleValidation()) {
        let airlineCode = sessionStorage.getItem("session_airlineCode");
        let UserId = sessionStorage.getItem("session_userId");
        let UserName = sessionStorage.getItem("session_userName");
        var request = {};
        request.AirlineCode = airlineCode;
        request.NotificationTypeId = Number(this.state.fcmMessageTypeId);
        request.NotificationText = this.state.notificationMessage;
        request.PriorityLevel = parseInt(1);
        request.AppTypeId = Number(this.state.appTypeId);
        request.EmployeeNumber = this.state.employeeNumber;
        request.AirportCode = this.state.airportCode;
        request.FlightNumber = this.state.flightNo;
        request.NotificationSourceId = Number(
          this.state.notificationSource?.id ?? 1
        );
        request.ShowPopup = this.state.isShowPopup;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
            "Notification/InsertCrewNotification",
          requestParams
        );
        const data = await response.json();
        this.setState({ isSubmited: false });
        if (data.isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getEmployeeNotifications();
              this.getAutoNotifications();
              this.handleClearNotification();
            }
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { width: "310px" },
            hideProgressBar: false,
            newestOnTop: false,
            closeButton: false,
          });
        }
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".handleNotificationSubmit function",
        err
      );
    }
    this.setState({ isSubmited: false });
  };

  handleClearNotification = async (e) => {
    this.setState({
      isSubmited: false,
      datasource: null,
      airportId: 0,
      airportCode: "",
      employeeId: 0,
      employeeNumber: "",
      rideTypeId: 0,
      notificationSource: notificationSourceLst[0],
      priority: priorities[0],
      notificationMessage: "",
      flightNo: "",
      appTypeId: 1,
      fcmMessageTypeId: 0,
      errors: {
        appType: "",
        fcmMessageType: "",
        notificationMessage: "",
        employeeNumber: "",
      },
    });
    setTimeout(() => {
      this.getCrewMember();
    }, 1000);
  };
  handleOnAppTypeChange = async (e) => {
    let appTypeId = e.selectedItem?.id ?? 1;
    let error = this.state.errors;
    error.appType = "";
    error.fcmMessageType = "";
    error.notificationMessage = "";
    this.setState({
      appTypeId: appTypeId,
      notificationMessage: "",
      errors: error,
    });
    await this.getFcmMessageTypeDropdown(appTypeId);
  };
  getFcmMessageTypeDropdown = async (appTypeId) => {
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      const requestParams = getRequestParams("GET");
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}Notification/fcm-messagetype-Dropdown/${airlineCode}/${appTypeId}`,
        requestParams
      );
      const data = await response.json();
      this.setState({
        dropdownFcmMessageType: data?.payload ?? [],
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getFcmMessageTypeDropdown function",
        err
      );
    }
  };
  handleOnFcmMessageTypeChange = async (e) => {
    try {
      let error = this.state.errors;
      error.fcmMessageType = "";
      error.notificationMessage = "";
      let fcmMessageTypeId = e.selectedItem?.id ?? 0;
      let isShowPopup = e.selectedItem?.showPopup;
      let notificationMessage = "";
      if (fcmMessageTypeId !== 0) {
        notificationMessage =
          this.state.dropdownFcmMessageType?.filter(
            (f) => f.id === fcmMessageTypeId
          )[0].notificationText ?? "";
      }
      this.setState({
        fcmMessageTypeId: fcmMessageTypeId,
        notificationMessage: notificationMessage,
        isShowPopup: isShowPopup,
        errors: error,
      });
    } catch (err) {
      console.log(
        "Error in " +
          this.displayName +
          ".handleOnFcmMessageTypeChange function",
        err
      );
    }
  };
  handleOnCellPrepared = (e) => {
    if (e.rowType === "data" && e.column.dataField === "statusName") {
      if (Number(e.data.statusId) > 0) {
        e.cellElement.style.backgroundColor = "#ffcccb";
        //e.cellElement.bgColor = "#FFFFE0";
        //e.cellElement.style.color = "red";
        if (Number(e.data.statusId) === 1) {
          e.cellElement.style.backgroundColor = "#999900"; // open
          e.cellElement.style.color = "#FFFFE0";
        }
        if (Number(e.data.statusId) === 2) {
          e.cellElement.style.backgroundColor = "#008000"; // success
          e.cellElement.style.color = "#FFFFE0";
        }
        if (Number(e.data.statusId) === 4) {
          e.cellElement.style.backgroundColor = "#FF0000"; // expired
          e.cellElement.style.color = "#FFFFE0";
        }
        if (Number(e.data.statusId) === 7) {
          e.cellElement.style.backgroundColor = "#5C4033"; // invlid
          e.cellElement.style.color = "#FFFFE0";
        }
      }
    }
  };
  render() {
    const renderDateTimeCell = (data) => {
      return formatDate(data.value, "hh:mm:ss a");
    };
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>App Type(s)</label>
                  <SelectBox
                    dataSource={appTypeLst}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select app type"
                    name="apptype"
                    defaultValue={1}
                    value={this.state.appTypeId || 1}
                    displayExpr="name"
                    valueExpr="id"
                    onSelectionChanged={this.handleOnAppTypeChange}
                    showClearButton={false}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {this.state.errors["appType"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["appType"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <div className="form-group">
                  <label>Topic(s)</label>
                  <SelectBox
                    dataSource={this.state.dropdownFcmMessageType}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select message type"
                    name="topic"
                    defaultValue={0}
                    value={this.state.fcmMessageTypeId}
                    displayExpr="topic"
                    valueExpr="id"
                    onSelectionChanged={this.handleOnFcmMessageTypeChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {this.state.errors["fcmMessageType"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["fcmMessageType"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>Airport(s)</label>
                  <SelectBox
                    dataSource={this.state.airportList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Airports"
                    name="airportCode"
                    defaultValue={0}
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    //displayExpr={this.displayExpr}
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                  {/* {this.state.errors["errVehicleMakeId"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["errVehicleMakeId"]}</span>} */}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>Flight No</label>
                  <div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="flightNo"
                      placeholder="Enter Flight No"
                      maxLength="50"
                      title="Flight No"
                      value={this.state.flightNo}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>Notification Type</label>
                  <div className="form-group">
                    <RadioGroup
                      items={notificationSourceLst}
                      value={this.state.notificationSource}
                      onValueChanged={this.handleOnNotificationSourceChange}
                      layout="horizontal"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 displayNone">
                <div className="form-group">
                  <label>Direction</label>
                  <SelectBox
                    dataSource={this.state.dropdownRideType}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Ride Type"
                    defaultValue={0}
                    value={this.state.rideTypeId}
                    valueExpr="id"
                    displayExpr="name"
                    onSelectionChanged={this.handleRideTypeChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                    width="100%"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <div className="form-group">
                  <label>Notification Message</label>
                  <div className="form-group">
                    <textarea
                      id="notificationMessage"
                      className="form-control form-textbox form-control-sm"
                      style={{ maxHeight: "150px", height: "50px" }}
                      maxLength="250"
                      title="Message"
                      placeholder="Message..."
                      name="notificationMessage"
                      value={this.state.notificationMessage}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  {this.state.errors["notificationMessage"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["notificationMessage"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label></label>
                  <RadioGroup
                    items={priorities}
                    value={this.state.priority}
                    layout="horizontal"
                    onValueChanged={this.handlePriorityChanged}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div className="form-group">
                  <label>Crew Member(s)</label>
                  <SelectBox
                    dataSource={this.state.crewMemberList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="All Crew Members"
                    name="employeeNumber"
                    defaultValue={0}
                    value={this.state.employeeNumber}
                    displayExpr="employeeNumber"
                    //displayExpr={this.displayExpr}
                    valueExpr="employeeNumber"
                    onSelectionChanged={this.handleCrewChange}
                    showClearButton={true}
                    disabled={this.state.priority.id <= 1 ? false : true}
                    searchEnabled={true}
                  />
                  {this.state.errors["employeeNumber"].length > 0 && (
                    <span className="error invalid-feedback">
                      {this.state.errors["employeeNumber"]}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <div className="form-group">
                  <label></label>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={this.handleNotificationSubmit}
                    >
                      {this.state.isSubmited && (
                        <span title="In-process">
                          <i className="fas fa-spinner fa-spin"></i> In-process
                        </span>
                      )}
                      {!this.state.isSubmited && (
                        <span title="Submit">
                          <i className="fas fa-check"></i> Send Notification
                        </span>
                      )}
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-custom-gray "
                      onClick={this.handleClearNotification}
                      title="Close"
                    >
                      <i className="fas fa-times"></i> Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div>
              <ul
                className="nav nav-tabs mt-1 ml-2"
                id="custom-tabs-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-automated-tab"
                    data-toggle="pill"
                    href="#custom-tabs-automated"
                    role="tab"
                    aria-controls="custom-tabs-automated"
                    aria-selected="true"
                    value={0}
                  >
                    Automated
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-manual-tab"
                    data-toggle="pill"
                    href="#custom-tabs-manual"
                    role="tab"
                    aria-controls="custom-tabs-manual"
                    aria-selected="false"
                    value={1}
                  >
                    Manual
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-Sos-tab"
                    data-toggle="pill"
                    href="#custom-tabs-Sos"
                    role="tab"
                    aria-controls="custom-tabs-Sos"
                    aria-selected="false"
                    value={2}
                  >
                    SOS Notification
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab-bordered"
              id="custom-tabs-tabContent"
            >
              <div
                className="tab-pane fade active show"
                id="custom-tabs-automated"
                role="tabpanel"
                aria-labelledby="custom-tabs-automated-tab"
              >
                <DataGrid
                  dataSource={this.state.employeeNotifications}
                  keyExpr="id"
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.onToolbarPreparing}
                >
                  <Column
                    dataField="employeeNumber"
                    caption="Employee Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={140}
                  />
                  <Column
                    dataField="firstName"
                    caption="First Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="lastName"
                    caption="Last Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="airlineCrewType"
                    caption="Crew Type"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="airportCode"
                    caption="Airport Code"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="pickupTime"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Pickup Time"
                  />
                  <Column
                    dataField="flightTime"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Flight Time"
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flight Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="title"
                    caption="Title"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="notificationMessage"
                    caption="Notification"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={300}
                  />
                  <Column
                    dataField="processedTime"
                    caption="Processed Time"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={160}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Employee Notifications"
                  />
                </DataGrid>
              </div>
              <div
                tabname={this.state.rowTabName}
                className="tab-pane fade"
                id="custom-tabs-manual"
                role="tabpanel"
                aria-labelledby="custom-tabs-manual-tab"
              >
                <DataGrid
                  dataSource={this.state.autoNotifications}
                  keyExpr="id"
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.onToolbarPreparing}
                  onCellPrepared={this.handleOnCellPrepared}
                >
                  <Column
                    dataField="topic"
                    caption="Topic"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="notificationText"
                    caption="Message"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={300}
                  />
                  <Column
                    dataField="appType"
                    caption="App Type"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="broadcastType"
                    caption="Broascast"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="priorityLevelText"
                    caption="Priority"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="notificationSource"
                    caption="Notification Type"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="statusName"
                    caption="Status"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                  />
                  <Column
                    dataField="receivedDate"
                    caption="Send Date"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={300}
                    cellRender={renderDateTimeCell}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Auto Notifications"
                  />
                </DataGrid>
              </div>
              <div
                className="tab-pane fade"
                id="custom-tabs-Sos"
                role="tabpanel"
                aria-labelledby="custom-tabs-Sos-tab"
              >
                <DataGrid
                  dataSource={this.state.employeeSosNotifications}
                  keyExpr="id"
                  selection={{ mode: "single" }}
                  columnsAutoWidth={false}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  rowAlternationEnabled={false}
                  allowColumnResizing={true}
                  columnResizingMode="widget"
                  onToolbarPreparing={this.onToolbarPreparing}
                >
                  <Column
                    dataField="employeeNumber"
                    caption="Employee Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={140}
                  />
                  <Column
                    dataField="firstName"
                    caption="First Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="lastName"
                    caption="Last Name"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={150}
                  />
                  <Column
                    dataField="airlineCrewType"
                    caption="Crew Type"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="airportCode"
                    caption="Airport Code"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={100}
                  />
                  <Column
                    dataField="pickupTime"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Pickup Time"
                  />
                  <Column
                    dataField="flightTime"
                    width={150}
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    caption="Flight Time"
                  />
                  <Column
                    dataField="flightNumber"
                    caption="Flight Number"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={110}
                  />
                  <Column
                    dataField="title"
                    caption="Title"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={80}
                  />
                  <Column
                    dataField="notificationMessage"
                    caption="Notification"
                    allowSearch={true}
                    allowFiltering={false}
                    allowSorting={true}
                    width={700}
                  />
                  <SortByGroupSummaryInfo summaryItem="count" />
                  <GroupPanel visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    highlightCaseSensitive={true}
                    width={240}
                    placeholder="Search..."
                  />
                  <Grouping autoExpandAll={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                    visible={true}
                  />
                  <Export
                    enabled={true}
                    allowExportSelectedData={false}
                    fileName="Employee Notifications"
                  />
                </DataGrid>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
