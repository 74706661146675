import React, { Component, Fragment } from "react";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { SelectBox } from "devextreme-react/select-box";
import Modal from "react-modal";
import DateBox from "devextreme-react/date-box";
import { LoadPanel } from "devextreme-react/load-panel";
import moment from "moment";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";

export class AirportGeofenceReportComponent extends Component {
  static displayName = AirportGeofenceReportComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      isFilter: false,
      loadPanelVisible: false,
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
      crewDataSource: [],
      airportDataSource: [],
      airportGeofenceReportDataSource: [],
      airportCode: "",
      employeeNumber: "",
    };
  }

  async componentDidMount() {
    await this.getAirports();
    await this.getCrewMember();
  }

  getAirports = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = parseInt(sessionStorage.getItem("session_userId"));
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Airport/AirlineAirport/",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportDataSource: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  getCrewMember = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.Status = "Active";
      request.AirportCode = "";
      request.FlightNo = "";
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Crew/GetAllCrew",
        requestParams
      );
      const data = await response.json();
      this.setState({
        crewDataSource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  getAirportGeofenceReport = async () => {
    try {
      this.setState({
        isFilter: true,
      });
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      var request = {};
      request.AirlineCode = airlineCode;
      request.FromDate = moment(this.state.filterFromDatetime).format(
        "YYYY-MM-DD 00:00:00"
      );
      request.ToDate = moment(this.state.filterToDatetime).format(
        "YYYY-MM-DD 23:59:59"
      );
      request.AirportCode = this.state.airportCode;
      request.EmployeeNumber = this.state.employeeNumber;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Report/GetAirportGeofenceReport",
        requestParams
      );
      const data = await response.json();
      this.setState({
        airportGeofenceReportDataSource: data.payload,
        isFilter: false,
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".getAirportGeofenceReport function",
        error
      );
      this.setState({
        isFilter: false,
      });
    }
  };

  handleAirportChange = async (e) => {
    this.setState({
      airportCode: e.selectedItem != null ? e.selectedItem.airportCode : "",
    });
  };

  handleEmployeeChange = async (e) => {
    this.setState({
      employeeNumber:
        e.selectedItem != null ? e.selectedItem.employeeNumber : "",
    });
  };

  handleOnFilterPickupDateChanged = (date) => {
    this.setState({
      filterFromDatetime: date.value,
    });
  };
  handleOnFiltertoDateChanged = (date) => {
    this.setState({
      filterToDatetime: date.value,
    });
  };

  handleOnClearFilter = () => {
    try {
      this.setState({
        isFilter: false,
        loadPanelVisible: false,
        filterFromDatetime: new Date(),
        filterToDatetime: new Date(),
        airportGeofenceReportDataSource: [],
        airportCode: "",
        employeeNumber: "",
      });
    } catch (error) {
      console.log(
        "Error in " + this.displayName + ".handleOnClearFilter function",
        error
      );
    }
  };

  setToday = (e) => {
    e.preventDefault();
    this.setState({
      filterFromDatetime: moment().startOf("day").toDate(),
      filterToDatetime: moment().endOf("day").toDate(),
    });
  };

  setYesterday = (e) => {
    e.preventDefault();
    this.setState({
      filterFromDatetime: moment().subtract(1, "day").startOf("day").toDate(),
      filterToDatetime: moment().subtract(1, "day").endOf("day").toDate(),
    });
  };

  setLast7days = (e) => {
    e.preventDefault();
    this.setState({
      filterFromDatetime: moment().subtract(6, "day").startOf("day").toDate(),
      filterToDatetime: moment().endOf("day").toDate(),
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Airport</label>
                  <SelectBox
                    dataSource={this.state.airportDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Airport"
                    name="airportCode"
                    value={this.state.airportCode}
                    displayExpr="airportCode"
                    valueExpr="airportCode"
                    onSelectionChanged={this.handleAirportChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group">
                  <label>Employee</label>
                  <SelectBox
                    dataSource={this.state.crewDataSource}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Employee"
                    name="employeeNumber"
                    value={this.state.employeeNumber}
                    displayExpr="employeeNumber"
                    valueExpr="employeeNumber"
                    onSelectionChanged={this.handleEmployeeChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <div className="form-group has-feedback">
                  <label>
                    Date & Time <span className="text-danger">*</span>
                  </label>
                  &nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.setToday(e);
                      }}
                    >
                      <b>Today</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.setYesterday(e);
                      }}
                    >
                      <b>Yesterday</b>
                    </a>
                  </span>
                  &nbsp;|&nbsp;
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.setLast7days(e);
                      }}
                    >
                      <b>Last 7 Days</b>
                    </a>
                  </span>
                  <div>
                    <DateBox
                      type="date"
                      className="replay-date-time"
                      name="filterFromDatetime"
                      placeholder="dd/MM/yyyy"
                      displayFormat="dd/MM/yyyy"
                      showAnalogClock={true}
                      useMaskBehavior={true}
                      defaultValue={this.state.filterFromDatetime}
                      value={this.state.filterFromDatetime}
                      onValueChanged={this.handleOnFilterPickupDateChanged}
                      width="100%"
                      disabled={false}
                      acceptCustomValue={false}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    min={this.state.filterFromDatetime}
                    defaultValue={this.state.filterToDatetime}
                    value={this.state.filterToDatetime}
                    onValueChanged={this.handleOnFiltertoDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.getAirportGeofenceReport}
                  >
                    {this.state.isFilter && (
                      <span title="In-process">
                        <i className="fas fa-spinner fa-spin"></i> In-process
                      </span>
                    )}
                    {!this.state.isFilter && (
                      <span title="Filter">
                        <i className="fas fa-filter"></i> Filter
                      </span>
                    )}
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="button"
                    className="btn btn-custom-gray"
                    onClick={this.handleOnClearFilter}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.airportGeofenceReportDataSource}
              columnAutoWidth={true}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              allowColumnResizing={true}
            >
              <Column
                caption="Flight Info"
                dataField="flightInfo"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Flight Time"
                dataField="flightTime"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Reporting Time"
                dataField="reportingTime"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
                dataType="datetime"
                format="MM/dd/yyyy HH:mm:ss"
              />
              <Column
                caption="Crew"
                dataField="crewName"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Rank"
                dataField="rank"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Vehicle No"
                dataField="vehicleNo"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Airport"
                dataField="airport"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Employee Number"
                dataField="employeeNumber"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <Column
                caption="Drop Time"
                dataField="dropTime"
                allowSearch={true}
                allowFiltering={true}
                allowSorting={true}
              />
              <GroupPanel visible={false} />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="Airport Geofence Report"
              />
            </DataGrid>
          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#historydiv" }}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </Fragment>
    );
  }
}
