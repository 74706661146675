import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Modal from 'react-modal'; //npm install --save react-modal
import { getRequestParams, formatDate } from '../../helpers/globalfunctions';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Margin } from 'devextreme-react/bullet';
import { DisplayFormat } from 'devextreme-react/date-box';

const customStyles = {
    content: {
        width: '85%',
        height: '80%',
    }
};
const customMaxStyles = {
    content: {
        width: '100%',
        height: '100%',
    }
};
const position = { of: '#locateVehiclediv' };

export class LocateVehicleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenModal: false,
            isSubmited: false,
            rideId: 0,
            vehicleNumber: '',
            loadPanelVisible: false,
            mapUrl: "",
            dStatus: customStyles,
            mxStatus: customMaxStyles,
            minStatusSize: customStyles,
            maxStatus: true,
            minStatus: false,
            //customeStyle: status == "Max" ? customStyles : customMaxStyles
        }

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        //this.handleMax = this.handleMax.bind(this);
    }

    handleMax = () => {
        try {
            this.setState({
                dStatus: this.state.mxStatus,
                maxStatus: false,
                minStatus: true,
            })
        } catch (ex) {
            throw ex;
        }
    }

    //Maximax to Orignal Size
    handleOriganlSize = () => {
        try {
            this.setState({
                dStatus: this.state.minStatusSize,
                maxStatus: true,
                minStatus: false,
            })
        } catch (ex) {
            throw ex;
        }
    }
    closeModal() {
        try {
            this.setState({
                isOpenModal: false,
                isSubmited: false,
                vehicleNumber: '',
                rideId: 0,
                mapUrl: process.env.MapUrl,
                dStatus: this.state.minStatusSize,
                maxStatus: true,
                minStatus: false,
            });
        } catch (ex) {
            throw ex;
        }
    }

    async showModal(vehicleNumber, rideId) {
        this.setState({
            isOpenModal: true,
            vehicleNumber: vehicleNumber,
            rideId: rideId,
        });
        // const requestParams = getRequestParams('GET', '');
        // const response = await fetch(process.env.MapUrl, requestParams);
        // const data = await response.json();
        // debugger;
        this.setState({
            mapUrl: process.env.REACT_APP_MapUrl,
        });
        //console.log("Map URL: " + process.env.REACT_APP_MapUrl);
    }

    render() {
        const url = this.state.mapUrl + "?accountId=0&locationId=0&userId=0&vgTypeId=0&rideId=" + this.state.rideId;

        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={this.state.dStatus}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal"
                >
                    <form>
                        <div id="locateVehiclediv">
                            <div className="modal-header">
                                <h6 className="modal-title">Locate Vehicle</h6>
                                <div className='modal-button'>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <button type='button' id='ddScreen' className="close" data-dismiss="modal" aria-label="Maximize" onClick={this.handleMax} style={{ display: this.state.maxStatus ? 'block' : 'none' }}>
                                        <span aria-hidden="true">☐</span></button>
                                    <button type='button' id='ddMaxScreen' className="close" data-dismiss="modal" aria-label="Maximize" onClick={this.handleOriganlSize} style={{ display: this.state.minStatus ? 'block' : 'none' }}>
                                        <span aria-hidden="true">⧉</span></button>
                                </div>
                            </div>
                            <div className="modal-body" >
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <small>Below is the current location of the vehicle number <i><strong className="text-danger"> {this.state.vehicleNumber} </strong></i>.</small>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="widget-map-container">
                                            <iframe width="100%" height="800" frameBorder="0" scrolling="no" allowtransparency="true" src={url}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={position}
                            visible={this.state.loadPanelVisible}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </form>
                </Modal>

            </div>
        );
    }
}