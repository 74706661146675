import React, { Component, Fragment } from "react";
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Editing,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import { getRequestParams, formatDate } from "../../helpers/globalfunctions.js";
import { symbolFormats, precisionFormats } from "../../helpers/fixcodes.js";
import { SelectBox } from "devextreme-react/select-box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DateBox from "devextreme-react/date-box";

export class InvoiceComponent extends Component {
  static displayName = InvoiceComponent.name;
  constructor(props) {
    super(props);
    this.state = {
      invoiceNo: 0,
      supplierNmList: [],
      SupplierId: 0,
      SupplierName: "",
      invoiceStatusList: [],
      invoiceStatusId: 0,
      invoiceStatusName: "",
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
      invoiceDataSource: [],
      locationId: 0,
      moduleId: 0,
      rideId: 0,
      IsInvoiceApproved: true,
      customerTypeId: 0,
    };
  }

  async componentDidMount() {
    await this.getSupplierList();
    await this.InvoiceGridList();
  }

  // Filter Input Handle Event
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  getSupplierList = async () => {
    this.setState({
      loadPanelVisible: true,
    });
    try {
      let airlineCode = sessionStorage.getItem("session_airlineCode");
      let UserId = sessionStorage.getItem("session_userId");
      let UserName = sessionStorage.getItem("session_userName");
      let SourceId = 0;
      var request = {};
      request.AirlineCode = airlineCode;
      request.UserId = UserId;
      request.UserName = UserName;
      request.SourceId = SourceId;
      const requestParams = getRequestParams("POST", request);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Account",
        requestParams
      );
      const data = await response.json();
      this.setState({
        supplierNmList: data.payload != null ? data.payload : [],
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAirports function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };

  // Filter Supplier Status DropDown Handle Event
  handleOnfilterSupplierNameChange = (selectedOption) => {
    {
      this.setState({
        SupplierId:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.accountId
            : 0,
      });
      this.setState({
        SupplierName:
          selectedOption.selectedItem !== null
            ? selectedOption.selectedItem.accountName
            : "",
      });
    }
  };

  // Filter Invoice Status DropDown Handle Event

  // Filter From Date DropDown Handle Event
  handleOnFilterFromDateChanged = (date) => {
    this.setState({ filterFromDatetime: date.value });
  };

  // Filter To Date DropDown Handle Event
  handleOnFilterToDateChanged = (date) => {
    this.setState({ filterToDatetime: date.value });
  };

  //Grid
  InvoiceGridList = async () => {
    try {
      this.setState({ loadPanelVisible: true });
      var newObject = {};
      newObject.InvoiceNo = this.state.invoiceNo;
      newObject.AccountId = this.state.SupplierId;
      newObject.LocationId = this.state.locationId;
      newObject.ModuleId = this.state.moduleId;
      newObject.CustomerId = 0;
      newObject.RideId = this.state.rideId;
      newObject.IsInvoiceApproved = this.state.IsInvoiceApproved;
      newObject.CustomerTypeId = this.state.customerTypeId;
      newObject.InvoiceStatusId = this.state.invoiceStatusId;
      newObject.InvoiceFromDate = formatDate(
        this.state.filterFromDatetime,
        "yyyy-MM-dd HH:mm:ss"
      );
      newObject.InvoiceToDate = formatDate(
        this.state.filterToDatetime,
        "yyyy-MM-dd HH:mm:ss"
      );
      const requestParams = getRequestParams("POST", newObject);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "Invoice",
        requestParams
      );
      const data = await response.json();
      this.setState({
        invoiceDataSource: data.payload,
        loadPanelVisible: false,
      });
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getCrewMember function",
        err
      );
      this.setState({
        loadPanelVisible: false,
      });
    }
  };
  closeModal = () => {
    this.setState({
      SupplierId: 0,
      filterFromDatetime: new Date(),
      filterToDatetime: new Date(),
    });
    setTimeout(() => this.InvoiceGridList(), 100);
  };

  render() {
    const renderGridNumberCell = (data) => {
      return (
        <div>
          <small>
            <i className={symbolFormats.dollarFormat}></i>
          </small>
          &nbsp;&nbsp;{parseFloat(data.value).toFixed(precisionFormats[2])}
        </div>
      );
    };

    return (
      <Fragment>
        <div className="filter-box">
          <div className="card p-2 m-0">
            <div className="row">
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Invoice Number</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="invoiceNo"
                    placeholder="Enter Invoice No."
                    value={this.state.invoiceNo}
                    title="Invoice Number"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>Supplier Name</label>
                  <SelectBox
                    dataSource={this.state.supplierNmList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Supplier Status"
                    name="accountId"
                    defaultValue={0}
                    value={this.state.SupplierId}
                    displayExpr="accountName"
                    valueExpr="accountId"
                    onSelectionChanged={this.handleOnfilterSupplierNameChange}
                    showClearButton={true}
                    disabled={false}
                    searchEnabled={true}
                  />
                </div>
              </div>

              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>From Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterFromDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterFromDatetime}
                    value={this.state.filterFromDatetime}
                    onValueChanged={this.handleOnFilterFromDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group">
                  <label>To Date</label>
                  <DateBox
                    type="date"
                    className="replay-date-time"
                    name="filterToDatetime"
                    placeholder="dd/MM/yyyy"
                    displayFormat="dd/MM/yyyy"
                    showAnalogClock={true}
                    useMaskBehavior={true}
                    defaultValue={this.state.filterToDatetime}
                    value={this.state.filterToDatetime}
                    onValueChanged={this.handleOnFilterToDateChanged}
                    width="100%"
                    disabled={false}
                    acceptCustomValue={false}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="form-group has-feedback">
                  <div>
                    <br />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={this.InvoiceGridList}
                  >
                    <span title="Filter">
                      <i className="fas fa-filter"></i> Filter
                    </span>
                  </button>{" "}
                  &nbsp; &nbsp;
                  <button
                    type="submit"
                    className="btn btn-custom-gray "
                    onClick={this.closeModal}
                  >
                    <span title="Clear">
                      <i className="fas fa-eraser"></i> Clear
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <DataGrid
              dataSource={this.state.invoiceDataSource}
              keyExpr="customerId"
              columnsAutoWidth={false}
              showColumnLines={true}
              showRowLines={true}
              showBorders={true}
              rowAlternationEnabled={false}
              allowColumnResizing={true}
              columnResizingMode="widget"
              selectedRowKeys={this.state.dataSourceSelectedRowKeys}
              onSelectionChanged={this.handleMultipleDataSourceSelectionChanged}
              onToolbarPreparing={this.onToolbarPreparing}
            >
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"none"}
              />
              <Editing mode="cell" allowUpdating={false} />
              <Column
                dataField="invoiceNo"
                caption="Invoice No."
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="invoiceDate"
                caption="Invoice Date"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
                dataType="datetime"
                format="dd/MM/yyyy"
              />
              <Column
                dataField="supplierName"
                caption="Supplier Name"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={300}
              />
              <Column
                dataField="locationName"
                caption="Location"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={150}
              />
              <Column
                dataField="customerName"
                caption="Customer"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={250}
              />
              <Column
                dataField="baseFare"
                caption="Base Fare"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
                cellRender={renderGridNumberCell}
              />
              <Column
                dataField="billAmount"
                caption="Bill Amount"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={100}
                cellRender={renderGridNumberCell}
              />
              <Column
                dataField="dueAmount"
                caption="Due Amount"
                allowSearch={true}
                allowFiltering={false}
                allowSorting={true}
                width={200}
                cellRender={renderGridNumberCell}
              />
              <SortByGroupSummaryInfo summaryItem="count" />
              <GroupPanel visible={false} />
              {/* <Template name="toolbarTemplate" render={this.toolbarItemRender} /> */}
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                width={240}
                placeholder="Search..."
              />
              <Grouping autoExpandAll={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
                visible={true}
              />
              <Export
                enabled={true}
                allowExportSelectedData={false}
                fileName="UnApproved Rides"
              />
            </DataGrid>
          </div>
        </div>
      </Fragment>
    );
  }
}
